import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Pie } from "@ant-design/plots";

export default function PieChart(props) {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const config = {
    appendPadding: 10,
    data,
    color: ["#edbb5f","#AEC8D7","#E6CDD8","#CCE3D0","#EAD9F2","#F4E8D5"],
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    label: {
      type: "outer",
    },
    onReady: (plot) => {
      plot.on("plot:click", (...args) => {
        if (args[0].data !== null) {
          props.setSelectedPiece(args[0].data.data);
        }
      });
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };
  return <Pie style={{ width: "100%", height: "100%" }} {...config} />;
}
