import React, { useEffect, useState } from "react";
import "../styles/AllProduct.css";
import delete_ico from "../assets/delete.png";
import Alert from "../components/Alert/Alert";
import PropertiesList from "../components/AllProductComponents/PropertiesList";
import OffersOfProduct from "../components/AllProductComponents/OffersOfProduct"
import edit_ico from "../assets/edit.png"
import confirm_ico from "../assets/confirm.png"
import close_ico from "../assets/close-button.png"
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import LogOutEndPoint from "../components/LogOutEndPoint";
import { useNavigate } from "react-router-dom";

export default function AllProduct() {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [properties, setProperties] = useState([]);
  const [alertList, setAlertList] = useState([]);
  const [tempArr, setTempArr] = useState([...alertList]);
  const [getTemplateTrigger, setGetTemplateTrigger] = useState(false)
  const [productNameToUpdate, setProductNameToUpdate] = useState("")
  const [productNameEdit, setProductNameEdit] = useState(false)
  const navigate = useNavigate();

  useEffect(()=>{
    const result = products.find(product=> product.id===selectedProduct)
    if(result?.name){
      setProductNameToUpdate(result?.name)
    }
  },[selectedProduct, productNameEdit])

  // console.log("products",products)
  const [langFile, setLangFile] = useState(en);
  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);
  
  useEffect(() => {
    setTempArr(alertList);
  }, [alertList]);

  function DeleteTemplate() {
    if (selectedProduct !== "") {
      fetch(
        process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_TEMPLATE_DELETE, {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: JSON.parse(localStorage.getItem("user")).id,
          template: {
            id: selectedProduct
          }
        })
      }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          else if (res.status === 401) {
            LogOutEndPoint(navigate);
          }
          throw res;
        })
        .then((data) => {
          if (data.result.success) {
            setGetTemplateTrigger(!getTemplateTrigger)
            let temp = [...alertList];
            temp.push({ status: "success", text: data.result.message });
            setAlertList([...temp]);
            setSelectedProduct("")
          }
          else {
            let temp = [...alertList];
            temp.push({ status: "error", text: data.result.message });
            setAlertList([...temp]);
          }

          return data;
        })
        .catch((error) => {
          let temp = [...alertList];
          temp.push({ status: "error", text: "Product Template Delete Failed" });
          setAlertList([...temp]);
          console.log(error);
        });
    }
  }
  useEffect(() => {
    if (selectedProduct !== "") {
      fetch(
        process.env.REACT_APP_BASE_URL + process.env.REACT_APP_TEMPLATE_BY_ID,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
          },
          body: JSON.stringify({
            id: selectedProduct,
          }),
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          else if (res.status === 401) {
            LogOutEndPoint(navigate);
          }
          throw res;
        })
        .then((data) => {
          setProperties(data.template.properties);
          // let temp = [...alertList];
          // temp.push({
          //   status: "success",
          //   text: data.message,
          // });
          // setAlertList([...temp]);
          return data;
        })
        .catch((error) => {
          let temp = [...alertList];
          temp.push({
            status: "error",
            text: "Getting Properties Of Template Failed",
          });
          setAlertList([...temp]);
          console.log(error);
        });
    }
  }, [selectedProduct, getTemplateTrigger]);

  useEffect(() => {
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_TEMPLATE_GET_ALL,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        setProducts([]);
        setProducts(data.templates);
        if (data.templates.length !== 0 && selectedProduct==="")
          setSelectedProduct(data.templates[0].id);
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getTemplateTrigger]);

  function UpdateProductName(){
    if(productNameToUpdate!=="")
    {fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPDATE_TEMPLATE,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json" ,
          "Accept": "application/json",
          "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
        body: JSON.stringify({
          userId: JSON.parse(localStorage.getItem("user")).id,
          templateId: selectedProduct,
          name: productNameToUpdate
        }),
      }
    )
      .then((res) => {
        setGetTemplateTrigger(!getTemplateTrigger)
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        let temp = [...alertList];
        temp.push({ status: "success", text: "Product Name Update Successful" });
        setAlertList([...temp]);
        return data;
      })
      .catch((error) => {
        let temp = [...alertList];
        temp.push({ status: "error", text: "Product Name Update Failed" });
        setAlertList([...temp]);
        console.log(error);
      });
    }
  }

  return (
    <div className="all-product-container">
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          position: "absolute",
          right: "1vw",
          height: "min-content",
          bottom: "0vh",
        }}
      >
        {tempArr.map((item, index) => {
          return (
            <Alert
              index={index}
              key={index}
              time={5000}
              status={item.status}
              text={item.text}
            ></Alert>
          );
        })}
      </div>
      <div className="product-navbar">
        <select
          name="selectProduct"
          id="selectProduct"
          onChange={(event) => setSelectedProduct(event.target.value)}
        >
          {products.map((product, index) => (
            <option key={index} value={product.id}>
              {product.name}
            </option>
          ))}
        </select>
            {productNameEdit?
            <>
            <input type="text"
            value={productNameToUpdate}
            onChange={(e)=>setProductNameToUpdate(e.target.value)}>
            </input>
            <button onClick={()=>{setProductNameEdit(false)}} className="edit-button">
            <img alt="close" src={close_ico} ></img>
          </button> 
            <button onClick={()=>UpdateProductName()} className="edit-button">
            <img alt="confirm" src={confirm_ico} ></img>
          </button>
          
          </>:
            <button onClick={()=>setProductNameEdit(true)} className="edit-button">
              <img alt="edit" src={edit_ico} ></img>
            </button>
            }
        <button
          className="delete-product"
          onClick={() => {
            DeleteTemplate();
          }}
        >
          <img
            alt="delete-product"
            className="delete-product-ico"
            src={delete_ico}
          ></img>
        </button>
      </div>

      <div className="components-parent">
        <div className="propert-list-component-container">
          <PropertiesList properties={properties} alertList={alertList} setAlertList={setAlertList} getTemplateTrigger={getTemplateTrigger} setGetTemplateTrigger={setGetTemplateTrigger}></PropertiesList>
        </div>
        <div className="offers-of-product-component-container">
          <OffersOfProduct langFile={langFile} selectedProduct={selectedProduct} alertList={alertList} setAlertList={setAlertList}></OffersOfProduct>
        </div>
      </div>

    </div>
  );
}
