import React, { useEffect, useState } from "react";
import "./styles/Sidebar.css";
import { useNavigate } from "react-router-dom";
import logo from "../assets/mentorSigortaLogo.png";
import logo2 from "../assets/mentorSigortaLogo-2.png";
import add_ico from "../assets/addToSla.png";
import product_ico from "../assets/product.png";
import search_ico from "../assets/search_document.png";
import folder_ico from "../assets/folder.png";
import addUser_ico from "../assets/add-user.png";
import leftArrow from "../assets/left-arrow-sidebar.png";
import rightArrow from "../assets/right-arrow-sidebar.png";
import dashboard_ico from "../assets/dashboard.png";
import user_ico from "../assets/people.png";
import offer_ico from "../assets/offer.png";
import offer_ico_2 from "../assets/offer-2.png";
import offer_ico_3 from "../assets/offer-3.png";
import addCompany_ico from "../assets/new-company.png";
import company_ico from "../assets/company.png";
import listProduct_ico from "../assets/product-2.png";
import downArrow from "../assets/down-arrow-2.png";
import rightArrow2 from "../assets/right-arrow-2.png";
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import addon from "../assets/addon.png";
import search from "../assets/search.png";
import userAndLog_ico from "../assets/profile.png";
import log_ico from "../assets/log.png"
import mailConfig_ico from "../assets/mailConfig.png"

export default function Sidebar({ children, sideBar, setSideBar }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function closeSidebarOnMobile() {
    if (windowWidth <= 600) {
      setSideBar(false);
    }
  }

  const navigate = useNavigate();
  const [langFile, setLangFile] = useState(en);
  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const url = new URL(window.location.href);
  const path = url.pathname; // URL'nin yolu (path)
  const extension = path.split(".").pop(); // Uzantıyı alma

  const [activePage, setActivePage] = useState(extension + "-side");

  useEffect(() => {
    if (sideBar) {
      setActivePage(activePage.substring(0, activePage.length - 5));
    } else {
      setActivePage(activePage.concat("-side"));
    }
  }, [sideBar]);

  const [navDropdownOpen, setIsNavDropdownOpen] = useState(false);
  const [newDropdownOpen, setIsNewDropdownOpen] = useState(false);
  const [findDropdownOpen, setIsFindDropdownOpen] = useState(false);
  const [userAndLogDropdownOpen, setUserAndLogDropdownOpen] = useState(false);

  const navDropdown = () => {
    setIsNavDropdownOpen(!navDropdownOpen);
  };
  const newDropdown = () => {
    setIsNewDropdownOpen(!newDropdownOpen);
    setIsFindDropdownOpen(false);
    setUserAndLogDropdownOpen(false);
  };
  const findDropdown = () => {
    setIsFindDropdownOpen(!findDropdownOpen);
    setIsNewDropdownOpen(false);
    setUserAndLogDropdownOpen(false);
  };
  const userAndLogDropdown = () => {
    setUserAndLogDropdownOpen(!userAndLogDropdownOpen);
    setIsNewDropdownOpen(false);
    setIsFindDropdownOpen(false);
  };

  return (
    <div>
      <button
        className={"arrow-button-right-to-mobile"}
        onClick={() => {
          setSideBar(!sideBar);
        }}
      >
        <img
          alt="main"
          style={{
            width: "60%",
            filter: "invert(var(--filterRatio2))",
            WebkitFilter: "invert(var(--filterRatio2))",
          }}
          src={rightArrow}
        ></img>
      </button>
      <div
        style={{
          display: "flex",
          flexdirection: "row",
        }}
      >
        <div
          className={
            sideBar === true
              ? "main-page-style-2"
              : "main-page-style-2 main-page-style-2-side"
          }
        >
          <div className="logo-main">
            <img
              alt="mentorSigorta"
              src={logo}
              className="mentor-ico-wide"
            ></img>
          </div>
          {user.privilege === "Admin" ? (
            <>
              <button
                className={
                  "button-main-1 " +
                  (activePage === "/dashboard" ? " button-main-1-active" : "")
                }
                onClick={() => {
                  setActivePage("/dashboard");
                  navigate("/dashboard");
                  closeSidebarOnMobile();
                }}
              >
                <img
                  alt="dashboard"
                  style={{
                    width: "1.25vw",
                    marginRight: "0.5rem",
                    filter: "invert(100%)",
                    WebkitFilter: "invert(100%)",
                  }}
                  src={dashboard_ico}
                ></img>
                {langFile.pages.Main.sidebar_dashboard}
              </button>

              <label htmlFor="new" className="sliderButton">
                <h6 className="h7-style">
                  {langFile.pages.Main.sidebarHeader2}
                </h6>
                <img
                  className="arrowIcon"
                  alt="arrow-up"
                  src={newDropdownOpen ? downArrow : rightArrow2}
                />
              </label>
              <input
                onChange={newDropdown}
                checked={newDropdownOpen}
                type="checkbox"
                id="new"
              ></input>
              <ul className={`slide ${newDropdownOpen ? "open" : ""}`}>
                <li>
                  <button
                    className={
                      "button-main-1 " +
                      (activePage === "/upload" ? " button-main-1-active" : "")
                    }
                    onClick={() => {
                      setActivePage("/upload");
                      navigate("/upload");
                      closeSidebarOnMobile();
                    }}
                  >
                    <img
                      alt="upload"
                      style={{
                        width: "1.25vw",
                        marginRight: "0.5rem",
                        filter: "invert(100%)",
                        WebkitFilter: "invert(100%)",
                      }}
                      src={add_ico}
                    ></img>
                    {langFile.pages.Main.sidebar_main_page}
                  </button>
                </li>
                <li>
                  <button
                    className={
                      "button-main-1 " +
                      (activePage === "/uploadOffer"
                        ? " button-main-1-active"
                        : "")
                    }
                    onClick={() => {
                      setActivePage("/uploadOffer");
                      navigate("/uploadOffer");
                      closeSidebarOnMobile();
                    }}
                  >
                    <img
                      alt="uploadOffer"
                      style={{
                        width: "1.25vw",
                        marginRight: "0.5rem",
                        filter: "invert(100%)",
                        WebkitFilter: "invert(100%)",
                      }}
                      src={offer_ico_2}
                    ></img>
                    {langFile.pages.Main.sidebar_upload_offer}
                  </button>
                </li>
                <li>
                  <button
                    className={
                      "button-main-1 " +
                      (activePage === "/newProduct"
                        ? " button-main-1-active"
                        : "")
                    }
                    onClick={() => {
                      setActivePage("/newProduct");
                      navigate("/newProduct");
                      closeSidebarOnMobile();
                    }}
                  >
                    <img
                      alt="main"
                      style={{
                        width: "1.25vw",
                        marginRight: "0.5rem",
                        filter: "invert(100%)",
                        WebkitFilter: "invert(100%)",
                      }}
                      src={product_ico}
                    ></img>
                    {langFile.pages.Main.sidebar_new_product}
                  </button>
                </li>
              </ul>
            </>
          ) : null}
          <label htmlFor="find" className="sliderButton">
            <h6 className="h7-style">{langFile.pages.Main.sidebarHeader3}</h6>
            <img
              className="arrowIcon"
              alt="arrow-up"
              src={findDropdownOpen ? downArrow : rightArrow2}
            />
          </label>
          <input
            onChange={findDropdown}
            checked={findDropdownOpen}
            type="checkbox"
            id="find"
          ></input>

          <ul className={`slide ${findDropdownOpen ? "open" : ""}`}>
            <li>
              <button
                className={
                  "button-main-1 " +
                  (activePage === "/search" ? " button-main-1-active" : "")
                }
                onClick={() => {
                  setActivePage("/search");
                  navigate("/search");
                  closeSidebarOnMobile();
                }}
              >
                <img
                  alt="main"
                  style={{
                    width: "1.25vw",
                    marginRight: "0.5rem",
                    filter: "invert(100%)",
                    WebkitFilter: "invert(100%)",
                  }}
                  src={search_ico}
                ></img>
                {langFile.pages.Main.sidebar_another_page}
              </button>
            </li>

            <li>
              <button
                className={
                  "button-main-1 " +
                  (activePage === "/searchPackage"
                    ? " button-main-1-active"
                    : "")
                }
                onClick={() => {
                  setActivePage("/searchPackage");
                  navigate("/searchPackage");
                  closeSidebarOnMobile();
                }}
              >
                <img
                  alt="main"
                  style={{
                    width: "1.25vw",
                    marginRight: "0.5rem",
                    filter: "invert(100%)",
                    WebkitFilter: "invert(100%)",
                  }}
                  src={offer_ico_3}
                ></img>
                {langFile.pages.Main.sidebar_search_offer}
              </button>
            </li>

            <li>
              {user.privilege === "Admin" ? (
                <button
                  className={
                    "button-main-1 " +
                    (activePage === "/allData" ? " button-main-1-active" : "")
                  }
                  onClick={() => {
                    setActivePage("/allData");
                    navigate("/allData");
                    closeSidebarOnMobile();
                  }}
                >
                  <img
                    alt="main"
                    style={{
                      width: "1.25vw",
                      marginRight: "0.5rem",
                      filter: "invert(100%)",
                      WebkitFilter: "invert(100%)",
                    }}
                    src={folder_ico}
                  ></img>
                  {langFile.pages.Main.sidebar_all_data}
                </button>
              ) : null}
            </li>

            <li>
              {user.privilege === "Admin" ? (
                <button
                  className={
                    "button-main-1 " +
                    (activePage === "/allProduct"
                      ? " button-main-1-active"
                      : "")
                  }
                  onClick={() => {
                    setActivePage("/allProduct");
                    navigate("/allProduct");
                    closeSidebarOnMobile();
                  }}
                >
                  <img
                    alt="main"
                    style={{
                      width: "1.25vw",
                      marginRight: "0.5rem",
                      filter: "invert(100%)",
                      WebkitFilter: "invert(100%)",
                    }}
                    src={listProduct_ico}
                  ></img>
                  {langFile.pages.Main.sidebar_list_all_product}
                </button>
              ) : null}
            </li>

            <li>
              <button
                className={
                  "button-main-1 " +
                  (activePage === "/allOffer" ? " button-main-1-active" : "")
                }
                onClick={() => {
                  setActivePage("/allOffer");
                  navigate("/allOffer");
                  closeSidebarOnMobile();
                }}
              >
                <img
                  alt="main"
                  style={{
                    width: "1.25vw",
                    marginRight: "0.5rem",
                    filter: "invert(100%)",
                    WebkitFilter: "invert(100%)",
                  }}
                  src={offer_ico}
                ></img>
                {langFile.pages.Main.sidebar_all_offer}
              </button>
            </li>
          </ul>

          {user.privilege === "Admin" ? (
            <>
              <label htmlFor="userAndLog" className="sliderButton">
                <h6 className="h7-style">
                  {langFile.pages.Main.sidebarHeader4}
                </h6>
                <img
                  className="arrowIcon"
                  alt="arrow-up"
                  src={userAndLogDropdownOpen ? downArrow : rightArrow2}
                />
              </label>
              <input
                onChange={userAndLogDropdown}
                checked={userAndLogDropdownOpen}
                type="checkbox"
                id="userAndLog"
              ></input>
              <ul className={`slide ${userAndLogDropdownOpen ? "open" : ""}`}>
                <li>
                  <button
                    className={
                      "button-main-1 " +
                      (activePage === "/newUser" ? " button-main-1-active" : "")
                    }
                    onClick={() => {
                      setActivePage("/newUser");
                      navigate("/newUser");
                      closeSidebarOnMobile();
                    }}
                  >
                    <img
                      alt="main"
                      style={{
                        width: "1.25vw",
                        marginRight: "0.5rem",
                        filter: "invert(100%)",
                        WebkitFilter: "invert(100%)",
                      }}
                      src={addUser_ico}
                    ></img>
                    {langFile.pages.Main.sidebar_new_user}
                  </button>
                </li>
                <li>
                  <button
                    className={
                      "button-main-1 " +
                      (activePage === "/newCompany"
                        ? " button-main-1-active"
                        : "")
                    }
                    onClick={() => {
                      setActivePage("/newCompany");
                      navigate("/newCompany");
                      closeSidebarOnMobile();
                    }}
                  >
                    <img
                      alt="main"
                      style={{
                        width: "1.25vw",
                        marginRight: "0.5rem",
                        filter: "invert(100%)",
                        WebkitFilter: "invert(100%)",
                      }}
                      src={addCompany_ico}
                    ></img>
                    {langFile.pages.Main.sidebar_new_company}
                  </button>
                </li>
                <li>
                  <button
                    className={
                      "button-main-1 " +
                      (activePage === "/allUser" ? " button-main-1-active" : "")
                    }
                    onClick={() => {
                      setActivePage("/allUser");
                      navigate("/allUser");
                      closeSidebarOnMobile();
                    }}
                  >
                    <img
                      alt="main"
                      style={{
                        width: "1.25vw",
                        marginRight: "0.5rem",
                        filter: "invert(100%)",
                        WebkitFilter: "invert(100%)",
                      }}
                      src={user_ico}
                    ></img>
                    {langFile.pages.Main.sidebar_list_all_user}
                  </button>
                </li>
                <li>
                  <button
                    className={
                      "button-main-1 " +
                      (activePage === "/allCompany"
                        ? " button-main-1-active"
                        : "")
                    }
                    onClick={() => {
                      setActivePage("/allCompany");
                      navigate("/allCompany");
                      closeSidebarOnMobile();
                    }}
                  >
                    <img
                      alt="main"
                      style={{
                        width: "1.25vw",
                        marginRight: "0.5rem",
                        filter: "invert(100%)",
                        WebkitFilter: "invert(100%)",
                      }}
                      src={company_ico}
                    ></img>
                    {langFile.pages.Main.sidebar_list_all_company}
                  </button>
                </li>
                <li>
                  <button
                    className={
                      "button-main-1 " +
                      (activePage === "/logs"
                        ? " button-main-1-active"
                        : "")
                    }
                    onClick={() => {
                      setActivePage("/logs");
                      navigate("/logs");
                      closeSidebarOnMobile();
                    }}
                  >
                    <img
                      alt="main"
                      style={{
                        width: "1.25vw",
                        marginRight: "0.5rem",
                        filter: "invert(100%)",
                        WebkitFilter: "invert(100%)",
                      }}
                      src={log_ico}
                    ></img>
                    {langFile.pages.Main.sidebar_logs}
                  </button>
                </li>
                <li>
                  <button
                    className={
                      "button-main-1 " +
                      (activePage === "/mailConfig"
                        ? " button-main-1-active"
                        : "")
                    }
                    onClick={() => {
                      setActivePage("/mailConfig");
                      navigate("/mailConfig");
                      closeSidebarOnMobile();
                    }}
                  >
                    <img
                      alt="main"
                      style={{
                        width: "1.25vw",
                        marginRight: "0.5rem",
                        filter: "invert(100%)",
                        WebkitFilter: "invert(100%)",
                      }}
                      src={mailConfig_ico}
                    ></img>
                    {langFile.pages.Main.sidebar_mailConfig}
                  </button>
                </li>
              </ul>
            </>
          ) : null}

          <button
            className={
              sideBar === true ? "arrow-button-left" : "arrow-button-right"
            }
            onClick={() => {
              setSideBar(!sideBar);
            }}
          >
            <img
              alt="main"
              style={{
                width: "16%",
                filter: "invert(15%)",
                WebkitFilter: "invert(15%)",
              }}
              src={sideBar === true ? leftArrow : rightArrow}
            ></img>
          </button>
        </div>
        <div
          className={
            sideBar === false
              ? "main-page-style-2-close"
              : "main-page-style-2-close main-page-style-2-close-side"
          }
        >
          <div className="logo-main">
            <img
              alt="experilabs"
              src={logo2}
              style={{
                width: "2.5vw",
              }}
            ></img>
          </div>
          {user.privilege === "Admin" ? (
            <>
              <button
                className={"button-main-1".concat(
                  activePage === "/dashboard-side"
                    ? " button-main-1-active"
                    : ""
                )}
                onClick={() => {
                  setActivePage("/dashboard-side");
                  navigate("/dashboard");
                }}
              >
                <img
                  alt="dashboard"
                  style={{
                    width: "35%",
                    filter: "invert(100%)",
                    WebkitFilter: "invert(100%)",
                  }}
                  src={dashboard_ico}
                ></img>
              </button>
              <hr></hr>
              <label htmlFor="new-side" className="sliderButton">
                <img className="addon" alt="arrow-up" src={addon} />
              </label>
              <input
                onChange={newDropdown}
                checked={newDropdownOpen}
                type="checkbox"
                id="new-side"
              ></input>
              <ul className={`slide ${newDropdownOpen ? "open" : ""}`}>
                <li>
                  <button
                    className={"button-main-1".concat(
                      activePage === "/upload-side"
                        ? " button-main-1-active"
                        : ""
                    )}
                    onClick={() => {
                      setActivePage("/upload-side");
                      navigate("/upload");
                    }}
                  >
                    <img
                      alt="upload"
                      className="mentor-small-ico"
                      src={add_ico}
                    ></img>
                  </button>
                </li>
                <li>
                  <button
                    className={"button-main-1".concat(
                      activePage === "/uploadOffer-side"
                        ? " button-main-1-active"
                        : ""
                    )}
                    onClick={() => {
                      setActivePage("/uploadOffer-side");
                      navigate("/uploadOffer");
                    }}
                  >
                    <img
                      alt="uploadOffer"
                      className="mentor-small-ico"
                      src={offer_ico_2}
                    ></img>
                  </button>
                </li>
                <li>
                  <button
                    className={"button-main-1".concat(
                      activePage === "/newProduct-side"
                        ? " button-main-1-active"
                        : ""
                    )}
                    onClick={() => {
                      setActivePage("/newProduct-side");
                      navigate("/newProduct");
                    }}
                  >
                    <img
                      alt="main"
                      className="mentor-small-ico"
                      src={product_ico}
                    ></img>
                  </button>
                </li>
              </ul>
            </>
          ) : null}
          <hr></hr>

          <label htmlFor="find-side" className="sliderButton">
            <img className="magnifier" alt="arrow-up" src={search} />
          </label>
          <input
            onChange={findDropdown}
            checked={findDropdownOpen}
            type="checkbox"
            id="find-side"
          ></input>
          <ul className={`slide ${findDropdownOpen ? "open" : ""}`}>
            <li>
              <button
                className={"button-main-1".concat(
                  activePage === "/search-side" ? " button-main-1-active" : ""
                )}
                onClick={() => {
                  setActivePage("/search-side");
                  navigate("/search");
                }}
              >
                <img
                  alt="main"
                  className="mentor-small-ico"
                  src={search_ico}
                ></img>
              </button>
            </li>
            <li>
              <button
                className={"button-main-1".concat(
                  activePage === "/searchPackage-side"
                    ? " button-main-1-active"
                    : ""
                )}
                onClick={() => {
                  setActivePage("/searchPackage-side");
                  navigate("/searchPackage");
                }}
              >
                <img
                  alt="main"
                  className="mentor-small-ico"
                  src={offer_ico_3}
                ></img>
              </button>
            </li>
            <li>
              {user.privilege === "Admin" ? (
                <button
                  className={"button-main-1".concat(
                    activePage === "/allData-side"
                      ? " button-main-1-active"
                      : ""
                  )}
                  onClick={() => {
                    setActivePage("/allData-side");
                    navigate("/allData");
                  }}
                >
                  <img
                    alt="main"
                    className="mentor-small-ico"
                    src={folder_ico}
                  ></img>
                </button>
              ) : null}
            </li>
            <li>
              {user.privilege === "Admin" ? (
                <button
                  className={"button-main-1".concat(
                    activePage === "/allProduct-side"
                      ? " button-main-1-active"
                      : ""
                  )}
                  onClick={() => {
                    setActivePage("/allProduct-side");
                    navigate("/allProduct");
                  }}
                >
                  <img
                    alt="main"
                    className="mentor-small-ico"
                    src={listProduct_ico}
                  ></img>
                </button>
              ) : null}
            </li>
            <li>
              <button
                className={"button-main-1".concat(
                  activePage === "/allOffer-side" ? " button-main-1-active" : ""
                )}
                onClick={() => {
                  setActivePage("/allOffer-side");
                  navigate("/allOffer");
                }}
              >
                <img
                  alt="main"
                  className="mentor-small-ico"
                  src={offer_ico}
                ></img>
              </button>
            </li>
          </ul>

          {user.privilege === "Admin" ? (
            <>
              <hr></hr>
              <label htmlFor="userAndLog-side" className="sliderButton">
                <img className="userAndLogIco" alt="arrow-up" src={userAndLog_ico} />
              </label>
              <input
                onChange={userAndLogDropdown}
                checked={userAndLogDropdownOpen}
                type="checkbox"
                id="userAndLog-side"
              ></input>
              <ul className={`slide ${userAndLogDropdownOpen ? "open" : ""}`}>
                <li>
                  <button
                    className={"button-main-1".concat(
                      activePage === "/newUser-side"
                        ? " button-main-1-active"
                        : ""
                    )}
                    onClick={() => {
                      setActivePage("/newUser-side");
                      navigate("/newUser");
                    }}
                  >
                    <img
                      alt="main"
                      className="mentor-small-ico"
                      src={addUser_ico}
                    ></img>
                  </button>
                </li>
                <li>
                  <button
                    className={"button-main-1".concat(
                      activePage === "/newCompany-side"
                        ? " button-main-1-active"
                        : ""
                    )}
                    onClick={() => {
                      setActivePage("/newCompany-side");
                      navigate("/newCompany");
                    }}
                  >
                    <img
                      alt="main"
                      className="mentor-small-ico"
                      src={addCompany_ico}
                    ></img>
                  </button>
                </li>
                <li>
                  <button
                    className={"button-main-1".concat(
                      activePage === "/allUser-side"
                        ? " button-main-1-active"
                        : ""
                    )}
                    onClick={() => {
                      setActivePage("/allUser-side");
                      navigate("/allUser");
                    }}
                  >
                    <img
                      alt="main"
                      className="mentor-small-ico"
                      src={user_ico}
                    ></img>
                  </button>
                </li>
                <li>
                  <button
                    className={"button-main-1".concat(
                      activePage === "/allCompany-side"
                        ? " button-main-1-active"
                        : ""
                    )}
                    onClick={() => {
                      setActivePage("/allCompany-side");
                      navigate("/allCompany");
                    }}
                  >
                    <img
                      alt="main"
                      className="mentor-small-ico"
                      src={company_ico}
                    ></img>
                  </button>
                </li>
                <li>
                  <button
                    className={"button-main-1".concat(
                      activePage === "/logs-side"
                        ? " button-main-1-active"
                        : ""
                    )}
                    onClick={() => {
                      setActivePage("/logs-side");
                      navigate("/logs");
                    }}
                  >
                    <img
                      alt="main"
                      className="mentor-small-ico"
                      src={log_ico}
                    ></img>
                  </button>
                </li>
                <li>
                  <button
                    className={"button-main-1".concat(
                      activePage === "/mailConfig-side"
                        ? " button-main-1-active"
                        : ""
                    )}
                    onClick={() => {
                      setActivePage("/mailConfig-side");
                      navigate("/mailConfig");
                    }}
                  >
                    <img
                      alt="main"
                      className="mentor-small-ico"
                      src={mailConfig_ico}
                    ></img>
                  </button>
                </li>
              </ul>
            </>
          ) : null}

          <button
            className={
              sideBar === true ? "arrow-button-left" : "arrow-button-right"
            }
            onClick={() => {
              setSideBar(!sideBar);
            }}
          >
            <img
              alt="main"
              style={{
                width: "60%",
                filter: "invert(15%)",
                WebkitFilter: "invert(15%)",
              }}
              src={sideBar === true ? leftArrow : rightArrow}
            ></img>
          </button>
        </div>

        <div
          className={
            sideBar === true
              ? "main-page-style main-page-style-side"
              : "main-page-style"
          }
        >
          <main style={{ width: "100%", height: "100%" }}>{children}</main>
        </div>
      </div>
    </div>
  );
}
