import React, { useEffect, useState } from 'react'
import "./OfferSelectionContainer.css"
import LogOutEndPoint from '../LogOutEndPoint'
import { useNavigate } from 'react-router-dom'

export default function OfferSelectionContainer(props) {
  const navigate = useNavigate();
  const [offers, setOffers] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [selectedOfferFromDropdown, setSelectedOfferFromDropdown] = useState("")
  const [dropdownLinksVisible, setDropdownLinksVisible] = useState(false);
  useEffect(() => {
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_ALL_OFFER_BY_GROUPS,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        if(data.responseData!==null){
        setOffers(data.responseData)
        }
        return data;
      })
      .catch((error) => {
        let temp = [...props.alertList];
        temp.push({ status: "error", text: "Get Offers Failed" });
        props.setAlertList([...temp]);
        // error.json().then(function (error) {
        //   console.log(error);
        // });
        console.log(error);
      });
  }, [])

  // function groupByDesc(arr) {
  //   const groupedOffers = {};

  //   arr.forEach((item) => {
  //     const desc = item.desc;

  //     if (!groupedOffers[desc]) {
  //       groupedOffers[desc] = {
  //         desc: desc,
  //         offerList: [],
  //       };
  //     }

  //     const offerData = {
  //       offerId: item.offerId,
  //       property: item.property,
  //     };

  //     groupedOffers[desc].offerList.push(offerData);
  //   });

  //   return Object.values(groupedOffers);
  // }


  const filteredOffers = offers.filter((offer) => {
    return (
      offer.desc.toLowerCase().includes(searchValue.toLowerCase()) &&
      !props.selectedOffersList.some((selectedOffer) => selectedOffer === offer.desc)
    );
  });

  const handleInputFocus = () => {
    setDropdownLinksVisible(true);
  };
  const handleInputBlur = () => {
    setTimeout(() => {
      setDropdownLinksVisible(false);
    }, 200);
  };

  function HandleAddOffer() {
    if (selectedOfferFromDropdown !== "") {
      let temp = [...props.selectedOffersList]
      temp.push(selectedOfferFromDropdown)
      props.setSelectedOffersList(temp)
      setSelectedOfferFromDropdown("")
      setSearchValue("")
    }
  }

  function DeleteOfferHandle(index){
    let temp = [...props.selectedOffersList]
    temp.splice(index, 1);
    props.setSelectedOffersList(temp)
  }
  return (
    <div className='offer-selection-container'>
      <div className='offer-dropdown-container'>
        <input
          type='text'
          placeholder='Lütfen Teklif Limiti:'
          id='offerLimitInput'
          name='offerLimitInput'
          value={props.langFile.pages.NewProduct.offerLimit+" 1"}
          disabled
        />
        <div id="myDropdown" className="dropdown-content">
          <input
            type='text'
            placeholder={props.langFile.pages.NewProduct.selectOffer}
            id='searchOfferInput'
            name='searchOfferInput'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
          {filteredOffers.map((offer, index) => (
            <a
              href={'#' + offer.desc}
              key={index}
              value={index}
              onClick={(e) => {
                setSearchValue(offer.desc);
                setSelectedOfferFromDropdown(offer.desc)
              }}
              style={{ display: dropdownLinksVisible ? 'block' : 'none' }}
            >
              {offer.desc}
            </a>
          ))}
        </div>
        <button className='add_button' onClick={HandleAddOffer}>
          <img alt='add'>
          </img>
        </button>
      </div>
      <div className='selected-offers-list-container'>
        {props.selectedOffersList.map((item, index) => (
          <div key={index} className='selected-offer-item'>
            <label>
              {1}
            </label>
            <label>
              {item}
            </label>
            <button onClick={()=>DeleteOfferHandle(index)} className='delete-button'>
              <img alt='delete'>
              </img>
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}
