import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import "../styles/Upload.css";
import add_icon from "../assets/import.png";
import { json, useNavigate } from "react-router-dom";
import loadingGif from "../assets/loading3.gif";
import * as xlsx from "xlsx";
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import { parseISO, parse, format } from "date-fns";
import "../styles/UploadOffer.css";
import LogOutEndPoint from "../components/LogOutEndPoint";

export default function UploadOffer() {
  const [langFile, setLangFile] = useState(en);
  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);

  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [dataFromExcel, setDataFromExcel] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [nextButton, setNextButton] = useState(false);
  const [dropdownLinksVisible, setDropdownLinksVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("+ Yeni Paket");
  const [selectedOffer, setSelectedOffer] = useState("$#$#$#$");
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    fetch(
      process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_GET_ALL_OFFER_BY_GROUPS,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        if (data.responseData !== null) {
          setOffers(data.responseData);
        }
        return data;
      })
      .catch((error) => {
        // error.json().then(function (error) {
        //   console.log(error);
        // });
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_ALL_COMPANY,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        setCompanies(data.companies);
        if (data.companies.length !== 0)
          setSelectedCompany(JSON.stringify(data.companies[0]));
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function readUploadFile(e) {
    setLoading(true);
    setTimeout(function () {
      if (e.target.files) {
        const uploadedFile = e.target.files[0];
        setFile(uploadedFile);
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          // const workbook = xlsx.read(data, { type: "array" });
          let workbook;
          try {
            workbook = xlsx.read(data, { type: "array", password: "" });
          } catch (error) {
            if (error.message === "File is password-protected") {
              alert("Please remove password of your file and try again.");
              setLoading(false);
              return;
            } else {
              console.log("Error reading file:", error);
            }
          }

          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const options = {
            raw: false, // Hücre değerlerini otomatik olarak dönüştürür (özel karakterler dahil)
            defval: "", // Boş hücreler için varsayılan değer olarak ""
          };
          const json = xlsx.utils.sheet_to_json(worksheet, options);
          const cleanedData = json.map((row, rowIndex) => {
            const cleanedRow = {};
            for (let key in row) {
              const cleanedKey =
                key && key.replace(/↵/g, "").replace(/\s+/g, " ").trim();
              const cellValue =
                row[key] &&
                row[key].replace(/↵/g, "").replace(/\s+/g, " ").trim();
              cleanedRow[cleanedKey] = cellValue;
            }
            return cleanedRow;
          });
          setDataFromExcel(cleanedData);
        };
        reader.readAsArrayBuffer(uploadedFile);
      }
    }, 1000); // 2 saniye gecikme
  }

  useEffect(() => {
    if (dataFromExcel.length !== 0)
      navigate("/tableOffer", {
        state: {
          dataFromExcel: dataFromExcel,
          selectedCompany: selectedCompany,
          selectedOffer: selectedOffer,
        },
      });
  }, [dataFromExcel]);
  function handleNextButton() {
    const container = document.querySelector(".info-data");
    container.style.marginBottom = "80vh";
    const nextBttn = document.querySelector(".next-button");
    nextBttn.style.display = "none";
    setTimeout(function () {
      setNextButton(true);
    }, 750); // 2 saniye gecikme
  }
  const handleInputFocus = () => {
    setDropdownLinksVisible(true);
  };
  const handleInputBlur = () => {
    setTimeout(() => {
      setDropdownLinksVisible(false);
    }, 200);
  };

  return (
    <>
      <div className="upload-offer-parent">
        <div className="info-data">
          <select
            name="selectCompanyImport"
            id="selectCompanyImport"
            onChange={(event) => setSelectedCompany(event.target.value)}
          >
            {companies.map((company, index) => (
              <option key={index} value={JSON.stringify(company)}>
                {company.name}
              </option>
            ))}
          </select>
          <div id="myDropdown" className="dropdown-content">
            <input
              type="text"
              placeholder="Lütfen Paket Seçiniz:"
              id="searchOfferInput"
              name="searchOfferInput"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              autoComplete="off"
            />
            {offers.map((offer, index) => (
              <a
                href={"#" + offer.desc}
                key={index}
                value={index}
                onClick={(e) => {
                  setSearchValue(offer.desc);
                  setSelectedOffer(offer.desc);
                }}
                style={{ display: dropdownLinksVisible ? "block" : "none" }}
              >
                {offer.desc}
              </a>
            ))}
            <a
              href={"#newOffer"}
              value={-1}
              onClick={(e) => {
                setSearchValue("+ Yeni Paket");
                setSelectedOffer("$#$#$#$"); //for a unique entry
              }}
              style={{
                display: dropdownLinksVisible ? "block" : "none",
                backgroundColor: "grey",
              }}
            >
              + Yeni Paket
            </a>
          </div>

          {!nextButton ? (
            <button className="next-button" onClick={() => handleNextButton()}>
              {langFile.pages.Upload.nextButton}
            </button>
          ) : null}
        </div>

        {nextButton ? (
          <>
            {loading && (
              <div className="load">
                <img src={loadingGif} alt="loading" />
              </div>
            )}
            <input
              type="file"
              id="file"
              accept=".xlsx , .csv"
              required="required"
              onChange={
                selectedCompany !== ""
                  ? readUploadFile
                  : alert("Lütfen Şirket Alanını Seçiniz")
              }
            />
            <label className="choose-file" htmlFor="file">
              <label>{langFile.pages.Upload.importButtonText}</label>
              <img src={add_icon} alt="addExcel"></img>
            </label>
          </>
        ) : null}
      </div>
    </>
  );
}
