import React, { useEffect, useState } from "react";
import PieChart from "../PieChart/PieChart";
import en from "../../../locales/en.json";
import tr from "../../../locales/tr.json";
import "./RelationalPieChart.css"

export default function RelationalPieChart(props) {
  const [langFile, setLangFile] = useState(en);
  const [data, setData] = useState([ {
         type: 'Product-1',
         value: 27,
         id:1
       }]);
  const [data2, setData2] = useState([])
  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);
  const [selectedPiece1, setSelectedPiece1] = useState({type:"TS Siber Koruma-86119"});//default value
  const [selectedPiece2, setSelectedPiece2] = useState("");

  useEffect(()=>{
    let tempData = []
    if(props.statistic !== 0){
      Object.entries(props.statistic.productPerTemplate).map(([key, value],index) => {
        let obj = {type:key, value:value, id:index}
        tempData.push(obj)
      });
      setData([...tempData])
    }
  },[props.statistic])

  useEffect(()=>{
    let tempArr = []
    if(props.statistic !== 0){
      tempArr.push({
        type: 'Active',
        value: selectedPiece1 !== "" ?  props.statistic.activeProductCount[selectedPiece1.type]:0,
        id:0})
        tempArr.push({
          type: 'Deactive',
          value: selectedPiece1 !== "" ?  props.statistic.inactiveProductCount[selectedPiece1.type]:0,
          id:0})
          setData2(tempArr)
    }
  },[selectedPiece1,props.statistic])

  // const data2 = [
  //   {
  //     type: 'Active',
  //     value: 20,
  //     id:1
  //   },
  //   {
  //     type: 'Deactive',
  //     value: 30,
  //     id:2
  //   },
  //    {
  //      type: 'Activity',
  //      value: 0,
  //      id:0
  //    }
  // ]
  return (
    <div
    className="relational-pie-chart-parent"

    >
      <div
        className="pie-chart-item"
      >
        <label className="chart-header" >{langFile.pages.RelationalPieChart.productRatio}</label>
        <PieChart setSelectedPiece={setSelectedPiece1} data={data}></PieChart>
      </div>
      <div
      className="pie-chart-item"
      >
        <label className="chart-header-2">
          {langFile.pages.RelationalPieChart.activeDeactiveRatio}</label>
          <label className="selected-product-text">
             {(selectedPiece1.type!==undefined?"("+selectedPiece1.type+")":"")}
          </label>
        <PieChart setSelectedPiece={setSelectedPiece2} data={data2}></PieChart>
      </div>
    </div>
  );
}
