import React, { useEffect, useState } from "react";
import "../styles/Logs.css";
import ico from "../assets/transaction.png";
import userIco from "../assets/user-2.png";
import LogOutEndPoint from "../components/LogOutEndPoint";
import { useNavigate } from "react-router-dom";
import en from "../locales/en.json";
import tr from "../locales/tr.json";

export default function Logs() {
  useEffect(() => {});
  const [langFile, setLangFile] = useState(en);
  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);
  const [loadingForLoadMore, setLoadingForLoadMore] = useState(false);
  const [counterForPagination, setCounterForPagination] = useState(20);
  const navigate = useNavigate();
  const [logData, setLogData] = useState([]);

  const [isCheckedProductAndPackage, setIsCheckedProductAndPackage] = useState(false); // Başlangıçta checked olarak işaretli olsun
  const [isCheckedUser, setIsCheckedUser] = useState(false); // Başlangıçta checked olarak işaretli olsun
  const [isCheckedAll, setIsCheckedAll] = useState(true); // Başlangıçta checked olarak işaretli olsun

  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    date.setHours(date.getHours());
    return date.toLocaleString("tr-TR", {
      timeZone: 'Europe/Istanbul',
       year: 'numeric',
       month: '2-digit',
       day: '2-digit',
       hour: '2-digit',
       minute: '2-digit',
       second: '2-digit',
    });
  };

  const sendApiRequest = () => {
    const requestBody = {
      isPackage: isCheckedProductAndPackage,
      isProduct: isCheckedProductAndPackage,
      isUser: isCheckedUser,
      isAll: isCheckedAll,
      pageNumber: 1,
      pageSize: counterForPagination,
    };

    fetch(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_LOGS_GET, {
      method: "POST",
      headers: {
        accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        const logsWithFormattedDates = data.logs.map((log) => {
          let logTypeImageSrc = ico;
          if (log.content.includes("User")) {
            logTypeImageSrc = userIco;
          }
          return {
            ...log,
            createdAt: formatCreatedAt(log.createdAt),
            logTypeImage: logTypeImageSrc,
          };
        });
        setLogData(logsWithFormattedDates);
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    sendApiRequest();
  }, [
    isCheckedAll,
    isCheckedUser,
    isCheckedProductAndPackage,
    counterForPagination,
  ]);

  return (
    <div className="last-update-container">
      <div className="transactions">
        {/* <Transactions logData={logData}></Transactions> */}
        <div className="transactions-container">
          {/* <label className="transactions-header">
              {langFile.pages.Transactions.transactions}
            </label> */}
          <div className="filter-container">
            <input
              type="checkbox"
              id="all-logs-check"
              checked={isCheckedAll}
              onChange={() => setIsCheckedAll(!isCheckedAll)}
            ></input>
            <label htmlFor="all-logs-check">{"All Logs"}</label>
            <input
              type="checkbox"
              id="user-logs-check"
              checked={isCheckedUser}
              onChange={() => setIsCheckedUser(!isCheckedUser)}
            ></input>
            <label htmlFor="user-logs-check">{"User Logs"}</label>
            <input
              type="checkbox"
              id="product-logs-check"
              checked={isCheckedProductAndPackage}
              onChange={() => setIsCheckedProductAndPackage(!isCheckedProductAndPackage)}
            ></input>
            <label htmlFor="product-logs-check">{"Product & Package Logs"}</label>
          </div>
          <div className="rows-container">
            {logData &&
              logData.map((item, index) => (
                <div className="row-parent" key={index}>
                  <div className="ico-container">
                    <img
                      alt="trans-ico"
                      src={item.logTypeImage}
                      className="trans-ico"
                    ></img>
                  </div>
                  <div className="trans-text">{item.content}</div>
                  <div className="date">{item.createdAt}</div>
                </div>
              ))}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "1vh 0",
                width: "100%!important",
                height: "10%",
              }}
            >
              {
              isCheckedProductAndPackage === true ||
              isCheckedUser === true ||
              isCheckedAll === true ? (
                <div style={{ borderRadius: "5px", minWidth: "100%" }}>
                  <button
                    className="load-more-button"
                    onClick={() => {
                      setCounterForPagination(counterForPagination + 10);
                    }}
                  >
                    {loadingForLoadMore === true
                      ? // <div className="data-loader"></div>
                        langFile.pages.AllData.loadMoreButton
                      : langFile.pages.AllData.loadMoreButton}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
