import React, { useEffect, useState } from "react";
import "../styles/Dashboard.css";
import CounterBoxes from "../components/DashboardComponents/CounterBoxes/CounterBoxes";
import PercentStackedArea from "../components/DashboardComponents/PercentStackedArea/PercentStackedArea";
import PiePlot from "../components/DashboardComponents/PiePlot/PiePlot";
import Transactions from "../components/DashboardComponents/Transactions/Transactions";
import Calendar from "../components/DashboardComponents/Calendar/Calendar.js";
import product_ico from "../assets/product-2.png";
import user_ico from "../assets/user-2.png";
import policy_ico from "../assets/policy.png";
import activePolicy_ico from "../assets/active-policy.png";
import totalPolicy_ico from "../assets/paper.png";
import PieChart from "../components/DashboardComponents/PieChart/PieChart";
import RelationalPieChart from "../components/DashboardComponents/RelationalPieChart/RelationalPieChart";
import LinePlotWithSlider from "../components/DashboardComponents/LinePlotWithSlider/LinePlotWithSlider.js";
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import BarChart from "../components/DashboardComponents/BarChart/BarChart";
import LogOutEndPoint from "../components/LogOutEndPoint";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  useEffect(() => {});
  const [langFile, setLangFile] = useState(en);
  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);

  const navigate = useNavigate();
  const [statistic, setStatistic] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(
    currentDate.toISOString().substr(0, 10)
  );
  const [stackedAreaChartDataType, setStackedAreaChartDataType] =
    useState("day");
  const [
    stackedAreaChartDataCalculationType,
    setStackedAreaChartDataCalculationType,
  ] = useState("contByAdd");
  useEffect(() => {
    const toggle = document.getElementById("myCheckbox");
    toggle.addEventListener("change", function () {
      if (this.checked) {
        setStackedAreaChartDataType("day");
      } else {
        setStackedAreaChartDataType("month");
      }
    });
    return () => {
      toggle.removeEventListener("change", () => {});
    };
  }, []);
  useEffect(() => {
    const toggle = document.getElementById("myCheckbox2");
    toggle.addEventListener("change", function () {
      if (this.checked) {
        setStackedAreaChartDataCalculationType("contByAdd");
      } else {
        setStackedAreaChartDataCalculationType("contWithoutAdd");
      }
    });
    return () => {
      toggle.removeEventListener("change", () => {});
    };
  }, []);
  useEffect(() => {
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_TEMPLATE_GET_ALL,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        setProducts(data.templates);
        if (data.templates.length > 1) {
          setSelectedProduct(data.templates[1].name);
        } else if (data.templates.length !== 0) {
          setSelectedProduct(data.templates[0].name);
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // useEffect(() => {
  //   fetch(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_LOGS_GET, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       Authorization:
  //         "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
  //     },
  //   })
  //     .then((res) => {
  //       if (res.ok) {
  //         return res.json();
  //       }
  //       else if (res.status === 401) {
  //         LogOutEndPoint(navigate);
  //       }
  //       throw res;
  //     })
  //     .then((data) => {
  //       // console.log("DATA",data)
  //       setLogData(data.content);
  //       return data;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);


  useEffect(() => {
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_STATISTIC_GET,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        setStatistic(data);
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="dashboard-container">
      <div className="counter-area-calender-container">
        <div className="counter-area-container">
          <div className="counter-container">
            <CounterBoxes
              text={langFile.pages.Dashboard.totalUser}
              count={statistic !== "" ? statistic.userStatistic?.userCount : 0}
              backgroundColor={"#AEC8D7"}
              src={user_ico}
            ></CounterBoxes>
            <CounterBoxes
              text={langFile.pages.Dashboard.productCounter}
              count={
                statistic !== ""
                  ? statistic.templateStatistic?.templateCount
                  : 0
              }
              backgroundColor={"#E6CDD8"}
              src={product_ico}
            ></CounterBoxes>
            <CounterBoxes
              text={langFile.pages.Dashboard.totalPolicy}
              count={
                statistic !== "" ? statistic.productStatistic?.productCount : 0
              }
              backgroundColor={"#CCE3D0"}
              src={totalPolicy_ico}
            ></CounterBoxes>
            <CounterBoxes
              text={langFile.pages.Dashboard.totalActivePolicy}
              count={
                statistic !== ""
                  ? statistic.productStatistic?.activeProductCount
                  : 0
              }
              backgroundColor={"#EAD9F2"}
              src={activePolicy_ico}
            ></CounterBoxes>
            <CounterBoxes
              text={langFile.pages.Dashboard.totalDeactivePolicy}
              count={
                statistic !== ""
                  ? statistic.productStatistic?.inactiveProductCount
                  : 0
              }
              backgroundColor={"#F4E8D5"}
              src={policy_ico}
            ></CounterBoxes>
          </div>
          <div className="counter-container">
            <CounterBoxes
              text={langFile.pages.Dashboard.offerTemplate}
              count={
                statistic !== ""
                  ? statistic.offerStatistic?.offerGroups.length
                  : 0
              }
              backgroundColor={"#E6CDD8"}
              src={product_ico}
            ></CounterBoxes>
            <CounterBoxes
              text={langFile.pages.Dashboard.totalOffer}
              count={
                statistic !== "" ? statistic.offerStatistic?.totalOfferCount : 0
              }
              backgroundColor={"#CCE3D0"}
              src={totalPolicy_ico}
            ></CounterBoxes>
            <CounterBoxes
              text={langFile.pages.Dashboard.activeOffer}
              count={
                statistic !== ""
                  ? statistic.offerStatistic?.totalAvailableOfferCount
                  : 0
              }
              backgroundColor={"#EAD9F2"}
              src={activePolicy_ico}
            ></CounterBoxes>
            <CounterBoxes
              text={langFile.pages.Dashboard.deactiveOffer}
              count={
                statistic !== ""
                  ? statistic.offerStatistic?.totalUnavailableOfferCount
                  : 0
              }
              backgroundColor={"#F4E8D5"}
              src={policy_ico}
            ></CounterBoxes>
          </div>
          <div className="area-relational-pie-container">
            <div className="percentStackedArea-container">
              {/* <PercentStackedArea></PercentStackedArea> */}
              <div className="navigationForChart">
                <label className="headerForComponents">
                  {langFile.pages.Dashboard.dailyPolicyBarChart}
                </label>

                <div className="selectionContainer">
                  <select
                    name="selectProduct"
                    id="selectProduct"
                    onChange={(event) => setSelectedProduct(event.target.value)}
                    value={selectedProduct}
                  >
                    {products.map((product, index) => (
                      <option key={index} value={product.name}>
                        {product.name}
                      </option>
                    ))}
                    <option value={"*"}>{"Hepsi"}</option>
                  </select>
                  <div className="chart-type-toggle">
                    <label className="switch">
                      <input id="myCheckbox" type="checkbox" defaultChecked />
                      <span className="slider round"></span>
                    </label>
                    <label htmlFor="myCheckbox">
                      {stackedAreaChartDataType === "day"
                        ? langFile.pages.Dashboard.lastTenDay
                        : langFile.pages.Dashboard.lastTenMounth}
                    </label>
                  </div>
                </div>

                <div className="selectionContainer">
                  <input
                    name="dateForChart"
                    id="dateForChart"
                    onChange={(event) => setSelectedDate(event.target.value)}
                    value={selectedDate}
                    type="date"
                  ></input>

                  <div className="chart-type-toggle">
                    <label className="switch">
                      <input id="myCheckbox2" type="checkbox" defaultChecked />
                      <span className="slider round"></span>
                    </label>
                    <label htmlFor="myCheckbox2">
                      {stackedAreaChartDataCalculationType === "contByAdd"
                        ? langFile.pages.Dashboard.contAdd
                        : langFile.pages.Dashboard.contWithoutAdd}
                    </label>
                  </div>
                </div>
              </div>

              {/* <LinePlotWithSlider></LinePlotWithSlider> */}
              <div style={{ width: "100%", height: "75%" }}>
                <BarChart
                  selectedDate={selectedDate}
                  selectedProduct={selectedProduct}
                  data={
                    statistic.productStatistic?.distinctProductByDateWithCount
                  }
                  stackedAreaChartDataType={stackedAreaChartDataType}
                  stackedAreaChartDataCalculationType={
                    stackedAreaChartDataCalculationType
                  }
                ></BarChart>
              </div>
              {/* <div className="update-notification">Güncel Tablo Değildir</div> */}
            </div>

            <div className="relationalPieChart-container">
              <RelationalPieChart
                statistic={statistic !== "" ? statistic.templateStatistic : 0}
              ></RelationalPieChart>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
