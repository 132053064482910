import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { json, useLocation, useNavigate } from "react-router-dom";
import "../styles/Search.css";
import Alert from "../components/Alert/Alert";
import IdentityCard from "../components/IdentityCard/IdentityCard";
import loadingGif from "../assets/loading3.gif";
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import UseOffer from "../components/UseOffer/UseOffer";
import search_ico from "../assets/search.png";
import LogOutEndPoint from "../components/LogOutEndPoint";

export default function Search() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [dataFromExcel, setDataFromExcel] = useState([]);
  const [pureDataFromExcel, setPureDataFromExcel] = useState([]);
  const [titles, setTitles] = useState([]);
  const [submitButtonText, setSubmitButtonText] = useState("Update");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [products, setProducts] = useState([]);
  const [counterForPagination, setCounterForPagination] = useState(1);
  const [searchValue, setSearchValue] = useState(
    ""
    // {event: { target: { value: "" } }}
  );
  const [loadingForLoadMore, setLoadingForLoadMore] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [selectedSearchType, setSelectedSearchType] = useState("");
  const [searchTypes, setSearchTypes] = useState([]);
  const [selectedProductToUpdate, setSelectedProductToUpdate] = useState();
  const [email, setEmail] = useState("");
  const [insured, setInsured] = useState("");
  const [productNo, setProductNo] = useState("");
  ///////////////////
  const [alertList, setAlertList] = useState([]);
  const [tempArr, setTempArr] = useState([...alertList]);
  useEffect(() => {
    setTempArr(alertList);
  }, [alertList]);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  ///////////////////
  const [langFile, setLangFile] = useState(en);
  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);
  ///////////////////

  useEffect(() => {
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_ALL_COMPANY,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        setCompanies(data.companies);
        if (data.companies.length !== 0)
          setSelectedCompany(data.companies[0].id);
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    //to detect titles
    if (dataFromExcel !== undefined && dataFromExcel.length > 0) {
      let temp = Object.entries(dataFromExcel[0]);
      let arrForTittle = [];
      temp.map((item) => {
        arrForTittle.push({ name: item[0], filter: true }); //eğer filter özelliğini false yaparsanız o kolon filterelenir
      });
      setTitles(arrForTittle);
    }
  }, [dataFromExcel]);

  useEffect(() => {
    if (selectedCompany !== "") {
      fetch(
        process.env.REACT_APP_BASE_URL +
          process.env.REACT_APP_GET_BY_COMPNAYID +
          selectedCompany,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
          },
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else if (res.status === 401) {
            LogOutEndPoint(navigate);
          }
          throw res;
        })
        .then((data) => {
          setProducts(data.templates);
          if (data.templates.length !== 0)
            setSelectedTemplate(data.templates[0].templateId);
          return data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [selectedCompany]);

  // useEffect(() => {
  //   fetch(
  //     process.env.REACT_APP_BASE_URL + process.env.REACT_APP_TEMPLATE_GET_ALL,
  //     {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         Authorization:
  //           "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
  //       },
  //     }
  //   )
  //     .then((res) => {
  //       if (res.ok) {
  //         return res.json();
  //       }
  //       else if (res.status === 401) {
  //         LogOutEndPoint(navigate);
  //       }
  //       throw res;
  //     })
  //     .then((data) => {
  //       setProducts(data.templates);
  //       if (data.templates.length !== 0)
  //         setSelectedTemplate(data.templates[0].id);
  //       return data;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  useEffect(() => {
    if (selectedTemplate !== "") {
      GetPropertiesOfTemplate(selectedTemplate);
    }
  }, [selectedTemplate]);

  function GetPropertiesOfTemplate(templateId) {
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_TEMPLATE_BY_ID,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
        body: JSON.stringify({
          id: templateId,
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        setSearchTypes(data.template.properties);
        if (data.template.properties.length !== 0) {
          for (let i = 0; i < data.template.properties.length; i++) {
            const item = data.template.properties[i];
            const lowerCaseName = item.name?.toLowerCase();
            if (
              lowerCaseName &&
              (lowerCaseName === "pol no" || lowerCaseName === "poliçe no")
            ) {
              setSelectedSearchType(JSON.stringify(item));
              break;
            } else if (
              lowerCaseName &&
              (lowerCaseName.includes("pol no") ||
                lowerCaseName.includes("poliçe no") ||
                lowerCaseName.includes("kimlik") ||
                lowerCaseName.includes("tckn"))
            ) {
              setSelectedSearchType(JSON.stringify(item));
            }
          }
        }

        let temp = [...alertList];
        temp.push({
          status: "success",
          text: data.message,
        });
        setAlertList([...temp]);
        return data;
      })
      .catch((error) => {
        let temp = [...alertList];
        temp.push({
          status: "error",
          text: "Getting Properties Of Template Failed",
        });
        setAlertList([...temp]);
        console.log(error);
      });
  }
  function SaveData(data) {
    let arr = [];
    for (let j = 0; j < data.length; j++) {
      let obj = {};
      for (let i = 0; i < data[j].productProperties.length; i++) {
        obj[data[j].productProperties[i].name] =
          data[j].productProperties[i].value.value;
      }
      obj["isActive"] = data[j].isActive === true ? "true" : "false";
      arr.push(obj);
    }
    setDataFromExcel(arr);
    setPureDataFromExcel(arr);
  }

  function CreteBodyForSearch() {
    const user = JSON.parse(localStorage.getItem("user"));
    let propertyId = JSON.parse(selectedSearchType)?.id;
    let body = {
      //  "userId": user.id,
      templateId: selectedTemplate,
      propertyValues: {
        [propertyId]: searchValue,
      },
      pageSize: 10 * counterForPagination,
      pageNumber: 1,
    };
    return body;
  }

  useEffect(() => {
    SearchAndGetPolicy();
  }, [counterForPagination, searchTrigger]);

  function SearchAndGetPolicy() {
    if (
      selectedTemplate !== "" &&
      selectedSearchType !== "" &&
      searchValue !== ""
    ) {
      setSelectedProductToUpdate();
      setLoadingPage(true);
      let body = JSON.stringify(CreteBodyForSearch());
      // console.log(body)
      fetch(
        process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PRODUCT_GET_ALL,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
          },
          body: body,
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else if (res.status === 401) {
            LogOutEndPoint(navigate);
          }
          throw res;
        })
        .then((data) => {
          // console.log(data);
          if (data.products !== null) {
            SaveData(data.products);
            setData(data.products);

            if (data.products !== null && data.products.length > 0) {
              if (data.products.length === 1) {
                const singleProduct = data.products[0];
                setProductNo(singleProduct.productCode);

                for (const property of singleProduct.productProperties) {
                  if (property.name.toLocaleLowerCase() === "email") {
                    setEmail(property.value.value);
                  } else if (
                    property.name.toLocaleLowerCase() === "sigortalı"
                  ) {
                    setInsured(property.value.value);
                  }
                }
                setSelectedProductToUpdate(singleProduct);
              }
            }
          } else {
            setDataFromExcel([]);
            setPureDataFromExcel([]);
            setTitles([]);
          }
          let temp = [...alertList];
          temp.push({ status: "success", text: data.messages });
          setAlertList([...temp]);
          setLoadingForLoadMore(false);
          setLoadingPage(false);
          return data;
        })
        .catch((error) => {
          let temp = [...alertList];
          temp.push({ status: "error", text: "Getting Data Failed" });
          setAlertList([...temp]);
          console.log(error);
          setLoadingForLoadMore(false);
          setLoadingPage(false);
        });
    }
  }

  useEffect(() => {
    if (data.length > 1) {
      if (selectedProductToUpdate) {
        setProductNo(selectedProductToUpdate.productCode);

        for (const property of selectedProductToUpdate.productProperties) {
          if (property.name.toLocaleLowerCase() === "email") {
            setEmail(property.value.value);
          } else if (property.name.toLocaleLowerCase() === "sigortalı") {
            setInsured(property.value.value);
          }
        }
      }
    }
  }, [selectedProductToUpdate]);
  // console.log("1", selectedProductToUpdate);

  function handleSubmit() {
    if (submitButtonText === "Update") {
      setTimeout(function () {
        setPureDataFromExcel(JSON.parse(JSON.stringify(dataFromExcel)));
        let temp = [...alertList];
        temp.push({ status: "success", text: "Updating Successful" });
        setAlertList([...temp]);
      }, 1000);
    }
  }

  return (
    <>
      {loadingPage && (
        <div className="load">
          <img src={loadingGif} alt="loading" />
          <label>{langFile.pages.Search.loadWarning}</label>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          position: "absolute",
          right: "1vw",
          height: "min-content",
          bottom: "0vh",
        }}
      >
        {tempArr.map((item, index) => {
          return (
            <Alert
              index={index}
              key={index}
              time={5000}
              status={item.status}
              text={item.text}
            ></Alert>
          );
        })}
      </div>
      <div className="search-container">
        <div className="navigation-container">
          <select
            name="selectCompany"
            id="selectCompany"
            onChange={(event) => {
              setSelectedCompany(event.target.value);
              setSelectedProductToUpdate();
              setDataFromExcel([]);
            }}
          >
            {companies.map((company, index) => (
              <option
                key={index}
                value={company.id}
                // value={JSON.stringify(company)}
              >
                {company.name}
              </option>
            ))}
          </select>
          <select
            name="selectProduct"
            id="selectProduct"
            onChange={(event) => {
              setSelectedTemplate(event.target.value);
              setSelectedProductToUpdate();
              setDataFromExcel([]);
            }}
          >
            {products.map((product, index) => (
              <option key={index} value={product.templateId}>
                {product.templateName}
              </option>
            ))}
          </select>

          <select
            name="selectSearchType"
            id="selectSearchType"
            onChange={(event) => {
              setSelectedSearchType(event.target.value);
              setSelectedProductToUpdate();
              setDataFromExcel([]);
            }}
            value={selectedSearchType}
          >
            {searchTypes.map((property, index) =>
              property.name?.toLocaleLowerCase().includes("pol no") ||
              property.name?.toLocaleLowerCase().includes("poliçe no") ||
              property.name?.toLocaleLowerCase().includes("kimlik") ||
              property.name?.toLocaleLowerCase().includes("tckn") ? (
                <option key={index} value={JSON.stringify(property)}>
                  {property.name}
                </option>
              ) : null
            )}
          </select>

          <input
            id="search"
            type="search"
            placeholder={
              langFile.pages.Search.placeholderSearch +
              " for " +
              (selectedSearchType !== ""
                ? JSON.parse(selectedSearchType).name
                : "")
            }
            required
            onChange={(e) => {
              // setSearchValue({ event: { target: { value: e.target.value } } });
              // handleSearch(e);
              setSearchValue(e.target.value);
            }}
          />
          <button className="search-button" onClick={SearchAndGetPolicy}>
            <img className="search-ico" alt="search" src={search_ico}></img>
          </button>
        </div>
        <div
          className="card-container"
          style={{ height: selectedProductToUpdate ? "30%" : "100%" }}
        >
          {dataFromExcel &&
            dataFromExcel.map(
              (item, rowIndex) => (
                // indexesForSearch[rowIndex] === true ? (
                <IdentityCard
                  alertList={alertList}
                  setAlertList={setAlertList}
                  key={rowIndex}
                  index={rowIndex}
                  titles={titles}
                  item={item}
                  data={data[rowIndex]}
                  setSearchTrigger={setSearchTrigger}
                  searchTrigger={searchTrigger}
                  setSelectedProductToUpdate={setSelectedProductToUpdate}
                  selectedProductToUpdate={selectedProductToUpdate}
                ></IdentityCard>
              )

              // ) : null
            )}
          {dataFromExcel.length > 0 ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="load-more-button"
                onClick={() => {
                  setCounterForPagination(counterForPagination + 1);
                }}
              >
                {loadingForLoadMore === true ? (
                  <div></div>
                ) : (
                  langFile.pages.Search.loadMore
                )}
              </button>
            </div>
          ) : null}
        </div>
        <div
          className="use-offer-container"
          style={{ height: selectedProductToUpdate ? "35%" : "0%" }}
        >
          <UseOffer
            langFile={langFile}
            alertList={alertList}
            setAlertList={setAlertList}
            selectedTemplate={selectedTemplate}
            selectedProductToUpdate={selectedProductToUpdate}
            setSelectedProductToUpdate={setSelectedProductToUpdate}
            data={data}
            email={email}
            insured={insured}
            productNo={productNo}
          ></UseOffer>
        </div>
      </div>
    </>
  );
}
