import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import "../styles/Upload.css";
import add_icon from "../assets/import.png";
import { json, useNavigate } from "react-router-dom";
import loadingGif from "../assets/loading3.gif";
import * as xlsx from "xlsx";
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import { parseISO, parse, format } from "date-fns";
import LogOutEndPoint from "../components/LogOutEndPoint";

export default function Upload() {
  const [langFile, setLangFile] = useState(en);
  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [dataFromExcel, setDataFromExcel] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [nextButton, setNextButton] = useState(false);
  const dateFormats = [
    ///////////
    "dd.MM.yyyy HH:mm:ss",
    "dd/MM/yyyy HH:mm:ss",
    "dd.MM.yyyy HH:mm",
    "dd/MM/yyyy HH:mm",
    "dd.MM.yyyy H:mm",
    "dd/MM/yyyy H:mm",
    "dd.MM.yyyy",
    "dd/MM/yyyy",
    "d.MM.yyyy HH:mm:ss",
    "d/MM/yyyy HH:mm:ss",
    "d.MM.yyyy HH:mm",
    "d/MM/yyyy HH:mm",
    "d.MM.yyyy H:mm",
    "d/MM/yyyy H:mm",
    "d.MM.yyyy",
    "d/MM/yyyy",
    ///////////
    "dd.MM.yy HH:mm:ss",
    "dd/MM/yy HH:mm:ss",
    "dd.MM.yy HH:mm",
    "dd/MM/yy HH:mm",
    "dd.MM.yy H:mm",
    "dd/MM/yy H:mm",
    "dd.MM.yy",
    "dd/MM/yy",
    "d.MM.yy HH:mm:ss",
    "d/MM/yy HH:mm:ss",
    "d.MM.yy HH:mm",
    "d/MM/yy HH:mm",
    "d/MM/yy H:mm",
    "d.MM.yy H:mm",
    "d.MM.yy",
    "d/MM/yy",
    ////////////
    "dd.M.yyyy HH:mm:ss",
    "dd/M/yyyy HH:mm:ss",
    "dd.M.yyyy HH:mm",
    "dd/M/yyyy HH:mm",
    "dd.M.yyyy H:mm",
    "dd/M/yyyy H:mm",
    "dd.M.yyyy",
    "dd/M/yyyy",
    "d.M.yyyy HH:mm:ss",
    "d/M/yyyy HH:mm:ss",
    "d.M.yyyy HH:mm",
    "d/M/yyyy HH:mm",
    "d.M.yyyy H:mm",
    "d/M/yyyy H:mm",
    "d.M.yyyy",
    "d/M/yyyy",
    ///////////
    "dd.M.yy HH:mm:ss",
    "dd/M/yy HH:mm:ss",
    "dd.M.yy HH:mm",
    "dd/M/yy HH:mm",
    "dd.M.yy H:mm",
    "dd/M/yy H:mm",
    "dd.M.yy",
    "dd/M/yy",
    "d.M.yy HH:mm:ss",
    "d/M/yy HH:mm:ss",
    "d.M.yy HH:mm",
    "d/M/yy HH:mm",
    "d/M/yy H:mm",
    "d.M.yy H:mm",
    "d.M.yy",
    "d/M/yy",
    ////////////
    "MM.dd.yyyy HH:mm:ss",
    "MM/dd/yyyy HH:mm:ss",
    "MM.dd.yyyy HH:mm",
    "MM/dd/yyyy HH:mm",
    "MM.dd.yyyy H:mm",
    "MM/dd/yyyy H:mm",
    "MM.dd.yyyy",
    "MM/dd/yyyy",
    "MM.d.yyyy HH:mm:ss",
    "MM/d/yyyy HH:mm:ss",
    "MM.d.yyyy HH:mm",
    "MM/d/yyyy HH:mm",
    "MM.d.yyyy H:mm",
    "MM/d/yyyy H:mm",
    "MM.d.yyyy",
    "MM/d/yyyy",
    ///////////
    "MM.dd.yy HH:mm:ss",
    "MM/dd/yy HH:mm:ss",
    "MM.dd.yy HH:mm",
    "MM/dd/yy HH:mm",
    "MM.dd.yy H:mm",
    "MM/dd/yy H:mm",
    "MM.dd.yy",
    "MM/dd/yy",
    "MM.d.yy HH:mm:ss",
    "MM/d/yy HH:mm:ss",
    "MM.d.yy HH:mm",
    "MM/d/yy HH:mm",
    "MM/d/yy H:mm",
    "MM.d.yy H:mm",
    "MM.d.yy",
    "MM/d/yy",
    ////////////
    "M.dd.yyyy HH:mm:ss",
    "M/dd/yyyy HH:mm:ss",
    "M.dd.yyyy HH:mm",
    "M/dd/yyyy HH:mm",
    "M.dd.yyyy H:mm",
    "M/dd/yyyy H:mm",
    "M.dd.yyyy",
    "M/dd/yyyy",
    "M.d.yyyy HH:mm:ss",
    "M/d/yyyy HH:mm:ss",
    "M.d.yyyy HH:mm",
    "M/d/yyyy HH:mm",
    "M.d.yyyy H:mm",
    "M/d/yyyy H:mm",
    "M.d.yyyy",
    "M/d/yyyy",
    ///////////
    "M.dd.yy HH:mm:ss",
    "M/dd/yy HH:mm:ss",
    "M.dd.yy HH:mm",
    "M/dd/yy HH:mm",
    "M.dd.yy H:mm",
    "M/dd/yy H:mm",
    "M.dd.yy",
    "M/dd/yy",
    "M.d.yy HH:mm:ss",
    "M/d/yy HH:mm:ss",
    "M.d.yy HH:mm",
    "M/d/yy HH:mm",
    "M/d/yy H:mm",
    "M.d.yy H:mm",
    "M.d.yy",
    "M/d/yy",
    ////////////

    ///////////
    "yyyy.dd.MM HH:mm:ss",
    "yyyy/dd/MM HH:mm:ss",
    "yyyy.dd.MM HH:mm",
    "yyyy/dd/MM HH:mm",
    "yyyy.dd.MM H:mm",
    "yyyy/dd/MM H:mm",
    "yyyy.dd.MM",
    "yyyy/dd/MM",
    "yyyy.d.MM HH:mm:ss",
    "yyyy/d/MM HH:mm:ss",
    "yyyy.d.MM HH:mm",
    "yyyy/d/MM HH:mm",
    "yyyy.d.MM H:mm",
    "yyyy/d/MM H:mm",
    "yyyy.d.MM",
    "yyyy/d/MM",
    ///////////
    "yy.dd.MM HH:mm:ss",
    "yy/dd/MM HH:mm:ss",
    "yy.dd.MM HH:mm",
    "yy/dd/MM HH:mm",
    "yy.dd.MM H:mm",
    "yy/dd/MM H:mm",
    "yy.dd.MM",
    "yy/dd/MM",
    "yy.d.MM HH:mm:ss",
    "yy/d/MM HH:mm:ss",
    "yy.d.MM HH:mm",
    "yy/d/MM HH:mm",
    "yy/d/MM H:mm",
    "yy.d.MM H:mm",
    "yy.d.MM",
    "yy/d/MM",
    ////////////
    "yyyy.dd.M HH:mm:ss",
    "yyyy/dd/M HH:mm:ss",
    "yyyy.dd.M HH:mm",
    "yyyy/dd/M HH:mm",
    "yyyy.dd.M H:mm",
    "yyyy/dd/M H:mm",
    "yyyy.dd.M",
    "yyyy/dd/M",
    "yyyy.d.M HH:mm:ss",
    "yyyy/d/M HH:mm:ss",
    "yyyy.d.M HH:mm",
    "yyyy/d/M HH:mm",
    "yyyy.d.M H:mm",
    "yyyy/d/M H:mm",
    "yyyy.d.M",
    "yyyy/d/M",
    ///////////
    "yy.dd.M HH:mm:ss",
    "yy/dd/M HH:mm:ss",
    "yy.dd.M HH:mm",
    "yy/dd/M HH:mm",
    "yy.dd.M H:mm",
    "yy/dd/M H:mm",
    "yy.dd.M",
    "yy/dd/M",
    "yy.d.M HH:mm:ss",
    "yy/d/M HH:mm:ss",
    "yy.d.M HH:mm",
    "yy/d/M HH:mm",
    "yy/d/M H:mm",
    "yy.d.M H:mm",
    "yy.d.M",
    "yy/d/M",
    ////////////
    "yyyy.MM.dd HH:mm:ss",
    "yyyy/MM/dd HH:mm:ss",
    "yyyy.MM.dd HH:mm",
    "yyyy/MM/dd HH:mm",
    "yyyy.MM.dd H:mm",
    "yyyy/MM/dd H:mm",
    "yyyy.MM.dd",
    "yyyy/MM/dd",
    "yyyy.MM.d HH:mm:ss",
    "yyyy/MM/d HH:mm:ss",
    "yyyy.MM.d HH:mm",
    "yyyy/MM/d HH:mm",
    "yyyy.MM.d H:mm",
    "yyyy/MM/d H:mm",
    "yyyy.MM.d",
    "yyyy/MM/d",
    ///////////
    "yy.MM.dd HH:mm:ss",
    "yy/MM/dd HH:mm:ss",
    "yy.MM.dd HH:mm",
    "yy/MM/dd HH:mm",
    "yy.MM.dd H:mm",
    "yy/MM/dd H:mm",
    "yy.MM.dd",
    "yy/MM/dd",
    "yy.MM.d HH:mm:ss",
    "yy/MM/d HH:mm:ss",
    "yy.MM.d HH:mm",
    "yy/MM/d HH:mm",
    "yy/MM/d H:mm",
    "yy.MM.d H:mm",
    "yy.MM.d",
    "yy/MM/d",
    ////////////
    "yyyy.M.dd HH:mm:ss",
    "yyyy/M/dd HH:mm:ss",
    "yyyy.M.dd HH:mm",
    "yyyy/M/dd HH:mm",
    "yyyy.M.dd H:mm",
    "yyyy/M/dd H:mm",
    "yyyy.M.dd",
    "yyyy/M/dd",
    "yyyy.M.d HH:mm:ss",
    "yyyy/M/d HH:mm:ss",
    "yyyy.M.d HH:mm",
    "yyyy/M/d HH:mm",
    "yyyy.M.d H:mm",
    "yyyy/M/d H:mm",
    "yyyy.M.d",
    "yyyy/M/d",
    ///////////
    "yy.M.dd HH:mm:ss",
    "yy/M/dd HH:mm:ss",
    "yy.M.dd HH:mm",
    "yy/M/dd HH:mm",
    "yy.M.dd H:mm",
    "yy/M/dd H:mm",
    "yy.M.dd",
    "yy/M/dd",
    "yy.M.d HH:mm:ss",
    "yy/M/d HH:mm:ss",
    "yy.M.d HH:mm",
    "yy/M/d HH:mm",
    "yy/M/d H:mm",
    "yy.M.d H:mm",
    "yy.M.d",
    "yy/M/d",
    ////////////
  ];

  useEffect(() => {
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_ALL_COMPANY,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        setCompanies(data.companies);
        if (data.companies.length !== 0)
          setSelectedCompany(JSON.stringify(data.companies[0]));
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_TEMPLATE_GET_ALL,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        setProducts(data.templates);
        if (data.templates.length !== 0)
          setSelectedProduct(JSON.stringify(data.templates[0]));
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function getSeparator(value) {
    const separators = ["/", "-", "."];
    return separators.find((separator) => value.includes(separator)) || "";
  }

  function isDate(value) {
    for (const format of dateFormats) {
      const d = parse(value, format, new Date());
      if (!isNaN(d)) {
        return true;
      }
    }
    return false;
  }

  function formatDateToDDMMYYYY(date) {
    let formattedDate;
    const separator = getSeparator(date);
    let valueParts = date.split(separator); //when already formatted
    if (date.length === 10 && valueParts.length === 3) {
      formattedDate = `${valueParts[0]}/${valueParts[1]}/${valueParts[2]}`;
      if (parseInt(valueParts[0]) > 12) {
        formattedDate = `${valueParts[0]}/${valueParts[1]}/${valueParts[2]}`;
      } else if (parseInt(valueParts[1]) > 12) {
        formattedDate = `${valueParts[1]}/${valueParts[0]}/${valueParts[2]}`;
      } else {
        formattedDate = `${valueParts[0]}/${valueParts[1]}/${valueParts[2]}`;
      }
      return formattedDate;
    }
    let day = 1;
    let month = 1;
    let year = 2023;

    for (const formatType of dateFormats) {
      const d = parse(date, formatType, new Date());
      if (!isNaN(d)) {
        day = d.getDate();
        month = d.getMonth() + 1;
        year = d.getFullYear();
        if (year.toString().length === 2) {
          year = `20${year}`;
        }
        if (month.toString().length === 1) {
          month = `0${month}`;
        }
        if (day.toString().length === 1) {
          day = `0${day}`;
        }
        break;
      }
    }

    formattedDate = `${day}/${month}/${year}`;
    if (parseInt(month) > 12) {
      formattedDate = `${month}/${day}/${year}`;
    } else if (parseInt(day) > 12) {
      formattedDate = `${day}/${month}/${year}`;
    }
    return formattedDate;
  }

  function processDataFromExcel(excelData) {
    const processedData = excelData.map((row) => {
      const processedRow = {};
      for (const key in row) {
        const cellValue = row[key];
        if (key.toLocaleLowerCase().includes("tar") && isDate(cellValue)) {
          const formattedDate = formatDateToDDMMYYYY(cellValue);
          processedRow[key] = formattedDate;
        } else if (key.toLocaleLowerCase().includes("tar")) {
          processedRow[key] = "01/01/2023";
        } else {
          processedRow[key] = cellValue;
        }
      }
      return processedRow;
    });
    // İşlenmiş verileri kullanın
    return processedData;
  }

  function readUploadFile(e) {
    setLoading(true);
    setTimeout(function () {
      if (e.target.files) {
        const uploadedFile = e.target.files[0];
        setFile(uploadedFile);
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          // const workbook = xlsx.read(data, { type: "array" });
          let workbook;
          try {
            workbook = xlsx.read(data, { type: "array", password: "" });
          } catch (error) {
            if (error.message === "File is password-protected") {
              alert("Please remove password of your file and try again.");
              setLoading(false);
              return;
            } else {
              console.log("Error reading file:", error);
            }
          }

          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const options = {
            raw: false, // Hücre değerlerini otomatik olarak dönüştürür (özel karakterler dahil)
            defval: "", // Boş hücreler için varsayılan değer olarak ""
            cellStyles: true,
          };
          const json = xlsx.utils.sheet_to_json(worksheet, options);
          const cellData = json[1]["Tarih (Muh)"];
          const cleanedData = json.map((row) => {
            const cleanedRow = {};
            for (let key in row) {
              const cleanedKey =
                key && key.replace(/↵/g, "").replace(/\s+/g, " ").trim();
              const cellValue =
                row[key] &&
                row[key].replace(/↵/g, "").replace(/\s+/g, " ").trim();
              cleanedRow[cleanedKey] = cellValue;
            }
            return cleanedRow;
          });
          setDataFromExcel(processDataFromExcel(cleanedData));
        };
        reader.readAsArrayBuffer(uploadedFile);
      }
    }, 1000); // 2 saniye gecikme
  }
  useEffect(() => {
    if (dataFromExcel.length !== 0)
      navigate("/table", {
        state: {
          dataFromExcel: dataFromExcel,
          selectedProduct: selectedProduct,
          selectedCompany: selectedCompany,
        },
      });
  }, [dataFromExcel]);

  function handleNextButton() {
    const container = document.querySelector(".info-data");
    container.style.marginBottom = "80vh";
    const nextBttn = document.querySelector(".next-button");
    nextBttn.style.display = "none";
    setTimeout(function () {
      setNextButton(true);
    }, 750); // 2 saniye gecikme
  }

  return (
    <>
      <div className="main-parent">
        <div className="info-data">
          <select
            name="selectCompanyImport"
            id="selectCompanyImport"
            onChange={(event) => setSelectedCompany(event.target.value)}
          >
            {companies.map((company, index) => (
              <option key={index} value={JSON.stringify(company)}>
                {company.name}
              </option>
            ))}
          </select>
          <select
            name="selectProductImport"
            id="selectProductImport"
            onChange={(event) => setSelectedProduct(event.target.value)}
            value={selectedProduct}
          >
            {products.map((product, index) => (
              <option key={index} value={JSON.stringify(product)}>
                {product.name}
              </option>
            ))}
          </select>

          {!nextButton ? (
            <button className="next-button" onClick={() => handleNextButton()}>
              {langFile.pages.Upload.nextButton}
            </button>
          ) : null}
        </div>

        {nextButton ? (
          <>
            {loading && (
              <div className="load">
                <img src={loadingGif} alt="loading" />
              </div>
            )}
            <input
              type="file"
              id="file"
              accept=".xlsx , .csv"
              required="required"
              onChange={
                selectedCompany !== "" && selectedProduct !== ""
                  ? readUploadFile
                  : alert("Lütfen Şirket ve Product Alanlarını Seçiniz")
              }
            />
            <label className="choose-file" htmlFor="file">
              <label>{langFile.pages.Upload.importButtonText}</label>
              <img src={add_icon} alt="addExcel"></img>
            </label>
          </>
        ) : null}
      </div>
    </>
  );
}
