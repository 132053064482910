import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/AllData.css";
import Alert from "../components/Alert/Alert";
import loadingGif from "../assets/loading3.gif";
import delete_ico from "../assets/delete.png";
import deleteAll_ico from "../assets/delete-2.png";
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import LogOutEndPoint from "../components/LogOutEndPoint";

export default function AllData() {
  const [dataFromExcel, setDataFromExcel] = useState([]);
  const [pureDataFromExcel, setPureDataFromExcel] = useState([]);
  const [titles, setTitles] = useState([]);
  const [sortedTitles, setSortedTitles] = useState([]);
  const [alertList, setAlertList] = useState([]);
  const [tempArr, setTempArr] = useState([...alertList]);
  const [indexesForSearch, setIndexesForSearch] = useState([]);
  const navigate = useNavigate();
  const [counterForPagination, setCounterForPagination] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [products, setProducts] = useState([]);
  const [searchValue, setSearchValue] = useState({
    event: { target: { value: "" } },
  });
  const [loadingForLoadMore, setLoadingForLoadMore] = useState(false);
  function handleCellChange(event, rowIndex, colIndex) {
    const updatedData = [...dataFromExcel];
    updatedData[rowIndex][titles[colIndex].name] = event.target.innerText;
    setDataFromExcel(updatedData);
  }
  const[companies, setCompanies] = useState([])
  const[selectedCompany, setSelectedCompany] = useState("")
  const [loadingPage, setLoadingPage] = useState(false);
  const [langFile, setLangFile] = useState(en);
  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);
  const [submitButtonText, setSubmitButtonText] = useState("Update");
  useEffect(() => {
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_ALL_COMPANY,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        setCompanies(data.companies);
        if (data.companies.length !== 0)
          setSelectedCompany(data.companies[0].id);
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if(selectedCompany !== "") {
      fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_BY_COMPNAYID + selectedCompany,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        setProducts(data.templates);
        if (data.templates.length !== 0)
        setSelectedProduct(data.templates[0].templateId);
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
    }
 
  }, [selectedCompany]);

  // useEffect(() => {
  //   fetch(
  //     process.env.REACT_APP_BASE_URL + process.env.REACT_APP_TEMPLATE_GET_ALL,
  //     {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         Authorization:
  //           "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
  //       },
  //     }
  //   )
  //     .then((res) => {
  //       setLoadingPage(false);
  //       setLoadingForLoadMore(false);
  //       if (res.ok) {
  //         return res.json();
  //       }
  //       else if (res.status === 401) {
  //         LogOutEndPoint(navigate);
  //       }
  //       throw res;
  //     })
  //     .then((data) => {
  //       setProducts(data.templates);
  //       if (data.templates.length !== 0)
  //         setSelectedProduct(data.templates[0].id);
  //       return data;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  useEffect(() => {
    if (selectedProduct !== "" && selectedCompany !== "") {
      const user = JSON.parse(localStorage.getItem("user"));
      setLoadingPage(true);
      setLoadingForLoadMore(true);
      fetch(
        process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PRODUCT_GET_ALL,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            Authorization:
              "Bearer " + user.accessToken,
          },
          body: JSON.stringify({
            // userId: user.id,
            companyId: selectedCompany,
            templateId: selectedProduct,
            pageNumber: 1,
            pageSize: 20 * counterForPagination,
          }),
        }
      )
        .then((res) => {
          setLoadingPage(false);
          setLoadingForLoadMore(false);
          if (res.ok) {
            return res.json();
          }
          else if (res.status === 401) {
            LogOutEndPoint(navigate);
          }
          throw res;
        })
        .then((data) => {
          if (data.products !== null) {
            SaveData(data.products);
          } else {
            setDataFromExcel([]);
            setPureDataFromExcel([]);
            setTitles([]);
          }
          let temp = [...alertList];
          temp.push({ status: "success", text: "Getting Data Successful" });
          setAlertList([...temp]);
          return data;
        })
        .catch((error) => {
          let temp = [...alertList];
          temp.push({ status: "error", text: "Getting Data Failed" });
          setAlertList([...temp]);
          console.log(error);
        });
    }
  }, [selectedProduct, counterForPagination, selectedCompany]);

  function SaveData(data) {
    let arr = [];
    for (let j = 0; j < data.length; j++) {
      let obj = { value: {} };
      for (let i = 0; i < data[j].productProperties.length; i++) {
        obj["value"][data[j].productProperties[i].name] =
          data[j].productProperties[i].value.value;
      }
      obj["value"]["isActive"] = data[j].isActive === true ? "true" : "false";
      obj["id"] = data[j].id;
      obj["isDeleted"] = false;
      arr.push(obj);
    }
    setDataFromExcel(arr);
    setPureDataFromExcel(arr);
  }

  useEffect(() => {
    setTempArr(alertList);
  }, [alertList]);

  function checkId(inputString) {
    let temp = "";

    const numericPart = inputString.replace(/\D/g, ""); // Tckn:12345678912 -> 12345678912
    const stringPart = inputString.replace(/\d/g, "");
    if (
      numericPart.length !== 11 ||
      numericPart % 2 !== 0 ||
      numericPart[0] === 0
    ) {
      // mod2 must be zero and tckn is 11 length
      return inputString;
    }

    if (!/^\d+$/.test(numericPart)) {
      //123456erd123 -> false
      // tckn must be contain only numbers
      return inputString;
    }
    let a = 0;
    let b = 0;

    for (let i = 0; i < 9; i++) {
      if (i % 2 === 0) {
        a += parseInt(numericPart[i]);
      } else {
        b += parseInt(numericPart[i]);
      }

      if (i === 8) {
        let result = (a * 7) + (b * 9);
        if (result.toString().slice(-1) !== numericPart.toString().slice(-2, -1)) {
         
          return inputString;
        }
      }
    }

    for (let i = 0; i < numericPart.length; i++) {
      if (i >= 3 && i <= 8) {
        temp += "*";
      } else {
        temp += numericPart[i];
      }
    }

    return stringPart + temp;
  }
  // Bu useEffect içinde "titles" dizisini sıralıyoruz.
  useEffect(() => {
    if (titles.length !== 0) {
      titles.sort((a, b) => {
        const aName = a.name;
        const bName = b.name;

        const aLower = aName.toLowerCase();
        const bLower = bName.toLowerCase();

        if (
          aLower.includes("poliçe no") ||
          aLower.includes("pol no") ||
          bLower.includes("poliçe no") ||
          bLower.includes("pol no")
        ) {
          // "poliçe no" veya "pol no" içeren başlıkları ilk sıraya yerleştiriyoruz.
          return (
            ((aLower.includes("poliçe no") || aLower.includes("pol no"))&&!aLower.includes("eski")
              ? 0
              : 1) -
            ((bLower.includes("poliçe no") || bLower.includes("pol no"))&&!bLower.includes("eski")
             ? 0 : 1)
          );
        } else if (
          aLower.includes("tckn") ||
          aLower.includes("kimlik") ||
          bLower.includes("tckn") ||
          bLower.includes("kimlik")
        ) {
          // "tckn" veya "kimlik" içeren başlıkları ikinci sıraya yerleştiriyoruz.
          return (
            (aLower.includes("tckn") || aLower.includes("kimlik") ? 0 : 1) -
            (bLower.includes("tckn") || bLower.includes("kimlik") ? 0 : 1)
          );
        } else if (
          aLower.includes("sigortalı") ||
          aLower.includes("sig. ettiren") ||
          bLower.includes("sigortalı") ||
          bLower.includes("sig. ettiren")
        ) {
          return (
            (aLower.includes("sigortalı") || aLower.includes("sig. ettiren")
              ? 0
              : 1) -
            (bLower.includes("sigortalı") || bLower.includes("sig. ettiren")
              ? 0
              : 1)
          );
        } else if (aLower.includes("tar") || bLower.includes("tar")) {
          // "tar" içeren başlıkları üçüncü sıraya yerleştiriyoruz.
          return (
            (aLower.includes("tar") ? 0 : 1) - (bLower.includes("tar") ? 0 : 1)
          );
        } else {
          // Diğer başlıkları en sona yerleştiriyoruz.
          return aName.localeCompare(bName);
        }
      });
      setSortedTitles(titles);
    }
  }, [titles]);

  useEffect(() => {
    //to detect titles
    if (dataFromExcel !== undefined && dataFromExcel.length > 0) {
      let temp = Object.entries(dataFromExcel[0].value);
      let arrForTittle = [];
      temp.map((item) => {
        arrForTittle.push({ name: item[0], filter: true }); //eğer filter özelliğini false yaparsanız o kolon filterelenir
      });
      setTitles(arrForTittle);
    }
  }, [dataFromExcel]);

  function handleSubmit() {
    if (submitButtonText === "Update") {
      setTimeout(function () {
        setPureDataFromExcel(JSON.parse(JSON.stringify(dataFromExcel)));
        let temp = [...alertList];
        temp.push({ status: "success", text: "Updating Successful" });
        setAlertList([...temp]);
      }, 1000);
    }
  }

  function handleSearch(event) {
    if (
      event === undefined ||
      (event.target.value === "" &&
        dataFromExcel !== undefined &&
        dataFromExcel.length > 0)
    ) {
      let searchFilter = [];
      for (let i = 0; i < dataFromExcel.length; i++) {
        searchFilter.push(true);
      }
      setIndexesForSearch(searchFilter);
    } else if (
      event.target.value !== "" &&
      dataFromExcel !== undefined &&
      dataFromExcel.length > 0
    ) {
      let searchFilter = [];
      for (let i = 0; i < dataFromExcel.length; i++) {
        let flag = false;
        let arr = Object.entries(dataFromExcel[i].value);
        for (let j = 0; j < arr.length; j++) {
          if (
            arr[j][1] &&
            arr[j][1]
              .toString()
              .toLocaleLowerCase()
              .includes(event.target.value.toString().toLocaleLowerCase())
          ) {
            flag = true;
            break;
          }
        }
        searchFilter.push(flag);
      }
      setIndexesForSearch(searchFilter);
    } else if (
      event.target.value === "" &&
      dataFromExcel !== undefined &&
      dataFromExcel.length > 0
    ) {
      let searchFilter = [];
      for (let i = 0; i < dataFromExcel.length; i++) {
        searchFilter.push(true);
      }
      setIndexesForSearch(searchFilter);
    }
  }
  useEffect(() => {
    handleSearch(searchValue.event);
  }, [dataFromExcel]);

  function DeleteProduct(id) {
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PRODUCT_DELETE,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: JSON.parse(localStorage.getItem("user")).id,
          productId: id,
        }),
      }
    )
      .then((res) => {
        setLoadingPage(false);
        setLoadingForLoadMore(false);
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        let filteredArr = [...dataFromExcel];
        for (let i = 0; i < filteredArr.length; i++) {
          if (filteredArr[i].id === id) {
            filteredArr[i].isDeleted = true;
          }
        }
        setDataFromExcel(filteredArr);
        setPureDataFromExcel(filteredArr);
        let temp = [...alertList];
        temp.push({
          status: "success",
          text: data.result.success ? "Success" : "Fail",
        });
        setAlertList([...temp]);
        return data;
      })
      .catch((error) => {
        let temp = [...alertList];
        temp.push({ status: "error", text: "Data Delete Failed" });
        setAlertList([...temp]);
        console.log(error);
      });
  }

  // function CheckData(){
  //   setLoadingPage(true);
  //   fetch(
  //     process.env.REACT_APP_BASE_URL + process.env.REACT_APP_CHECK_DATA,
  //     {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         Authorization:
  //           "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
  //       },
  //     }
  //   )
  //     .then((res) => {
  //       setLoadingPage(false);
  //       if (res.ok) {
  //         return res.json();
  //       }
  //       throw res;
  //     })
  //     .then((data) => {
  //       let temp = [...alertList];
  //       temp.push({ status: "success", text: "Data Update Succesful"});
  //       setAlertList([...temp]);
  //       setLoadingPage(false);
  //       return data;
  //     })
  //     .catch((error) => {
  //       let temp = [...alertList];
  //       temp.push({ status: "error", text: "Data Update Failed" });
  //       setAlertList([...temp]);
  //       setLoadingPage(false);
  //       console.log(error);
  //     });
  // }
  function DeleteAllProduct() {
    setLoadingPage(true);
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PRODUCT_DELETE_ALL,
      {
        method: "DELETE",
        headers: {
          accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      }
    )
      .then((res) => {
        setLoadingPage(false);
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        setDataFromExcel([]);
        setPureDataFromExcel([]);
        setTitles([]);
        let temp = [...alertList];
        temp.push({ status: "success", text: "All Data Delete Succesfull" });
        setAlertList([...temp]);
        setLoadingPage(false);
        setLoadingForLoadMore(false);
        return data;
      })
      .catch((error) => {
        let temp = [...alertList];
        temp.push({ status: "error", text: "All Data Delete Failed" });
        setAlertList([...temp]);
        setLoadingForLoadMore(false);
        setLoadingPage(false);
        console.log(error);
      });
  }

  return (
    <>
      {loadingPage && (
        <div className="load">
          <img src={loadingGif} alt="loading" />
          <label>{langFile.pages.AllData.loadingLabel}</label>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          position: "absolute",
          right: "1vw",
          height: "min-content",
          bottom: "0vh",
        }}
      >
        {tempArr.map((item, index) => {
          return (
            <Alert
              index={index}
              key={index}
              time={5000}
              status={item.status}
              text={item.text}
            ></Alert>
          );
        })}
      </div>
      <div className="allData-container">
        <div className="navigation-container">
        <select
            name="selectCompany"
            id="selectCompany"
            onChange={(event) => setSelectedCompany(event.target.value)}
          >
            {companies.map((company, index) => (
              <option key={index} value={company.id}>
                {company.name}
              </option>
            ))}
          </select>
          <select
            name="selectProduct"
            id="selectProduct"
            onChange={(event) => setSelectedProduct(event.target.value)}
          >
            {products.map((product, index) => (
              <option key={index} value={product.templateId}>
                {product.templateName}
              </option>
            ))}
          </select>
          <input
            id="search"
            type="search"
            placeholder={langFile.pages.AllData.placeholderSearch}
            required
            onChange={(e) => {
              setSearchValue({ event: { target: { value: e.target.value } } });
              handleSearch(e);
            }}
          />
          {/* <button
            className="check-all-data-button"
            onClick={() => {
              CheckData();
            }}
          >

            Tüm Veriyi Tarihlere Göre Kontrol Et
          </button> */}
          {/* <button
            className="delete-all-data-button"
            onClick={() => {
              DeleteAllProduct();
            }}
          >
            <img
              alt="delete-all"
              src={deleteAll_ico}
              className="delete-all-ico"
            ></img>
            Tüm Veriyi Sil
          </button> */}
        </div>
        <table>
          <thead>
            <tr>
              {sortedTitles.length !== 0 && <th>No</th>}
              {sortedTitles &&
                sortedTitles.map((item, index) =>
                  item.filter === true ? <th key={index}>{item.name}</th> : null
                )}
            </tr>
          </thead>

          <tbody>
            {dataFromExcel.map((item, rowIndex) =>
              indexesForSearch[rowIndex] === true &&
              item["isDeleted"] === false ? (
                <tr key={rowIndex}>
                  <td
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "flex-start",
                    }}
                  >
                    <button
                      onClick={() => {
                        DeleteProduct(item["id"]);
                      }}
                      className="delete-button"
                    >
                      <img
                        alt="delete"
                        src={delete_ico}
                        style={{ width: "1vw", height: "1vw" }}
                      ></img>
                    </button>
                    <div
                      className="custom-tooltip"
                      title={
                        item["value"]["isActive"] === "true"
                          ? "ACTIVE"
                          : "DEACTIVE"
                      }
                      style={{
                        borderRadius: "50%",
                        backgroundColor:
                          item["value"]["isActive"] === "true"
                            ? "green"
                            : "red",
                        width: ".5vw",
                        height: ".5vw",
                        marginLeft: "5%",
                        marginRight: "5%",
                        fontWeight: "bolder",
                        color:
                          item["value"]["isActive"] === "true"
                            ? "green"
                            : "red",
                      }}
                    ></div>
                    {rowIndex + 1}
                  </td>
                  {/* Satır numarasını gösteren hücre */}
                  {sortedTitles.map((title, colIndex) =>
                    title.filter ? (
                      <td
                        // contentEditable={true}
                        // suppressContentEditableWarning={true}
                        style={{
                          backgroundColor:
                            String(
                              pureDataFromExcel[rowIndex]["value"][title.name]
                            ) !== String(item["value"][title.name])
                              ? "#d9534f"
                              : "",
                        }}
                        key={colIndex}
                      >
                        {(title.name.toLowerCase().includes("kimlik") ||
                        title.name.toLowerCase().includes("tckn"))&&item["value"][title.name]
                          ? checkId(item["value"][title.name])
                          : item["value"][title.name]}
                      </td>
                    ) : null
                  )}
                </tr>
              ) : null
            )}
            <tr
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "1vh 0",
                width: "100%!important",
              }}
            >
              <td style={{ borderRadius: "5px", minWidth: "100%" }}>
                <button
                  className="load-more-button"
                  onClick={() => {
                    setCounterForPagination(counterForPagination + 1);
                  }}
                >
                  {loadingForLoadMore === true ? (
                    <div className="data-loader"></div>
                  ) : (
                    langFile.pages.AllData.loadMoreButton
                  )}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
