import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/AllData.css";
import Alert from "../components/Alert/Alert";
import loadingGif from "../assets/loading3.gif";
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import "../styles/AllOffer.css"
import deactive_ico from "../assets/deactive.png"
import active_ico from "../assets/active.png"
import delete_ico from "../assets/delete.png"
import edit_ico from "../assets/edit.png"
import close_ico from "../assets/close.png"
import closebtn from "../assets/close-button.png"
import confirm_ico from "../assets/confirm.png"
import userEvent from "@testing-library/user-event";
import LogOutEndPoint from "../components/LogOutEndPoint";

export default function AllOffer() {
  const navigate = useNavigate();
  const [langFile, setLangFile] = useState(en);
  const [alertList, setAlertList] = useState([]);
  const [tempArr, setTempArr] = useState([...alertList]);
  const [offerList, setOfferList] = useState([])
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingForLoadMore, setLoadingForLoadMore] = useState(false);
  const [offerTemplate, setOfferTemplate] = useState([])
  const [selectedOfferTemplate, setSelectedOfferTemplate] = useState("")
  const [selectedOfferTemplateCounts, setSelectedOfferTemplateCounts] = useState("")
  const [selectSituation, setSelectSituation] = useState("all");
  const [modal, setModal] = useState(false);
  const [selectedOfferForEdit, setSelectedOfferForEdit] = useState(null);
  const [updatedPropertyValues, setUpdatedPropertyValues] = useState({});
  const [shouldHandleCheckboxChange, setShouldHandleCheckboxChange] = useState(false);
  const [pinCodeColumns, setPinCodeColumns] = useState([]);
  const [getAllOfferByDescTrigger, setGetAllOfferByDescTrigger] = useState(false)
  const [offerTitles, setOfferTitles] = useState([])
  const [counterForPagination, setCounterForPagination] = useState(1)
  const [offerNameToUpdate, setOfferNameToUpdate] = useState("");
  const [offerNameEdit, setOfferNameEdit] = useState(false);

  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);
  useEffect(() => {
    setTempArr(alertList);
  }, [alertList]);

  const toggleModal = (offer) => {
    setSelectedOfferForEdit(offer);
    setModal(!modal);
  }

  useEffect(() => {
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_ALL_OFFER_BY_GROUPS,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        setOfferTemplate(data.responseData);
        if (selectedOfferTemplate === "" && data.responseData.length > 0) {
          setSelectedOfferTemplate(data.responseData[0])
          setSelectedOfferTemplateCounts(data.responseData[0].counts[0])
        }else if (selectedOfferTemplate !== "") {
          const matchingTemplate = data.responseData.find((item) => item.desc === selectedOfferTemplate.desc);
          if (matchingTemplate) {
            setSelectedOfferTemplate(matchingTemplate);
            setSelectedOfferTemplateCounts(matchingTemplate.counts[0]);
          }
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getAllOfferByDescTrigger])
  // console.log("0",offerTemplate)
  // console.log("1",selectedOfferTemplate)
  // console.log("2",selectedOfferTemplateCounts)
  
  useEffect(() => {
    if (selectedOfferTemplate !== "") {
      setLoadingPage(true);
      setLoadingForLoadMore(true);
      fetch(
        process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_ALL_OFFER_BY_DESC + selectedOfferTemplate.desc+"&PageNumber=1&PageSize="+50*counterForPagination,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
          },
        }
      )
        .then((res) => {
          setLoadingPage(false);
          setLoadingForLoadMore(false);
          if (res.ok) {
            return res.json();
          }
          else if (res.status === 401) {
            LogOutEndPoint(navigate);
          }
          throw res;
        })
        .then((data) => {
          // console.log("data", data)
          setOfferList(data.offerGroup);
          setOfferTitles(data.titles)
          setPinCodeColumns(data.pinCodeColumns);
          return data;
        })
        .catch((error) => {
          setLoadingPage(false);
          setLoadingForLoadMore(false);
          console.log(error);
        });
    }
  }, [selectedOfferTemplate, getAllOfferByDescTrigger, counterForPagination])



  // function HandleActivityAllDesc(status) { 

  //   const user = JSON.parse(localStorage.getItem("user"));
  
  //   fetch(
  //     process.env.REACT_APP_BASE_URL + process.env.REACT_APP_SET_ACTIVITY_BY_DESC,
  //     {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Accept": "application/json",
  //         "Authorization": "Bearer " + user.accessToken,
  //       },
  //       body: JSON.stringify({
  //         userId: user.id,
  //         desc: selectedOfferTemplate.desc,
  //         status: status,
  //         isAvailable: null
  //       }),
  //     }
  //   )
  //     .then((res) => {
  //       if (res.ok) {
  //         return res.json();
  //       }
  //       throw res;
  //     })
  //     .then((data) => {
  //       let temp = [...alertList];
  //       temp.push({ status: "success", text: "Offer Change Status Successful" });
  //       setAlertList([...temp]);
  //       setGetAllOfferByDescTrigger(!getAllOfferByDescTrigger)
  //       return data;
  //     })
  //     .catch((error) => {
  //       let temp = [...alertList];
  //       temp.push({ status: "error", text: "Offer Change Status Fail" });
  //       setAlertList([...temp]);
  //       console.log(error);
  //       setGetAllOfferByDescTrigger(!getAllOfferByDescTrigger)
  //     });
  // }

  // function HandleCheckboxChange(event, offerIndex) {

  //   const status = event.target.checked ? "Active" : "Deactive"

  //   ChangeActivityById(offerIndex, status)

  // };
  // function ChangeActivityById(offerIndex, status) {

  //   const user = JSON.parse(localStorage.getItem("user"));

  //   fetch(
  //     process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPDATE_STATUS_BY_OFFER_ID,
  //     {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Accept": "application/json",
  //         "Authorization": "Bearer " + user.accessToken,
  //       },
  //       body: JSON.stringify({
  //         offerId: offerList[offerIndex].offerId,
  //         userId: user.id,
  //         status: status
  //       }),
  //     }
  //   )
  //     .then((res) => {
  //       if (res.ok) {
  //         return res.json();
  //       }
  //       throw res;
  //     })
  //     .then((data) => {
  //       if(data.result.success){
  //         const updatedOfferList = [...offerList];
  //         updatedOfferList[offerIndex].status = status;
  //         setOfferList(updatedOfferList);
  //       }
  //       let temp = [...alertList];
  //       temp.push({ status: "success", text: data.result.message });
  //       setAlertList([...temp]);
       
  //       return data;
  //     })
  //     .catch((error) => {
  //       let temp = [...alertList];
  //       temp.push({ status: "error", text: "Offer Change Status Fail" });
  //       setAlertList([...temp]);
  //       console.log(error);
  //     });
  // }

  // Modal

  function HandleCheckboxChangeModal(event) {

    const status = event.target.checked ? "Active" : "Deactive"

    setSelectedOfferForEdit(prevOffer => ({
      ...prevOffer,
      status: status
    }));

    if (shouldHandleCheckboxChange) {
      ChangeActivityByIdModal(selectedOfferForEdit, status);
    }

  };

  function handleDelete(offer) {
    ChangeActivityByIdModal(offer, "Deleted")
    setModal(false);
  }


  function ChangeActivityByIdModal(offer, status) {

    const user = JSON.parse(localStorage.getItem("user"));

    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPDATE_STATUS_BY_OFFER_ID,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + user.accessToken,
        },
        body: JSON.stringify({
          offerId: offer.offerId,
          userId: user.id,
          status: status
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        if (data.result.success) {
          const updatedOfferList = offerList.map((item) => {
            if (item.offerId === offer.offerId) {
              return {
                ...item,
                status: status,
              };
            }
            return item;
          });
          setOfferList(updatedOfferList);
        }
        let temp = [...alertList];
        temp.push({ status: "success", text: data.result.message });
        setAlertList([...temp]);
        setGetAllOfferByDescTrigger(!getAllOfferByDescTrigger);
        return data;
      })
      .catch((error) => {
        let temp = [...alertList];
        temp.push({ status: "error", text: "Offer Change Status Fail" });
        setAlertList([...temp]);
        setModal(true);
        console.log(error);
      });
  }

    const handlePropertyChange = (propertyName, value) => {
      setUpdatedPropertyValues((prevValues) => ({
        ...prevValues,
        [propertyName]: value,
      }));
    };

    function UpdateOffer(offer) {
      const updatedProperties = offer.property.map((property) => ({
        ...property,
        value: updatedPropertyValues[property.propertyName] || property.value,
      }));

      let pinCodeValue;
      updatedProperties.forEach((property) => {
        if (property.propertyName === pinCodeColumns[0]) {
          pinCodeValue = property.value;
        }
      }); 

      fetch(
        process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPDATE_OFFER, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
          },
          body: JSON.stringify({
            offerId: offer.offerId,
            pinCode: pinCodeValue,
            userId: JSON.parse(localStorage.getItem("user")).id,
            companyId: offer.companyId,
            isAvailable: offer.isAvailable,
            status: offer.status,
            offerProperties: updatedProperties
          }),
        }
      )
        .then((res) => {
          if(res.ok) {
            return res.json();
          }
          else if (res.status === 401) {
            LogOutEndPoint(navigate);
          }
          throw res;
        })
        .then((data) => {
          setModal(false);
          
          let temp = [...alertList];
          temp.push({ status: "success", text: data.result.message });
          setAlertList([...temp]);

          setGetAllOfferByDescTrigger(!getAllOfferByDescTrigger);
          return data;
          
        })
        .catch((error) => {
          let temp = [...alertList];
          temp.push({ status: "error", text: "Offer Update Fail" });
          setAlertList([...temp]);
          console.log(error);
        });
    }  

    useEffect(()=>{
      const result = offerTemplate.find(offertemp=> offertemp.desc===selectedOfferTemplate.desc)
      if(result){
        setOfferNameToUpdate(result.desc)
      }
    },[selectedOfferTemplate, offerNameEdit])

    function UpdateOfferName(){
      if(offerNameToUpdate!=="")
    {fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPDATE_OFFERS_BY_DESC,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json" ,
          "Accept": "application/json",
          "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
        body: JSON.stringify({
          userId: JSON.parse(localStorage.getItem("user")).id,
          desc: selectedOfferTemplate.desc,
          newDesc: offerNameToUpdate
        }),
      }
    )
      .then((res) => {
        selectedOfferTemplate.desc=offerNameToUpdate
        setGetAllOfferByDescTrigger(!getAllOfferByDescTrigger)
        setOfferNameEdit(!offerNameEdit)
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        let temp = [...alertList];
        temp.push({ status: "success", text: "Offer Name Update Successful" });
        setAlertList([...temp]);
        return data;
      })
      .catch((error) => {
        let temp = [...alertList];
        temp.push({ status: "error", text: "Offer Name Update Failed" });
        setAlertList([...temp]);
        console.log(error);
      });
    }
    }
    
  return (
    <>
      {loadingPage && (
        <div className="load">
          <img src={loadingGif} alt="loading" />
          <label>{langFile.pages.AllData.loadingLabel}</label>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          position: "absolute",
          right: "1vw",
          height: "min-content",
          bottom: "0vh",
        }}
      >
        {tempArr.map((item, index) => {
          return (
            <Alert
              index={index}
              key={index}
              time={5000}
              status={item.status}
              text={item.text}
            ></Alert>
          );
        })}
      </div>

        

      <div className="all-offer-parent">
        <div className="all-offer-navigation">
          <select
            name="selectOfferTemplate"
            id="selectOfferTemplate"
            onChange={(event) => {setSelectedOfferTemplate(offerTemplate[event.target.value]); setSelectedOfferTemplateCounts(offerTemplate[event.target.value].counts[0])}}
          >
            {offerTemplate?.map((item, index) => (
              <option key={index} value={index}>
                {item.desc}
              </option>
            ))}
          </select>

          {offerNameEdit?
            <>
            <input type="text"
            value={offerNameToUpdate}
            onChange={(e)=>setOfferNameToUpdate(e.target.value)}>
            </input>
            <button onClick={()=>{setOfferNameEdit(false)}} className="edit-button">
            <img alt="close" src={closebtn} ></img>
          </button> 
            <button onClick={()=>UpdateOfferName()} className="edit-button">
            <img alt="confirm" src={confirm_ico} ></img>
          </button>
          
          </>:
            <button onClick={()=>setOfferNameEdit(true)} className="edit-button">
              <img alt="edit" src={edit_ico} ></img>
            </button>
            }

          <select
            name="selectSituation"
            id="selectSituation"
            onChange={(e) => setSelectSituation(e.target.value)}
            value={selectSituation}
          >
            <option value="all">
              {langFile.pages.AllOffer.All}
            </option>
            <option value="active">
              {langFile.pages.AllOffer.Active}
            </option>
            <option value="deactive">
              {langFile.pages.AllOffer.Deactive}
            </option>
            
          </select>

          <>
            <input type="text" value={`${langFile.pages.AllOffer.countAll}: ${selectedOfferTemplateCounts.countAll}`} readOnly style={{backgroundColor:"rgb(204, 227, 208)", color:"black",fontSize:".8vw"}}/>
            <input type="text" value={`${langFile.pages.AllOffer.countIsActive}: ${selectedOfferTemplateCounts.countIsActive}`} readOnly style={{backgroundColor:"rgb(234, 217, 242)", color:"black", fontSize:".8vw"}}/>
            <input type="text" value={`${langFile.pages.AllOffer.countIsDeactive}: ${selectedOfferTemplateCounts.countIsDeactive}`} readOnly style={{backgroundColor:"rgb(244, 232, 213)", color:"black", fontSize:".8vw"}}/>
          </>
          

          {/* <button onClick={() => { HandleActivityAllDesc("Active") }} style={{ marginLeft: "auto", marginRight: "1vw" }} >
            <img alt="deactive" src={active_ico} className="activityIco"></img>
            
          </button>
          <button onClick={() => { HandleActivityAllDesc("Deactive") }}>
            <img alt="deactive" src={deactive_ico} className="activityIco"></img>
            
          </button> */}
        </div>
        <div className="data-list-container">
          {offerList && offerList.length > 0 ?
            <table>
              <thead>
                <tr>
                  
                  <th>{langFile.pages.SearchOffer.AdherenceToPolicy}</th>
                  {offerTitles.map((title, titleIndex) => (
                  <th key={"title" + titleIndex}>
                          {title}
                  </th>
                      ))}
                  <th>{langFile.pages.SearchOffer.Situation}</th>
                </tr>
              </thead>
              <tbody>
                {offerList?.filter((offer) => {
                  if (selectSituation === "all") {
                    return true;
                  } else if (selectSituation === "active" && offer.status === "Active") {
                    return true;
                  } else if (selectSituation === "deactive" && offer.status === "Deactive") {
                    return true;
                  } else {
                    return false;
                  }
                }).map((offer, offerIndex) => (
                  offer.status !== "Deleted" ?
                    <tr key={offerIndex}>
                      
                      <td style={{color:!offer.isAvailable?"#C53333":""}}>
                        <button onClick={() => toggleModal(offer)}
                        // onClick={() => { ChangeActivityById(offerIndex, "Deleted") }}
                        >
                          <img alt="edit" src={edit_ico} className="editIco"></img>
                          {/* {selectedOfferTemplate.desc + " için tüm offerları deaktif yap" } */}
                        </button>
                        {!offer.isAvailable ? "Verildi" : "Boşta"}
                      </td>
                      {offerTitles.map((title, titleIndex) => (
                        <td key={"title" + titleIndex}>
                                
                                {offer.property.find(property => property.propertyName === title)?.value}
                        </td>
                            ))}
                      <td>
                        {/* <div className="chart-type-toggle">
                          <label className="switch">
                            <input id="isAvailableCheckBox"
                              type="checkbox"
                              checked={offer.status === "Active"}
                              onChange={(event) => HandleCheckboxChange(event, offerIndex)}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div> */}
                        {offer.status}</td>
                        
                
                    </tr>
                    : null

                ))}
                 <tr
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "1vh 0",
                width: "100%!important",
              }}
            >
              <td style={{ borderRadius: "5px", minWidth: "100%" }}>
                <button
                  className="load-more-button"
                  onClick={() => {
                    setCounterForPagination(counterForPagination + 1);
                  }}
                >
                  {loadingForLoadMore === true
                    ? // <div className="data-loader"></div>
                      langFile.pages.AllData.loadMoreButton
                    : langFile.pages.AllData.loadMoreButton}
                </button>
              </td>
            </tr>
              </tbody>
            </table>
            : null}
        </div>
      </div>
      {modal && selectedOfferForEdit && (
          <div className="modal">
            <div className="overlay"></div>
            <div className="modal-content">
              <button className="close-modal" onClick={toggleModal}>
                <img className="closeIco" alt="closeModal" src={close_ico} ></img>
              </button>
              <h2>{langFile.pages.AllOffer.ModalHeader}</h2>
              <table>
              <thead>
                <tr>
                  
                  <th>{langFile.pages.SearchOffer.AdherenceToPolicy}</th>
                  {offerTitles.map((title, titleIndex) => (
                  <th key={"title" + titleIndex}>
                          {title}
                  </th>
                      ))}
                  <th>{langFile.pages.SearchOffer.Situation}</th>
                </tr>
              </thead>
              <tbody>
                    <tr>
                      
                      <td style={{color:!selectedOfferForEdit.isAvailable?"#C53333":""}}>
                        {!selectedOfferForEdit.isAvailable ? "Verildi" : "Boşta"}
                      </td>
                      {offerTitles.map((title, titleIndex) => (
                        <td key={"title" + titleIndex}>
                                <input 
                                type="text" 
                                className="newInput" 
                                placeholder={selectedOfferForEdit.property.find(property => property.propertyName === title)?.value}
                                onChange={(e) => handlePropertyChange(title, e.target.value)}
                                 />
                                
                        </td>
                            ))}
                      <td>
                        <div className="chart-type-toggle">
                          <label className="switch">
                            <input id="isAvailableCheckBox"
                              type="checkbox"
                              checked={selectedOfferForEdit.status === "Active"}
                              onChange={HandleCheckboxChangeModal}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        {selectedOfferForEdit.status}</td>
                        
                
                    </tr>
              </tbody>
            </table>
            <div className="buttons">
                <button className="deleteBtn" onClick={() => handleDelete(selectedOfferForEdit)}>{langFile.pages.AllOffer.Delete}</button>
                <button className="saveBtn" onClick={() => {setShouldHandleCheckboxChange(false); UpdateOffer(selectedOfferForEdit)}}>{langFile.pages.AllOffer.Save}</button>
            </div>
            </div>
          </div>
        )}
    </>
  )
}
