function LogOutEndPoint(navigate) {
  const user = JSON.parse(localStorage.getItem("user"));
  fetch(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_LOGOUT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + user.accessToken,
    },
    body: JSON.stringify({
      id: user.id,
    }),
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw res;
    })
    .then((data) => {
      localStorage.clear();
      navigate("/");
    })
    .catch((error) => {
      console.log("error.sttus", error.status);

      console.log(error);
    });
}

export default LogOutEndPoint;
