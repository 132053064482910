import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Alert from "../components/Alert/Alert";
import loadingGif from "../assets/loading3.gif";
import pages_ico from "../assets/mentorSigortaLogo.png";
import { LoginContext } from "../context/LoginContext";

export default function Validation() {
  const email = useLocation().state?.email;
  const password = useLocation().state?.password;

  const [alertList, setAlertList] = useState([]);
  const [code, setCode] = useState("");
  const [codeError, setcodeError] = useState(false);
  const { SignInConfirm } = useContext(LoginContext);

  async function SendVerificationCode(inputCode) {
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_EMAIL_VERIFICATION,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          usernameOrEmail: email,
          verificationCode: inputCode,
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then((data) => {
        if (data.message.includes("Verification code is correct")) {
          SignInConfirm(email, password);
        }
        // return data;
      })
      .catch((error) => {
        setcodeError(true);
        let temp = [...alertList];
        temp.push({ status: "error", text: "Failed" });
        setAlertList([...temp]);
        // console.log(error);
      });
  }

  const handleSendVerificationCode = () => {
    SendVerificationCode(code);
  };
  return (
    <>
      <div className="resetPassword-parent">
        <img alt="pages_ico" src={pages_ico} className="img" />
        <div className="container">
          <h4 style={{ textAlign: "center", marginBottom: 6 }}>
            E-posta adresine gelen doğrulama kodunu yazınız.
          </h4>
          <input
            className="input"
            type="text"
            placeholder="E-posta adresine gelen doğrulama kodunu yazınız."
            value={code}
            onChange={(event) => setCode(event.target.value)}
          />
          {codeError === true ? (
            <label className="errorLabel">Kod Eksik veya Hatalı</label>
          ) : null}
          <button className="button" onClick={handleSendVerificationCode}>
            Kodu Doğrula
          </button>
        </div>
      </div>
    </>
  );
}
