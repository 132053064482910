import React, { useState } from "react";
import "./PropertiesList.css";
import edit_ico from "../../assets/edit.png";
import confirm_ico from "../../assets/confirm.png";
import close_ico from "../../assets/close-button.png";
import LogOutEndPoint from "../LogOutEndPoint";
import { useNavigate } from "react-router-dom";

export default function PropertiesList(props) {
  const [selectedProperty, setSelectedProperty] = useState({
    id: "",
    name: "",
  });
  const [newPropertyName, setNewPropertyName] = useState("");
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();

  const handleEdit = (id, name) => {
    setSelectedProperty({ id, name });
    setEdit(true);
    setNewPropertyName(name);
  };

  const UpdatePropertyName = () => {
    UpdateProductPropertiesName(selectedProperty.id, newPropertyName);
    setEdit(false);
  };

  function UpdateProductPropertiesName(idToUpdate, newPropertyName) {
    if (newPropertyName !== "") {
      fetch(
        process.env.REACT_APP_BASE_URL +
          process.env.REACT_APP_PRODUCT_PROPERTY_UPDATE,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
          },
          body: JSON.stringify({
            guid: idToUpdate,
            userId: JSON.parse(localStorage.getItem("user")).id,
            name: newPropertyName,
          }),
        }
      )
        .then((res) => {
          props.setGetTemplateTrigger(!props.getTemplateTrigger);
          if (res.ok) {
            return res.json();
          } else if (res.status === 401) {
            LogOutEndPoint(navigate);
          }
          throw res;
        })
        .then((data) => {
          let temp = [...props.alertList];
          temp.push({
            status: "success",
            text: "Product Properties Name Update Successful",
          });
          props.setAlertList([...temp]);
          return data;
        })
        .catch((error) => {
          let temp = [...props.alertList];
          temp.push({
            status: "error",
            text: "Product Properties Name Update Failed",
          });
          props.setAlertList([...temp]);
          console.log(error);
        });
    }
  }

  return (
    <div className="product-detail">
      {props.properties.map((item, index) => (
        <div key={index} className="property-container">
          {edit && selectedProperty.id === item.id ? (
            <>
              <input
                type="text"
                value={newPropertyName}
                onChange={(e) => setNewPropertyName(e.target.value)}
              />
              <button onClick={() => setEdit(false)} className="edit-button">
                <img alt="close" src={close_ico}></img>
              </button> 
              <button onClick={UpdatePropertyName} className="edit-button">
                <img alt="confirm" src={confirm_ico} ></img>
              </button>
            </>
          ) : (
            <>
              {item.name}
              <button onClick={() => handleEdit(item.id, item.name)} className="edit-button">
                <img alt="edit" src={edit_ico}></img>
              </button>
            </>
          )}
        </div>
      ))}
    </div>
  );
}
