import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Column } from "@ant-design/plots";
import * as xlsx from "xlsx";
import exportImg from "../../../assets/export.png";
import "./BarChart.css";

export default function BarChart(props) {
  const [data, setData] = useState([]);
  const [chartType, setChartType] = useState(props.stackedAreaChartDataType);
  const [calculateType, setCalculateType] = useState(
    props.stackedAreaChartDataCalculationType
  );

  useEffect(() => {
    setChartType(props.stackedAreaChartDataType);
    setCalculateType(props.stackedAreaChartDataCalculationType);
    if (
      props.stackedAreaChartDataType === "day" &&
      props.stackedAreaChartDataCalculationType === "contByAdd"
    ) {
      DayData();
    } else if (
      props.stackedAreaChartDataType === "day" &&
      props.stackedAreaChartDataCalculationType === "contWithoutAdd"
    ) {
      DayDataWithoutAdd();
    } else if (
      props.stackedAreaChartDataType === "month" &&
      props.stackedAreaChartDataCalculationType === "contByAdd"
    ) {
      MonthData();
    } else {
      MonthDataWithoutAdd();
    }
  }, [
    props.stackedAreaChartDataCalculationType,
    props.stackedAreaChartDataType,
    props.data,
    props.selectedProduct,
    props.selectedDate,
  ]);

  function exportToExcel() {
    const wb = xlsx.utils.book_new();
    const ws = xlsx.utils.json_to_sheet(data);
    xlsx.utils.book_append_sheet(wb, ws, "Veri Tablosu");
    const excelBuffer = xlsx.write(wb, { bookType: "xlsx", type: "array" });

    // Dosya adını belirleyin
    const dosyaAdi = "veri_tablosu.xlsx";

    // Blob oluşturun ve indirme bağlantısı oluşturun
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", dosyaAdi);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function getSeparator(value) {
    const separators = ["/", "-", "."];
    return separators.find((separator) => value.includes(separator)) || "";
  }
  function calculateDateDifference(startDate, endDate) {
    const oneDay = 24 * 60 * 60 * 1000; // Bir günün milisaniye cinsinden değeri
    const separator = getSeparator(startDate);
    const startParts = startDate.split(separator); // Tarihi böl
    const endParts = endDate.split("/"); // Tarihi böl
    let startPartsYear = startParts[2];
    const start = new Date(
      startPartsYear?.length > 2 ? startParts[2] : "20" + startParts[2],
      startParts[1] - 1,
      startParts[0]
    ); // Yıl, ay, gün sırasıyla
    const end = new Date(endParts[2], endParts[1] - 1, endParts[0]); // Yıl, ay, gün sırasıyla

    // İki tarih arasındaki gün farkını hesapla
    const diffDays = Math.round((start - end) / oneDay);
    return diffDays;
  }

  function getDaysInMonth(startDate) {
    const separator = getSeparator(startDate);
    const startParts = startDate.split(separator);
    let startPartsYear = startParts[2];
    const start = new Date(
      startPartsYear?.length > 2 ? startParts[2] : "20" + startParts[2],
      startParts[1] - 1,
      startParts[0]
    );

    const nextMonth = new Date(start);
    nextMonth.setMonth(nextMonth.getMonth() + 1);

    const daysInMonth = (nextMonth - start) / (1000 * 60 * 60 * 24);

    return daysInMonth;
}


  function getPreviousDate(daysBefore) {
    // const currentDate = new Date(); // Mevcut tarih ve zamanı al
    const currentDate = new Date(props.selectedDate);
    const oneDay = 24 * 60 * 60 * 1000; // Bir günün milisaniye cinsinden değeri

    // Belirtilen gün sayısına göre önceki tarihi hesapla
    const previousDate = new Date(currentDate.getTime() - daysBefore * oneDay);

    const day = previousDate.getDate();
    const month = previousDate.getMonth() + 1; // JavaScript'te aylar 0-11 arası indekslenir
    const year = previousDate.getFullYear();
    const formattedPreviousDate = `${day}/${month}/${year}`;

    return formattedPreviousDate;
  }

  function getPreviousMounth(monthBefore) {
    const currentDate = new Date(props.selectedDate);
    let totalMonth =
      currentDate.getMonth() + 1 + currentDate.getFullYear() * 12;
    totalMonth = totalMonth - monthBefore;
    const year =
      totalMonth % 12 === 0
        ? Math.floor(totalMonth / 12) - 1
        : Math.floor(totalMonth / 12);
    const month = totalMonth % 12 === 0 ? 12 : totalMonth % 12;
    let day = currentDate.getDate();
    let formattedPreviousDate = `${day}/${month}/${year}`;
    if (day > 28) {
      //since all months does not contain the same number of days, the condition that below is written
      if (getDaysInMonth("01/" + (month + 1) + "/" + year) < 29) {
        formattedPreviousDate = `28/${month}/${year}`;
        return formattedPreviousDate;
      } else if (getDaysInMonth("01/" + (month + 1) + "/" + year) < 30) {
        formattedPreviousDate = `29/${month}/${year}`;
        return formattedPreviousDate;
      } else if (getDaysInMonth("01/" + (month + 1) + "/" + year) < 31) {
        formattedPreviousDate = `30/${month}/${year}`;
        return formattedPreviousDate;
      }
    }
    return formattedPreviousDate;
  }

  function DayDataWithoutAdd() {
    if (props.data?.length > 0 && props.selectedProduct !== "") {
      let arr = [];
      for (let i = 14; i >= 0; i--) {
        let dateForChart = getPreviousDate(i);
        let activeCounter = 0;
        let inActiveCounter = 0;
        for (let j = 0; j < props.data.length; j++) {
          if (
            calculateDateDifference(props.data[j].date, dateForChart) === 0 &&
            (props.selectedProduct === props.data[j].template ||
              props.selectedProduct === "*")
          ) {
            if (props.data[j].isActive) {
              activeCounter = activeCounter + props.data[j].totalCount;
            } else {
              inActiveCounter = inActiveCounter + props.data[j].totalCount;
            }
          }
        }
        let objForActive = {
          date: dateForChart,
          type: "Active",
          value: activeCounter,
        };
        let objForInActive = {
          date: dateForChart,
          type: "InActive",
          value: inActiveCounter,
        };
        arr.push(objForActive);
        arr.push(objForInActive);
      }
      setData(arr);
    }
  }

  function DayData() {
    if (props.data?.length > 0 && props.selectedProduct !== "") {
      let arr = [];
      for (let i = 14; i >= 0; i--) {
        let dateForChart = getPreviousDate(i);
        let activeCounter = 0;
        let inActiveCounter = 0;
        for (let j = 0; j < props.data.length; j++) {
          if (
            calculateDateDifference(props.data[j].date, dateForChart) <= 0 &&
            (props.selectedProduct === props.data[j].template ||
              props.selectedProduct === "*")
          ) {
            if (props.data[j].isActive) {
              activeCounter = activeCounter + props.data[j].totalCount;
            } else {
              inActiveCounter = inActiveCounter + props.data[j].totalCount;
            }
          }
        }
        let objForActive = {
          date: dateForChart,
          type: "Active",
          value: activeCounter,
        };
        let objForInActive = {
          date: dateForChart,
          type: "InActive",
          value: inActiveCounter,
        };
        arr.push(objForActive);
        arr.push(objForInActive);
      }
      setData(arr);
    }
  }

  function MonthData() {
    if (props.data?.length > 0 && props.selectedProduct !== "") {
      let arr = [];
      for (let i = 11; i >= 0; i--) {
        let dateForChart = getPreviousMounth(i);
        let activeCounter = 0;
        let inActiveCounter = 0;
        for (let j = 0; j < props.data.length; j++) {
          if (
            calculateDateDifference(props.data[j].date, dateForChart) <= 0 &&
            (props.selectedProduct === props.data[j].template ||
              props.selectedProduct === "*")
          ) {
            if (props.data[j].isActive) {
              activeCounter = activeCounter + props.data[j].totalCount;
            } else {
              inActiveCounter = inActiveCounter + props.data[j].totalCount;
            }
          }
        }
        let objForActive = {
          date: dateForChart,
          type: "Active",
          value: activeCounter,
        };
        let objForInActive = {
          date: dateForChart,
          type: "InActive",
          value: inActiveCounter,
        };
        arr.push(objForActive);
        arr.push(objForInActive);
      }
      setData(arr);
    }
  }


  function MonthDataWithoutAdd() {
    if (props.data?.length > 0 && props.selectedProduct !== "") {
      let arr = [];
      for (let i = 11; i >= 0; i--) {
        let dateForChart = getPreviousMounth(i);
        let activeCounter = 0;
        let inActiveCounter = 0;
        for (let j = 0; j < props.data.length; j++) {
          // console.log(props.data[j].date, dateForChart )
          // console.log(0-calculateDateDifference(props.data[j].date, dateForChart))
          // console.log(getDaysInMonth(props.data[j].date))
          // console.log(props.data[j].date)
          // console.log(getDaysInMonth(props.data[j].date))
          if (
            calculateDateDifference(props.data[j].date, dateForChart) <= 0 &&
            0-calculateDateDifference(props.data[j].date, dateForChart) <
              getDaysInMonth(props.data[j].date) &&
            (props.selectedProduct === props.data[j].template ||
              props.selectedProduct === "*")
          ) {
            if (props.data[j].isActive) {
              activeCounter = activeCounter + props.data[j].totalCount;
            } else {
              inActiveCounter = inActiveCounter + props.data[j].totalCount;
            }
          }
        }
        let objForActive = {
          date: dateForChart,
          type: "Active",
          value: activeCounter,
        };
        let objForInActive = {
          date: dateForChart,
          type: "InActive",
          value: inActiveCounter,
        };
        arr.push(objForActive);
        arr.push(objForInActive);
      }
      setData(arr);
    }
  }

  //   const [data, setData] = useState(
  //     [
  //   {
  //     "date": "2023.01.01",
  //     "type": "activeProductCountPerDay",
  //     "value": 14500
  //   },
  //   {
  //     "date": "2023.01.01",
  //     "type": "inactiveProductCountPerDay",
  //     "value": 8500
  //   },
  //   {
  //     "date": "2023.01.10",
  //     "type": "activeProductCountPerDay",
  //     "value": 9000
  //   },
  //   {
  //     "date": "2023.01.10",
  //     "type": "inactiveProductCountPerDay",
  //     "value": 8500
  //   },
  // ]
  //   );

  const config = {
    data,
    xField: "date",
    yField: "value",
    seriesField: "type",
    isGroup: true,
    color: ["#edbb5f", "#AEC8D7", "#E6CDD8", "#CCE3D0", "#EAD9F2", "#F4E8D5"],
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
  };

  return (
    <>
      <button
        onClick={() => {
          exportToExcel();
        }}
        className="chart-export-button"
      >
        <img
          alt="export"
          src={exportImg}
          style={{ width: "100%", height: "100%" }}
        ></img>
      </button>
      <Column style={{ width: "100%", height: "100%" }} {...config} />
    </>
  );
}
