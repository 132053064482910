import React, { useState, useRef, useEffect } from "react";
import "./ProfileCard.css";
import user_photo from "../../assets/user.png";
import edit_ico from "../../assets/edit.png";
import email_ico from "../../assets/email.png";
import phone_ico from "../../assets/phone.png";
import point_ico from "../../assets/point.png";
import logout_ico from "../../assets/logout.png";
import save_ico from "../../assets/save.png";
import addPhoto_ico from "../../assets/add-photo.png";
import { useNavigate } from "react-router-dom";
import en from "../../locales/en.json";
import tr from "../../locales/tr.json";
import LangDropdown from "../LangDropdown/LangDropdown";
import LogOutEndPoint from "../LogOutEndPoint";

export default function ProfileCard(props) {
  const [local, setLocal] = useState(localStorage.getItem("language"));
  const [langFile, setLangFile] = useState(local === "English" ? en : tr);

  useEffect(() => {
    setLangFile(local === "English" ? en : tr);
    localStorage.setItem("language", local);
  }, [local]);

  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  function LogOut() {
    // fetch(process.env.REACT_APP_BASE_URL+process.env.REACT_APP_LOGOUT, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     accept: "application/json",
    //     Authorization: "Bearer " + user.accessToken,
    //   },
    //   body: JSON.stringify({
    //     id: user.id,
    //   }),
    // })
    //   .then((res) => {
    //     if (res.ok) {
    //       return res.json();
    //     }
    //     throw res;
    //   })
    //   .then((data) => {
    //     localStorage.clear();
    //     navigate("/");
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    LogOutEndPoint(navigate);
  }
  const [inputs, setInputs] = useState({
    firstName: "",
    lastName: "",
    accountCompanyName: "",
    email: "",
    phoneNumber: "",
    address: "",
  });
  const [disabled, setDisabled] = useState(true);
  const inputRef = useRef(null);
  const handleChange = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };
  const handleEditClick = () => {
    setDisabled(false);
  };
  const handleSaveClick = () => {
    setDisabled(true);
  };
  const handleImageClick = () => {
    inputRef.current.click();
  };
  function refreshPage() {
    window.location.reload();
  }

  useEffect(() => {
    if (props.profileComp === false) {
      setTimeout(function () {
        const element = document.querySelector(".parent-profile-card");
        element.style.display = "none";
      }, 400);
    } else {
      const element = document.querySelector(".parent-profile-card");
      element.style.display = "flex";
    }
  }, [props.profileComp]);

  return (
    <div
      className={
        props.profileComp === true
          ? "container-profile-card"
          : "container-profile-card container-profile-card-close"
      }
    >
      <div className="parent-profile-card">
        <div className="profile-photo-parent">
          {" "}
          <img
            alt="profile_photo"
            src={user_photo}
            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
          ></img>
        </div>
        <div className="firstName">
          <input
            type="text"
            name="firstName"
            value={inputs.firstName}
            placeholder={user.firstName}
            disabled={disabled}
            onChange={handleChange}
            maxLength={16}
            style={{ width: "100%", border: "hidden" }}
          ></input>
        </div>
        <div className="lastName">
          <input
            type="text"
            name="lastName"
            value={inputs.lastName}
            placeholder={user.lastName}
            disabled={disabled}
            onChange={handleChange}
            maxLength={16}
            style={{ width: "100%", border: "hidden" }}
          ></input>
        </div>
        <div className="accountCompanyName">
          <input
            type="text"
            name="accountCompanyName"
            value={inputs.accountCompanyName}
            placeholder={
              JSON.parse(localStorage.getItem("accountCompanyName")) +
              "/" +
              user.username
            }
            disabled={disabled}
            onChange={handleChange}
            maxLength={16}
            style={{ width: "100%", border: "hidden" }}
          ></input>
        </div>
        <label>{langFile.pages.ProfileCard.status}</label>
        <div className="edit-button-parent-profile-card">
          {/* {disabled && (
            <button
              onClick={handleEditClick}
              style={{
                backgroundColor: "transparent",
                border: "none",
                width: "7.5%",
              }}
            >
              <img
                className="edit-ico-profile-card"
                alt="edit"
                src={edit_ico}
              ></img>
            </button>
          )} */}
          {!disabled && (
            <button
              onClick={handleSaveClick}
              style={{
                backgroundColor: "transparent",
                border: "none",
                width: "14%",
              }}
            >
              <img
                className="save-ico-profile-card"
                alt="save"
                src={save_ico}
              ></img>
            </button>
          )}
        </div>
        <div className="info-parent-profile-card">
          <img
            className="info-ico-profile-card"
            alt="email"
            src={email_ico}
          ></img>
          <label>
            <input
              type="email"
              name="email"
              value={inputs.email}
              placeholder={user.email}
              disabled={disabled}
              onChange={handleChange}
              maxLength={22}
              style={{
                width: "90%",
                border: "hidden",
              }}
            ></input>
          </label>
        </div>
        <div className="info-parent-profile-card">
          <img
            className="info-ico-profile-card"
            alt="phone"
            src={phone_ico}
          ></img>
          <label>
            <input
              type="text"
              name="phoneNumber"
              value={inputs.phoneNumber}
              placeholder={user.phoneNumber}
              disabled={disabled}
              onChange={handleChange}
              style={{
                width: "90%",
                border: "hidden",
              }}
            ></input>
          </label>
        </div>
        {/* <div className="info-parent-profile-card">
          <img
            className="info-ico-profile-card"
            alt="point"
            src={point_ico}
          ></img>
          <label>
            <input
              type="text"
              name="address"
              value={inputs.address}
              placeholder={user.address}
              disabled={disabled}
              onChange={handleChange}
              maxLength={22}
              style={{
                width: "90%",
                border: "hidden",
              }}
            ></input>
          </label>
        </div> */}
        {/* <select
          name="language"
          id="language"
          onChange={(event) => {
            setLocal(event.target.value);
            refreshPage();
          }}
          defaultValue={localStorage.getItem("language")}
        >
          <option value={"Turkish"}>Turkish</option>
          <option value={"English"}>English</option>
        </select> */}
        <div style={{ width: "100%" }}>
          <div className="lang-container">
            <LangDropdown
              selected={local}
              setSelected={setLocal}
            ></LangDropdown>
          </div>
        </div>

        <div className="logout-button-parent-profile-card">
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={LogOut}
          >
            <img
              className="logout-ico-profile-card"
              alt="edit"
              src={logout_ico}
            ></img>
            <label>Log Out</label>
          </button>
        </div>
      </div>
    </div>
  );
}
