import React, { useState } from 'react';
import './Calendar.css';

const Calendar = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const reminders = [
    { id: 1, date: new Date(2023, 6, 1), text: 'Reminder 1' },
    { id: 2, date: new Date(2023, 6, 1), text: 'Reminder 2' },
    { id: 3, date: new Date(2023, 6, 1), text: 'Reminder 3' },
    { id: 4, date: new Date(2023, 6, 1), text: 'Reminder 4' },
    { id: 5, date: new Date(2023, 6, 1), text: 'Reminder 5' },
    { id: 6, date: new Date(2023, 6, 1), text: 'Reminder 1' },
    { id: 7, date: new Date(2023, 6, 1), text: 'Reminder 2' },
    { id: 8, date: new Date(2023, 6, 1), text: 'Reminder 3' },
    { id: 9, date: new Date(2023, 6, 1), text: 'Reminder 4' },
    { id: 10, date: new Date(2023, 6, 1), text: 'Reminder 5' },
    { id: 11, date: new Date(2023, 6, 1), text: 'Reminder 1' },
    { id: 12, date: new Date(2023, 6, 1), text: 'Reminder 2' },
    { id: 13, date: new Date(2023, 6, 1), text: 'Reminder 3' },
    { id: 14, date: new Date(2023, 6, 1), text: 'Reminder 4' },
    { id: 15, date: new Date(2023, 6, 1), text: 'Reminder 5' },
    { id: 16, date: new Date(2023, 6, 1), text: 'Reminder 1' },
    { id: 17, date: new Date(2023, 6, 1), text: 'Reminder 2' },
    { id: 18, date: new Date(2023, 6, 1), text: 'Reminder 3' },
    { id: 19, date: new Date(2023, 6, 1), text: 'Reminder 4' },
    { id: 20, date: new Date(2023, 6, 1), text: 'Reminder 5' },
    { id: 21, date: new Date(2023, 6, 2), text: 'Reminder 6' },
  ];

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const handlePrevMonth = () => {
    setCurrentMonth((prevMonth) =>
      new Date(prevMonth.getFullYear(), prevMonth.getMonth() - 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentMonth((prevMonth) =>
      new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1)
    );
  };

  const renderReminders = () => {
    const remindersForSelectedDate = reminders.filter(
      (reminder) =>
        reminder.date.getDate() === selectedDate?.getDate() &&
        reminder.date.getMonth() === selectedDate?.getMonth() &&
        reminder.date.getFullYear() === selectedDate?.getFullYear()
    );

    return (
    
      <div className="reminders-container">
        {remindersForSelectedDate.map((reminder) => (
          <div key={reminder.id} className="reminder">
            {reminder.text}
          </div>
        ))}
      </div>
      
    );
  };

  const renderCalendar = () => {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const currentDate = new Date();
    const currentMonthIndex = currentMonth.getMonth();
    const currentYear = currentMonth.getFullYear();
    const firstDayOfMonth = new Date(currentYear, currentMonthIndex, 1);
    const startingDayOfWeek = firstDayOfMonth.getDay();
    const daysInMonth = new Date(
      currentYear,
      currentMonthIndex + 1,
      0
    ).getDate();

    const calendar = [];

    // Boş hücreleri ekleyin
    for (let i = 0; i < startingDayOfWeek; i++) {
      calendar.push(
        <div key={`empty-${i}`} className="calendar-day empty"></div>
      );
    }

    // Günleri ekleyin
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currentYear, currentMonthIndex, day);
      const isCurrentDate =
        currentYear === selectedDate?.getFullYear() &&
        currentMonthIndex === selectedDate?.getMonth() &&
        day === selectedDate?.getDate();
      const remindersForDate = reminders.filter(
        (reminder) =>
          reminder.date.getDate() === day &&
          reminder.date.getMonth() === currentMonthIndex &&
          reminder.date.getFullYear() === currentYear
      );

      calendar.push(
        <div
          key={`day-${day}`}
          className={`calendar-day${isCurrentDate ? ' current' : ''}${
            remindersForDate.length > 0 ? ' has-reminder' : ''
          }`}
          onClick={() => handleDateClick(date)}
        >
          <div className="day-number">{day}</div>
        </div>
      );
    }

    return (
      <div className="calendar-container">
        <div className="calendar-header">
          <button className="nav-button" onClick={handlePrevMonth}>
            &lt;
          </button>
          <div className="month-year">
            {months[currentMonthIndex]} {currentYear}
          </div>
          <button className="nav-button" onClick={handleNextMonth}>
            &gt;
          </button>
        </div>
        <div style={{width:"100%", height:"95%", fontSize:".8vw", display:"flex", flexDirection:"row", gap:"1vw"}}>
        <div className="calendar">{calendar}</div>
        <div className='reminder-container'>
        <label style={{fontWeight:"bold", color:"var(--textColor)"}}>{selectedDate?.getDate() === undefined ? "": selectedDate?.getDate()+"/"+selectedDate?.getMonth()+"/"+selectedDate?.getFullYear()}</label>

        {selectedDate && renderReminders()}
        </div>
        

        </div>
      </div>
    );
  };

  return renderCalendar();
};

export default Calendar;
