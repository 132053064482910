import React, { useEffect, useState } from 'react'
import "./OffersOfProduct.css"
import { da } from 'date-fns/locale'
import LogOutEndPoint from '../LogOutEndPoint'
import { useNavigate } from 'react-router-dom'

export default function OffersOfProduct(props) {
  const navigate = useNavigate();
  const [offers, setOffers] = useState([])
  const [selectedOffersList, setSelectedOffersList] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [selectedOfferFromDropdown, setSelectedOfferFromDropdown] = useState("")
  const [dropdownLinksVisible, setDropdownLinksVisible] = useState(false);
  const [getOffersTrigger, setGetOffersTrigger] = useState(true)
  useEffect(() => {
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_ALL_OFFER_BY_GROUPS,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        if(data.responseData!==null){
        setOffers(data.responseData)
        }
        return data;
      })
      .catch((error) => {
        let temp = [...props.alertList];
        temp.push({ status: "error", text: "Get Offers Failed" });
        props.setAlertList([...temp]);
        // error.json().then(function (error) {
        //   console.log(error);
        // });
        console.log(error);
      });
  }, [])

  useEffect(() => {
    if (props.selectedProduct !== "") {
      fetch(
        process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_ALL_OFFERS_BY_TEMPLATE + props.selectedProduct + "&pageNumber=1&pageSize=999",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
          },
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          else if (res.status === 401) {
            LogOutEndPoint(navigate);
          }
          throw res;
        })
        .then((data) => {
          if (data.offers) {
            setSelectedOffersList(data.offers)
          }
          else {
            setSelectedOffersList([])
          }
          return data;
        })
        .catch((error) => {
          setSelectedOffersList([])
          let temp = [...props.alertList];
          temp.push({ status: "error", text: "Get Offers Failed" });
          props.setAlertList([...temp]);
          console.log(error);
        });
    }
  }, [props.selectedProduct,getOffersTrigger])

  function groupByDesc(arr) {
    const groupedOffers = {};

    arr?.forEach((item) => {
      const desc = item.desc;

      if (!groupedOffers[desc]) {
        groupedOffers[desc] = {
          desc: desc,
          offerList: [],
        };
      }

      const offerData = {
        offerId: item.offerId,
        property: item.property,
      };

      groupedOffers[desc].offerList.push(offerData);
    });

    return Object.values(groupedOffers);
  }

  const filteredOffers = offers.filter((offer) => {
    return (
      offer.desc.toLowerCase().includes(searchValue.toLowerCase()) &&
      !selectedOffersList.some((selectedOffer) => selectedOffer.offerDesc === offer.desc)
    );
  });

  const handleInputFocus = () => {
    setDropdownLinksVisible(true);
  };
  const handleInputBlur = () => {
    setTimeout(() => {
      setDropdownLinksVisible(false);
    }, 200);
  };
  function HandleAddOffer() {
    if (selectedOfferFromDropdown !== "") {
      AddOfferToTemplate()
    }
  }
  function DeleteOfferHandle(index) {
    // let temp = [...selectedOffersList]
    // temp.splice(index, 1);
    // setSelectedOffersList(temp)
    UnmatchTemplateOffer(index)
  }

  function UnmatchTemplateOffer(index){
    console.log(JSON.stringify({
      templateId: props.selectedProduct,
      offerDesc: selectedOffersList[index].offerDesc
    }))

    const user = JSON.parse(localStorage.getItem("user"));

    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UNMATCH_OFFER_AND_TEMPLATE,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            "Bearer " + user.accessToken,
        },
        body: JSON.stringify({
          userId: user.id,
          templateId: props.selectedProduct,
          offerDesc: selectedOffersList[index].offerDesc
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        let temp = [...props.alertList];
        temp.push({ status: "success", text: data.result.message });
        props.setAlertList([...temp]);
        return data;
      })
      .then((data) => {
        setGetOffersTrigger(!getOffersTrigger)
        return data;
      })
      .catch((error) => {
        let temp = [...props.alertList];
        temp.push({ status: "error", text: "Unmatch Between Template and Offer Failed" });
        props.setAlertList([...temp]);
        console.log(error);
      });
  }





  function AddOfferToTemplate() {

    const user = JSON.parse(localStorage.getItem("user"));

    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_MATCH_OFFER_AND_TEMPLATE,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            "Bearer " + user.accessToken,
        },
        body: JSON.stringify({
          userId: user.id,
          templateId: props.selectedProduct,
          offerDescs: [selectedOfferFromDropdown.desc]
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        let temp = [...props.alertList];
        temp.push({ status: "success", text: data.result.message });
        props.setAlertList([...temp]);
        setSelectedOfferFromDropdown("")
        setSearchValue("")
        return data;
      })
      .then((data) => {
        setGetOffersTrigger(!getOffersTrigger)
        return data;
      })
      .catch((error) => {
        let temp = [...props.alertList];
        temp.push({ status: "error", text: "Add Product Failed" });
        props.setAlertList([...temp]);
        console.log(error);
      });
  }
  return (
    <div className='product-offer-selection-container'>
      <div className='offer-dropdown-container'>
        <input
          type='text'
          placeholder='Lütfen Teklif Limiti:'
          id='offerLimitInput'
          name='offerLimitInput'
          value={props.langFile.pages.NewProduct.offerLimit+" 1"}
          disabled
        />
        <div id="myDropdown" className="dropdown-content">
          <input
            type='text'
            placeholder={props.langFile.pages.NewProduct.selectOffer}
            id='searchOfferInput'
            name='searchOfferInput'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            autoComplete='off'
          />
          {filteredOffers.map((offer, index) => (
            <a
              href={'#' + offer.desc}
              key={index}
              value={index}
              onClick={(e) => {
                setSearchValue(offer.desc);
                setSelectedOfferFromDropdown(offer)
              }}
              style={{ display: dropdownLinksVisible ? 'block' : 'none' }}
            >
              {offer.desc}
            </a>
          ))}
        </div>
        <button className='add_button' onClick={HandleAddOffer}>
          <img alt='add'>
          </img>
        </button>
      </div>
      <hr></hr>
      <div className='selected-offer-item'>
        <label>
        {props.langFile.pages.NewProduct.offerLimit}
        </label>
        <label>
        {props.langFile.pages.NewProduct.canUseOffer}
        </label>
        <label>
        {props.langFile.pages.NewProduct.offerName}
        </label>
        <button className='delete-button'>
        </button>
      </div>
      <hr></hr>
      <div className='selected-offers-list-container'>
        {selectedOffersList.map((item, index) => (
          <div key={index} className='selected-offer-item'>
            <label>
              {1}
            </label>
            <label>
            {item.counts[0].countStatusAndAvaiblityIsTrue}
            </label>
            <label>
            {item.offerDesc}
            </label>
            <button onClick={() => DeleteOfferHandle(index)} className='delete-button'>
              <img alt='delete'>
              </img>
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}
