import React, { useEffect, useState } from "react";
import "./EditUser.css";
import en from "../../locales/en.json";
import tr from "../../locales/tr.json";
import LogOutEndPoint from "../LogOutEndPoint";
import { useNavigate } from "react-router-dom";
import loadingGif from "../../assets/loading3.gif";

export default function EditUser(props) {
  const navigate = useNavigate();
  const [loadingPage, setLoadingPage] = useState(false);
  const [firstName, setFirstName] = useState(props.toEditUser.firstname);
  const [lastName, setLastName] = useState(props.toEditUser.lastname);
  const [email, setEmail] = useState(props.toEditUser.email);
  const [username, setUserName] = useState(props.toEditUser.username);
  const [password, setPassword] = useState(props.toEditUser.password);
  const [phoneNumber, setPhoneNumber] = useState(props.toEditUser.phoneNumber);
  const [langFile, setLangFile] = useState(en);
  const [accountType, setAccountType] = useState(props.toEditUser.privilege);
  const [accountTypes, setAccountTypes] = useState(["Admin", "User"]);
  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);

  useEffect(() => {
    setFirstName(props.toEditUser.firstName);
    setLastName(props.toEditUser.lastName);
    setEmail(props.toEditUser.email);
    setUserName(props.toEditUser.username);
    setPassword(props.toEditUser.password);
    setPhoneNumber(props.toEditUser.phoneNumber);
    setAccountType(props.toEditUser.privilege);
  }, [props.toEditUser]);
  function EditUser() {
    // console.log(JSON.stringify({
    //   user: {
    //     id: props.toEditUser.id,
    //     userName: username,
    //     firstName: firstName,
    //     lastName: lastName,
    //     password: password,
    //     phoneNumber: phoneNumber,
    //     email: email,
    //     privilege: accountType,
    //     isActive: props.isActive,
    //   },
    // }))
    setLoadingPage(true);
    const user = JSON.parse(localStorage.getItem("user"));

    fetch(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_EDIT_USER, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + user.accessToken,
      },
      body: JSON.stringify({
        user: {
          id: props.toEditUser.id,
          userName: username,
          firstName: firstName,
          lastName: lastName,
          password: password,
          phoneNumber: phoneNumber,
          email: email,
          privilege: accountType,
          isActive: true,
        },
        userId: user.id,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        let temp = [...props.alertList];
        temp.push({ status: "success", text: data.result.message });
        props.setAlertList([...temp]);
        props.setDeleteTrigger(!props.deleteTrigger);
        setLoadingPage(false);
        return data;
      })
      .catch((error) => {
        console.log(error);
        props.setDeleteTrigger(!props.deleteTrigger);
        let temp = [...props.alertList];
        temp.push({ status: "error", text: "User Updating Failed" });
        props.setAlertList([...temp]);
        setLoadingPage(false);
      });
  }

  return (
    <div className="parent-edit-user">
      {loadingPage && (
        <div className="load">
          <img src={loadingGif} alt="loading" />
          <label>{langFile.pages.AllData.loadingLabel}</label>
        </div>
      )}
      <div className="formbold-main-wrapper">
        <div className="formbold-form-wrapper">
          <button
            className="close-button"
            onClick={() => {
              props.setToEditUser(false);
            }}
          >
            {langFile.pages.EditUser.close}
          </button>
          <form>
            <div className="formbold-form-title">
              <h2 style={{ textAlign: "left" }}>
                {langFile.pages.EditUser.header1}
              </h2>
            </div>
            <div className="formbold-input-flex">
              <div>
                <label htmlFor="firstname" className="formbold-form-label">
                  {langFile.pages.EditUser.header9}
                </label>
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  className="formbold-form-input"
                  value={firstName || ""}
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </div>
            </div>
            <div className="formbold-input-flex">
              <div>
                <label htmlFor="lastname" className="formbold-form-label">
                  {langFile.pages.EditUser.header10}
                </label>
                <input
                  value={lastName || ""}
                  type="text"
                  name="lastname"
                  id="lastname"
                  className="formbold-form-input"
                  onChange={(event) => setLastName(event.target.value)}
                />
              </div>
            </div>
            <div className="formbold-input-flex">
              <div className="formbold-mb-3">
                <label htmlFor="password" className="formbold-form-label">
                  {langFile.pages.EditUser.header11}
                </label>
                <input
                  value={password || ""}
                  type="password"
                  name="password"
                  id="password"
                  className="formbold-form-input"
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div>
            </div>
            <div className="formbold-input-flex">
              <div>
                <label htmlFor="username" className="formbold-form-label">
                  {langFile.pages.EditUser.header12}
                </label>
                <input
                  value={username || ""}
                  type="text"
                  name="username"
                  id="username"
                  className="formbold-form-input"
                  onChange={(event) => setUserName(event.target.value)}
                />
              </div>
            </div>
            <div className="formbold-input-flex">
              <div>
                <div className="formbold-mb-3">
                  <label htmlFor="email" className="formbold-form-label">
                    {langFile.pages.EditUser.header13}
                  </label>
                  <input
                    value={email || ""}
                    type="email"
                    name="email"
                    id="email"
                    className="formbold-form-input"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="formbold-input-flex">
              <div>
                <div className="formbold-mb-3">
                  <label htmlFor="phoneNumber" className="formbold-form-label">
                    {langFile.pages.EditUser.header14}
                  </label>
                  <input
                    value={phoneNumber || ""}
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    className="formbold-form-input"
                    onChange={(event) => setPhoneNumber(event.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="formbold-input-flex">
              <div>
                <div className="formbold-mb-3">
                  <label htmlFor="phoneNumber" className="formbold-form-label">
                    {langFile.pages.EditUser.header15}
                  </label>
                  <select
                    name="selectProduct"
                    id="selectProduct"
                    onChange={(event) => setAccountType(event.target.value)}
                    value={accountType}
                  >
                    {accountTypes.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="formbold-btn"
              onClick={
                // Test
                EditUser
              }
            >
              {langFile.pages.EditUser.button1}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
