import React, { useEffect, useState } from "react";
import "../styles/SearchPackage.css";
import Alert from "../components/Alert/Alert";
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import search_ico from "../assets/search.png";
import delete_ico from "../assets/delete.png";
import loadingGif from "../assets/loading3.gif";
import edit_ico from "../assets/edit.png"
import close_ico from "../assets/close.png"
import LogOutEndPoint from "../components/LogOutEndPoint";
import { useNavigate } from "react-router-dom";

export default function SearchOffer() {
  const [langFile, setLangFile] = useState(en);
  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);

  const [alertList, setAlertList] = useState([]);
  const [tempArr, setTempArr] = useState([...alertList]);
  useEffect(() => {
    setTempArr(alertList);
  }, [alertList]);

  const navigate = useNavigate();
  const [pinCode, setPinCode] = useState("");
  const [offer, setOffer] = useState([]);
  const [warning, setWarning] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [selectedOfferTemplate, setSelectedOfferTemplate] = useState("");
  const [offerTemplate, setOfferTemplate] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedOfferForEdit, setSelectedOfferForEdit] = useState(null);
  const [offerTitles, setOfferTitles] = useState([])
  const [updatedPropertyValues, setUpdatedPropertyValues] = useState({});
  const [pinCodeColumns, setPinCodeColumns] = useState([]);
  const [offerList, setOfferList] = useState([])
  const [shouldHandleCheckboxChange, setShouldHandleCheckboxChange] = useState(false);

  const toggleModal = (offer) => {
    setSelectedOfferForEdit(offer);
    setModal(!modal); 
  }

  useEffect(() => {
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_ALL_OFFER_BY_GROUPS,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        setOfferTemplate(data.responseData);
        
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const fetchOffer = () => {
    setOffer([]);
    setLoadingPage(true);
    fetch(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_SEARCH_PACKAGE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
      body: JSON.stringify({
        desc: selectedOfferTemplate,
        pinCode: pinCode
      })
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        // console.log("DATAAAAAAAAAAAAAAA",data)
        setOffer(data);
        setOfferTitles(data.titles);
        setPinCodeColumns(data.pinCodeColumns);
        
        if (data.offers.length !== 0 ) {
          setWarning(false);
          let temp = [...alertList];
          temp.push({
            status: "success",
            text: "Package found",
          });
          setAlertList([...temp]);
          setLoadingPage(false)
          return data;
        } 
        // if (pinCode === "") {
        //   setWarning(true);
        //   let temp = [...alertList];
        //   temp.push({
        //     status: "error",
        //     text: "Package is not found",
        //   });
        //   setAlertList([...temp]);
        // }
        else {
          setOffer([]);
          setWarning(true);
          let temp = [...alertList];
          temp.push({
            status: "error",
            text: "Package is not found",
          });
          setAlertList([...temp]);
          setLoadingPage(false)
        }
      })
      .catch((error) => {
        setOffer([]);
        let temp = [...alertList];
        temp.push({
          status: "error",
          text: "Package is not found",
        });
        setAlertList([...temp]);
        setLoadingPage(false)
        console.log(error);
      });
  };

  // function HandleCheckboxChange(event, offerIndex) {
  //   const offerStatus = event.target.checked ? "Active" : "Deactive";

  //   ChangeActivityById(offerIndex, offerStatus);
  // }

  
  // function ChangeActivityById(offerIndex, offerStatus) {

  //   const user = JSON.parse(localStorage.getItem("user"));
    
  //   fetch(
  //     process.env.REACT_APP_BASE_URL +
  //       process.env.REACT_APP_UPDATE_STATUS_BY_OFFER_ID,
  //     {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //         Authorization:
  //           "Bearer " + user.accessToken,
  //       },
  //       body: JSON.stringify({
  //         offerId: offer.offers[offerIndex].offerId,
  //         userId: user.id,
  //         status: offerStatus,
  //       }),
  //     }
  //   )
  //     .then((res) => {
  //       if (res.ok) {
  //         return res.json();
  //       }
  //       throw res;
  //     })
  //     .then((data) => {
  //       if (data.result.success) {
  //         const updatedOffer = offer;
  //         updatedOffer.offers[offerIndex].offerStatus = offerStatus;
  //         setOffer(updatedOffer);
  //       }
  //       if (offerStatus === "Deleted") {
  //         setOffer([]);
  //         setWarning(true);
  //       }
  //       let temp = [...alertList];
  //       temp.push({ status: "success", text: data.result.message });
  //       setAlertList([...temp]);
        
  //       return data;
  //     })
  //     .catch((error) => {
  //       let temp = [...alertList];
  //       temp.push({ status: "error", text: "Package Change Status Fail" });
  //       setAlertList([...temp]);
  //       console.log(error);
  //     });
      
  // }

  // Modal

  function HandleCheckboxChangeModal(event) {

    const status = event.target.checked ? "Active" : "Deactive"

    setSelectedOfferForEdit(prevOffer => ({
      ...prevOffer,
      offerStatus: status
    }));

    if (shouldHandleCheckboxChange) {
      ChangeActivityByIdModal(selectedOfferForEdit, status);
    }

  };

  function handleDelete(offer) {
    ChangeActivityByIdModal(offer, "Deleted")
    setModal(false);
    setOffer([]);
  }

  function ChangeActivityByIdModal(offer, status) {

    const user = JSON.parse(localStorage.getItem("user"));

    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPDATE_STATUS_BY_OFFER_ID,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + user.accessToken,
        },
        body: JSON.stringify({
          offerId: offer.offerId,
          userId: user.id,
          status: status
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        if (data.result.success) {
          const updatedOfferList = offerList.map((item) => {
            if (item.offerId === offer.offerId) {
              return {
                ...item,
                status: status,
              };
            }
            return item;
          });
          setOfferList(updatedOfferList);
        }
        let temp = [...alertList];
        temp.push({ status: "success", text: data.result.message });
        setAlertList([...temp]);
       
        return data;
      })
      .catch((error) => {
        let temp = [...alertList];
        temp.push({ status: "error", text: "Offer Change Status Fail" });
        setAlertList([...temp]);
        setModal(true);
        console.log(error);
      });
  }

  const handlePropertyChange = (propertyName, value) => {
    setUpdatedPropertyValues((prevValues) => ({
      ...prevValues,
      [propertyName]: value,
    }));
  };

  function UpdateOffer(offer) {
    const updatedProperties = offer.properties.map((property) => ({
      ...property,
      value: updatedPropertyValues[property.propertyName] || property.value,
    }));

    let pinCodeValue;
    updatedProperties.forEach((property) => {
      if (property.propertyName === pinCodeColumns[0]) {
        pinCodeValue = property.value;
      }
    }); 

    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPDATE_OFFER, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
        body: JSON.stringify({
          offerId: offer.offerId,
          pinCode: pinCodeValue,
          userId: JSON.parse(localStorage.getItem("user")).id,
          companyId: offer.companyId,
          isAvailable: offer.offerIsAvailable,
          status: offer.offerStatus,
          offerProperties: updatedProperties
        }),
      }
    )
      .then((res) => {
        if(res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        setModal(false);
        
        let temp = [...alertList];
        temp.push({ status: "success", text: data.result.message });
        setAlertList([...temp]);

        fetchOffer();
        return data;
      })
      .catch((error) => {
        let temp = [...alertList];
        temp.push({ status: "error", text: "Offer Update Fail" });
        setAlertList([...temp]);
        console.log(error);
      });
  }  

  // function fetchOfferUpdate() {
  //   fetchOffer();
  //   setWarning(false);
  //   setPinCode("");
  // }
  return (
    <>
      {loadingPage && (
        <div className="load">
          <img src={loadingGif} alt="loading" />
          <label>{langFile.pages.Search.loadWarning}</label>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          position: "absolute",
          right: "1vw",
          height: "min-content",
          bottom: "0vh",
        }}
      >
        {tempArr.map((item, index) => {
          return (
            <Alert
              index={index}
              key={index}
              time={5000}
              status={item.status}
              text={item.text}
            ></Alert>
          );
        })}
      </div>
      <div className="searchOffer-container">
        <div className="navigation-container">

          <select
            name="selectOfferTemplate"
            id="selectOfferTemplate"
            onChange={(event) => setSelectedOfferTemplate(event.target.value)}
            defaultValue=""
          >
            <option value="">
            {langFile.pages.AllOffer.All}
            </option>
            {offerTemplate.map((item, index) => (
              <option key={index} value={item.desc}>
                {item.desc}
              </option>
            ))}
          </select>
          
          <input
            id="searchOffer"
            type="search"
            placeholder="Search for PIN code"
            required
            onChange={(e) => {
              setPinCode(e.target.value);
            }}
          />
          <img
            alt="search"
            className="search-ico"
            src={search_ico}
            onClick={fetchOffer}
          />
        </div>
        <div className="card-container">
          {offer.offers && offer.offers.length > 0 ? (
            <table>
              <thead>
                <tr>
                  
                  <th>{langFile.pages.SearchOffer.AdherenceToPolicy}</th>
                  {offerTitles.map((title, titleIndex) => (
                  <th key={"title" + titleIndex}>
                          {title}
                  </th>
                      ))}
                  <th>{langFile.pages.SearchOffer.Situation}</th>
                  <th>{langFile.pages.SearchOffer.Description}</th>
                  
                </tr>
              </thead>
              <tbody>
                {offer.offers.map((offer, offerIndex) =>
                  offer.offerStatus !== "Deleted" ? (
                    <tr key={offerIndex}>
                      
                      <td
                        style={{
                          color: !offer.offerIsAvailable ? "#C53333" : "",
                        }}
                      >
                        <button
                          onClick={() => toggleModal(offer)}
                          // onClick={() => {
                          //   ChangeActivityById(offerIndex, "Deleted");
                          // }}
                        >
                          <img
                            alt="edit"
                            src={edit_ico}
                            className="editIco"
                          ></img>
                        </button>
                        {!offer.offerIsAvailable ? "Verildi" : "Boşta"}
                      </td>
                      {offerTitles.map((title, titleIndex) => (
                        <td key={"title" + titleIndex}>
                                
                                {offer.properties.find(property => property.propertyName === title)?.value}
                        </td>
                            ))}
                      <td>
                        {/* <div className="chart-type-toggle">
                          <label className="switch">
                            <input
                              id="isAvailableCheckBox"
                              type="checkbox"
                              checked={offer.offerStatus === "Active"}
                              onChange={(event) =>
                                HandleCheckboxChange(event, offerIndex)
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div> */}
                        {offer.offerStatus}
                      </td>
                      <td>{offer.desc}</td>
                      
                    </tr>
                  ) : null
                )}
              </tbody>
            </table>
          ) : null}
          {offer.productNo && offer.companyName && offer.templateName ? (
            <table>
              <thead>
                <tr>
                  <th>{langFile.pages.SearchOffer.ProductNo}</th>
                  <th>{langFile.pages.SearchOffer.CompanyName}</th>
                  <th>{langFile.pages.SearchOffer.TemplateName}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{offer.productNo}</td>
                  <td>{offer.companyName}</td>
                  <td>{offer.templateName}</td>
                </tr>
              </tbody>
            </table>
          ): warning === true ? (
            <div>
              <span style={{color: "red", fontSize:".9vw"}}>{langFile.pages.SearchOffer.PackageNotFound}</span>
            </div>
          ): null}
        </div>
      </div>
      {modal && selectedOfferForEdit && (
          <div className="modal">
            <div className="overlay"></div>
            <div className="modal-content">
              <button className="close-modal" onClick={toggleModal}>
                <img className="closeIco" alt="closeModal" src={close_ico} ></img>
              </button>
              <h2>{langFile.pages.AllOffer.ModalHeader}</h2>
              <table>
              <thead>
                <tr>
                  
                  <th>{langFile.pages.SearchOffer.AdherenceToPolicy}</th>
                  {offerTitles.map((title, titleIndex) => (
                  <th key={"title" + titleIndex}>
                          {title}
                  </th>
                      ))}
                  <th>{langFile.pages.SearchOffer.Situation}</th>
                  <th>{langFile.pages.SearchOffer.Description}</th>
                </tr>
              </thead>
              <tbody>
                    <tr>
                      
                      <td style={{color:!selectedOfferForEdit.offerIsAvailable?"#C53333":""}}>
                        {!selectedOfferForEdit.offerIsAvailable ? "Verildi" : "Boşta"}
                      </td>
                      {offerTitles.map((title, titleIndex) => (
                        <td key={"title" + titleIndex}>
                                <input 
                                type="text" 
                                className="newInput" 
                                placeholder={selectedOfferForEdit.properties.find(property => property.propertyName === title)?.value}
                                onChange={(e) => handlePropertyChange(title, e.target.value)}
                                 />
                                
                        </td>
                            ))}
                      <td>
                        <div className="chart-type-toggle">
                          <label className="switch">
                            <input id="isAvailableCheckBox"
                              type="checkbox"
                              checked={selectedOfferForEdit.offerStatus === "Active"}
                              onChange={HandleCheckboxChangeModal}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        {selectedOfferForEdit.offerStatus}</td>
                        <td>{selectedOfferForEdit.desc}</td>
                        
                
                    </tr>
              </tbody>
            </table>
            <div className="buttons">
                <button className="deleteBtn" onClick={() => handleDelete(selectedOfferForEdit)}>{langFile.pages.AllOffer.Delete}</button>
                <button className="saveBtn" onClick={() => {setShouldHandleCheckboxChange(false); UpdateOffer(selectedOfferForEdit)}}>{langFile.pages.AllOffer.Save}</button>
            </div>
            </div>
          </div>
        )}
    </>
  );
}