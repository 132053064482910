import React, { useContext, useEffect, useState } from "react";
import "../styles/Login.css";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../assets/logo-3.png";
import pages_ico from "../assets/mentorSigortaLogo.png";
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import { LoginContext } from "../context/LoginContext";

export default function Login() {
  const {
    SignInConfirm,
    loginEmail,
    setLoginEmail,
    loginPassword,
    setLoginPassword,
    importLoader,
    setImportLoader,
    loginError,
    setLoginError,
    tooManyRequest,
    setTooManyRequest,
  } = useContext(LoginContext);

  const navigate = useNavigate();
  const [local, setLocal] = useState("Turkish");
  const [langFile, setLangFile] = useState(local === "English" ? en : tr);

  useEffect(() => {
    setLangFile(local === "English" ? en : tr);
    localStorage.setItem("language", local);
  }, [local]);

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      SignInConfirm(loginEmail, loginPassword);
    }
  }

  function ForgotPassword() {
    navigate("/resetPassword");
  }
  return (
    <div className="login-parent">
      <div className="container">
        <label>{langFile.pages.Login.header1}</label>
        <label>{langFile.pages.Login.header2}</label>
        <div className="container-2">
          <img alt="pages_ico" src={pages_ico} className="login-pages-img" />
          <div className="form-container">
            <input
              className="login-input"
              type="text"
              placeholder={langFile.pages.Login.input1}
              id="email"
              onChange={(event) => setLoginEmail(event.target.value)}
              onKeyDown={handleKeyPress}
              autoComplete="on"
            />
            <input
              className="login-input"
              type="password"
              placeholder={langFile.pages.Login.input2}
              id="password"
              onChange={(event) => setLoginPassword(event.target.value)}
              onKeyDown={handleKeyPress}
              autoComplete="on"
            />
            {tooManyRequest === true ? (
              <label className="errorLabel">
                Çok sayıda başarısız giriş denemesi gerçekleşti. Lütfen bir süre
                bekleyip tekrar deneyin.
              </label>
            ) : null}
            {loginError === true ? (
              <label className="errorLabel">
                {langFile.pages.Login.warning}
              </label>
            ) : null}
            <button
              onClick={() => SignInConfirm(loginEmail, loginPassword)}
              style={{ pointerEvents: importLoader === true ? "none" : "all" }}
            >
              {importLoader === true ? (
                <div className="login-loader"></div>
              ) : (
                langFile.pages.Login.button1
              )}
            </button>
            <p onClick={ForgotPassword}>{langFile.pages.Login.link1}</p>
          </div>
        </div>
        {/* <img alt="pages_ico" src={logo} className="login-exp-logo" /> */}
        <select
          style={{ alignSelf: "flex-end" }}
          name="language"
          id="language"
          onChange={(event) => setLocal(event.target.value)}
          defaultValue="Turkish"
        >
          <option value={"Turkish"}>Turkish</option>
          <option value={"English"}>English</option>
        </select>
      </div>
    </div>
  );
}
