import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Upload from "./pages/Upload";
import Login from "./pages/Login";
import Validation from "./pages/Validation";
import Table from "./pages/Table";
import Search from "./pages/Search";
import AllData from "./pages/AllData";
import NewProduct from "./pages/NewProduct";
import NewUser from "./pages/NewUser";
import Layout from "./layout/Layout";
import Dashboard from "./pages/Dashboard";
import ListAllUser from "./pages/ListAllUser";
import NewCompany from "./pages/NewCompany";
import AllProduct from "./pages/AllProduct";
import AllCompany from "./pages/AllCompany";
import UploadOffer from "./pages/UploadOffer";
import TableOffer from "./pages/TableOffer";
import AllOffer from "./pages/AllOffer";
import SearchPackage from "./pages/SearchPackage";
import Logs from "./pages/Logs";
import MailConfig from "./pages/MailConfig";
import ResetPassword from "./pages/ResetPassword";
import NewPassword from "./pages/NewPassword";
import RecaptchaProvider from "./context/recaptchaContext";
import LoginContextProvider from "./context/LoginContext";

export default function Routing() {
  useEffect(() => {
    if (localStorage.getItem("language") === null) {
      localStorage.setItem("language", "Turkish");
    }
    if (localStorage.getItem("thema") === null) {
      localStorage.setItem("thema", "light");
    }
  }, []);
  return (
    <BrowserRouter>
      <RecaptchaProvider>
        <LoginContextProvider>
          <Routes>
            <Route
              path="newUser"
              element={
                <Layout>
                  {" "}
                  <NewUser></NewUser>
                </Layout>
              }
            />
            <Route
              path="newCompany"
              element={
                <Layout>
                  {" "}
                  <NewCompany></NewCompany>
                </Layout>
              }
            />
            <Route
              path="dashboard"
              element={
                <Layout>
                  <Dashboard></Dashboard>
                </Layout>
              }
            />
            <Route
              path="upload"
              element={
                <Layout>
                  <Upload></Upload>
                </Layout>
              }
            />
            <Route
              path="table"
              element={
                <Layout>
                  <Table></Table>
                </Layout>
              }
            />
            <Route
              path="search"
              element={
                <Layout>
                  <Search></Search>
                </Layout>
              }
            />
            <Route
              path="allData"
              element={
                <Layout>
                  <AllData></AllData>
                </Layout>
              }
            />
            <Route
              path="newProduct"
              element={
                <Layout>
                  <NewProduct></NewProduct>
                </Layout>
              }
            />
            <Route
              path="allUser"
              element={
                <Layout>
                  <ListAllUser></ListAllUser>
                </Layout>
              }
            />
            <Route
              path="allProduct"
              element={
                <Layout>
                  <AllProduct></AllProduct>
                </Layout>
              }
            />
            <Route
              path="allCompany"
              element={
                <Layout>
                  <AllCompany></AllCompany>
                </Layout>
              }
            />
            <Route
              path="uploadOffer"
              element={
                <Layout>
                  <UploadOffer></UploadOffer>
                </Layout>
              }
            />
            <Route
              path="tableOffer"
              element={
                <Layout>
                  <TableOffer></TableOffer>
                </Layout>
              }
            />
            <Route
              path="allOffer"
              element={
                <Layout>
                  <AllOffer></AllOffer>
                </Layout>
              }
            />
            <Route
              path="logs"
              element={
                <Layout>
                  <Logs></Logs>
                </Layout>
              }
            />
            <Route
              path="searchPackage"
              element={
                <Layout>
                  <SearchPackage></SearchPackage>
                </Layout>
              }
            />
            <Route
              path="mailConfig"
              element={
                <Layout>
                  <MailConfig></MailConfig>
                </Layout>
              }
            />
            <Route
              path="resetPassword"
              element={<ResetPassword></ResetPassword>}
            />
            <Route path="newPassword" element={<NewPassword></NewPassword>} />
            <Route path="/" element={<Login></Login>} />
            <Route path="validation" element={<Validation></Validation>} />
          </Routes>
        </LoginContextProvider>
      </RecaptchaProvider>
    </BrowserRouter>
  );
}
