import React, { useEffect, useState } from "react";
import "./Card.css";
import user_photo from "../../assets/user.png";
import upArrow from "../../assets/up-arrow.png";
import downArrow from "../../assets/down-arrow.png";
import edit_ico from "../../assets/edit.png";
import email_ico from "../../assets/email.png";
import phone_ico from "../../assets/phone.png";
import point_ico from "../../assets/point.png";
import delete_ico from "../../assets/delete.png";
import Alert from "../Alert/Alert";
import LogOutEndPoint from "../LogOutEndPoint";
import { useNavigate } from "react-router-dom";

export default function Card(props) {
  const [alertList, setAlertList] = useState([]);
  const [tempArr, setTempArr] = useState([...alertList]);
  const navigate = useNavigate();

  useEffect(() => {
    setTempArr(alertList);
  }, [alertList]);
  const [wide, setWide] = useState(true);
  function handleClickEditButton() {
    props.setToEditUser(props.customer);
  }
  function DeleteUser() {
    const user = JSON.parse(localStorage.getItem("user"));

    fetch(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_DELETE_USER, {
      method: "POST",
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + user.accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: props.customer.id,
        userId: user.id,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        props.setDeleteTrigger(!props.deleteTrigger);
        let temp = [...alertList];
        temp.push({ status: "success", text: data.messages });
        setAlertList([...temp]);
        return data;
      })
      .catch((error) => {
        let temp = [...alertList];
        temp.push({ status: "error", text: "Data Delete Failed" });
        setAlertList([...temp]);
        console.log(error);
      });
  }

  return (
    <div className={wide === false ? "card-parent" : "card-parent-side"}>
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          position: "absolute",
          right: "1vw",
          height: "min-content",
          bottom: "0vh",
        }}
      >
        {tempArr.map((item, index) => {
          return (
            <Alert
              index={index}
              key={index}
              time={5000}
              status={item.status}
              text={item.text}
            ></Alert>
          );
        })}
      </div>
      {wide === false ? (
        <div className="card-container">
          <div className="profile-photo-parent">
            <img
              alt="profile_photo"
              src={user_photo}
              style={{ width: "100%", height: "100%", borderRadius: "50%" }}
            ></img>
          </div>
          <div className="text-area">
            <label>
              {props.customer.firstName + " " + props.customer.lastName}
            </label>
            <label>{props.customer.username}</label>
          </div>
          <label
            className={
              props.customer.isActive === true
                ? "online-color"
                : "offline-color"
            }
          >
            {props.customer.isActive === true ? "Online" : "Offline"}
          </label>
        </div>
      ) : (
        <div className="card-container">
          <div className="left-part">
            <div className="profile-photo-parent">
              <img
                alt="profile_photo"
                src={user_photo}
                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
              ></img>
            </div>

            <label
              className={
                props.customer.isActive === true
                  ? "online-color"
                  : "offline-color"
              }
            >
              {props.customer.isActive === true ? "Online" : "Offline"}
            </label>
          </div>

          <div className="right-part">
            <div className="button-area">
              <button className="delete-user" onClick={handleClickEditButton}>
                <img
                  className="delete-user-ico"
                  alt="editUser"
                  src={edit_ico}
                ></img>
              </button>
              <button
                className="delete-user"
                onClick={() => {
                  DeleteUser();
                }}
              >
                <img
                  alt="delete-user"
                  className="delete-user-ico"
                  src={delete_ico}
                ></img>
              </button>
            </div>

            <div className="text-area">
              <label>
                {props.customer.firstName + " " + props.customer.lastName}
              </label>
              <label>{props.customer.username}</label>
            </div>
            <div className="text-area">
              <img className="icon" alt="point" src={email_ico}></img>

              <label>{props.customer.email}</label>
            </div>
            <div className="text-area">
              <img className="icon" alt="point" src={phone_ico}></img>

              <label>{props.customer.phoneNumber}</label>
            </div>
          </div>
        </div>
      )}

      <div className="arrow-area">
        <button
          onClick={() => {
            setWide(!wide);
          }}
        >
          <img
            className="arrow-ico-userbar"
            alt="arrow-up"
            src={wide === false ? downArrow : upArrow}
          />
        </button>
      </div>
    </div>
  );
}
