import tr from "../../assets/flags/tr.png";

import eng from "../../assets/flags/gb.png";

import "./LangDropdown.css";

import React, { useState } from "react";

export default function LangDropdown({ selected, setSelected }) {
  const [isActive, setIsActive] = useState(false);
  function refreshPage() {
    window.location.reload();
  }
  const languages = [
    {
      lang: "Turkish",

      flag: tr,
    },

    {
      lang: "English",

      flag: eng,
    },
  ];

  return (
    <div className="menu-container">
      <div
        className="menu-trigger"
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <img src={selected === "Turkish" ? tr : eng} alt="flag" />

        {selected}
      </div>

      {isActive && (
        <div className="dropdown-menu">
          <ul>
            {languages.map((option, index) => (
              <div key={index}>
                <li
                  className="dropdownItem"
                  onClick={(e) => {
                    setSelected(option.lang);
                    setIsActive(false);
                    refreshPage();
                  }}
                >
                  <img src={option.flag} alt="flag" />

                  <button>{`${option.lang}`}</button>
                </li>
              </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
