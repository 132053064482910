import React, { useEffect, useState } from "react";
import "../styles/NewCompany.css";
import logo2 from "../assets/chat.png";
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import Alert from "../components/Alert/Alert.js";
import LogOutEndPoint from "../components/LogOutEndPoint";
import { useNavigate } from "react-router-dom";

export default function NewCompany() {
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("");
  const [alertList, setAlertList] = useState([]);
  const [tempArr, setTempArr] = useState([...alertList]);
  const [companyErrorList, setCompanyErrorList] = useState(null)
  const [langFile, setLangFile] = useState(en);
  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);
  useEffect(() => {
    setTempArr(alertList);
  }, [alertList]);

  
  function handleKeyPress(event) {
    if (event.key === "Enter") {
      AddCompany();
    }
  }


  function AddCompany() {
    fetch(process.env.REACT_APP_BASE_URL+process.env.REACT_APP_ADD_COMPANY, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
      body: JSON.stringify({
        userId: JSON.parse(localStorage.getItem("user")).id,
        name: companyName
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        let temp = [...alertList];
        temp.push({ status: "success", text: data.message });
        setAlertList([...temp]);
        setCompanyErrorList(null)
        return data;
      })
      .catch((error) => {
        let temp = [...alertList];
        temp.push({ status: "error", text: "Add Company Failed" });
         error.json().then(
           function(error){
             console.log(error)
             setCompanyErrorList(error)
           }
         )
        setAlertList([...temp]);
        console.log(error);
      });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          position: "absolute",
          right: "1vw",
          height: "min-content",
          bottom: "0vh",
        }}
      >
        {tempArr.map((item, index) => {
          return (
            <Alert
              index={index}
              key={index}
              time={5000}
              status={item.status}
              text={item.text}
            ></Alert>
          );
        })}
      </div>

      <div className="parent-new-company">
        <div className="formbold-main-wrapper">
          <div className="formbold-form-wrapper">
            <div className="form-parent">
              <div className="formbold-form-title">
                <h2 style={{ textAlign: "left" }}>
                  {langFile.pages.NewCompany.header3}
                </h2>
                <p>{langFile.pages.NewCompany.header4}</p>
              </div>
              <div className="formbold-input-flex">
                <div className="formbold-mb-3">
                  <label htmlFor="company" className="formbold-form-label">
                    {langFile.pages.NewCompany.companyName}
                  </label>
                  <input
                    type="text"
                    name="company"
                    id="company"
                    className="formbold-form-input"
                    value={companyName}
                    placeholder=""
                    onChange={(event) => setCompanyName(event.target.value)}
                    onKeyDown={handleKeyPress}
                  />
                  {companyErrorList !== null &&
                    companyErrorList.errors.name
                      ? companyErrorList.errors.name.map((err, index) => (
                          <label key={index} className="errorLabel">
                            {err}
                          </label>
                        ))
                      : null}
                </div>
              </div>
              {/*  <div className="formbold-mb-3">      <label htmlFor="address2" className="formbold-form-label">        Street Address Line 2
      </label>      <input type="text" name="address2" id="address2" className="formbold-form-input" />    </div>    <div className="formbold-input-flex">      <div>  
            <label htmlFor="state" className="formbold-form-label"> State/Prvince </label>    
                <input type="text" name="state" id="state" className="formbold-form-input" />      </div>      <div>        <label htmlFor="country" className="formbold-form-label"> Country </label>        <input type="text" name="country" id="country" className="formbold-form-input" />      </div>    </div>    <div className="formbold-input-flex">      <div>        <label htmlFor="post" className="formbold-form-label"> Post/Zip code </label>        <input type="text" name="post" id="post" className="formbold-form-input" />      </div>      <div>        <label htmlFor="area" className="formbold-form-label"> Area Code </label>        <input type="text" name="area" id="area" className="formbold-form-input" />      </div>    </div> */}
              <button
                type="button"
                className="formbold-btn"
                onClick={AddCompany}
              >
                {langFile.pages.NewCompany.button1}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
