import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import Alert from "../components/Alert/Alert";
import "../styles/NewUser.css";
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import LogOutEndPoint from "../components/LogOutEndPoint";
import { useNavigate } from "react-router-dom";
import loadingGif from "../assets/loading3.gif";

export default function NewUser() {
  const [langFile, setLangFile] = useState(en);
  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);

  const [alertList, setAlertList] = useState([]);
  const [tempArr, setTempArr] = useState([...alertList]);

  const [customerErrorList, setCustomerErrorList] = useState(null);

  const [loadingPage, setLoadingPage] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedUserType, setSelectedUserType] = useState("Admin");
  const navigate = useNavigate();

  function AddUser() {
    setLoadingPage(true);
    fetch(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_REGISTER, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
      body: JSON.stringify({
        firstname: firstName,
        lastname: lastName,
        username: username,
        email: email,
        phoneNumber: phoneNumber,
        privilege: selectedUserType,
        password: password,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        let temp = [...alertList];
        temp.push({ status: "success", text: data.result.message });
        setAlertList([...temp]);
        setCustomerErrorList(null);
        setLoadingPage(false);
        return data;
      })
      .catch((error) => {
        let temp = [...alertList];
        temp.push({ status: "error", text: "Add Customer Failed" });
        error.json().then(function (error) {
          console.log(error);
          setCustomerErrorList(error);
        });
        setAlertList([...temp]);
        setLoadingPage(false);
        console.log(error);
      });
  }

  useEffect(() => {
    setTempArr(alertList);
  }, [alertList]);

  const handlePhoneNumberChange = (event) => {
    let formattedNumber = event.target.value.replace(/\D/g, ""); // Sadece rakamları allmak için yazıldı
    formattedNumber = formattedNumber.slice(0, 10);

    let formattedPhoneNumber = "";
    for (let i = 0; i < formattedNumber.length; i++) {
      if (i === 3 || i === 6 || i === 8) {
        formattedPhoneNumber += "-";
      }
      formattedPhoneNumber += formattedNumber[i];
    }

    setPhoneNumber(formattedPhoneNumber.replace(/-/g, " "));
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        {loadingPage && (
          <div className="load">
            <img src={loadingGif} alt="loading" />
            <label>{langFile.pages.AllData.loadingLabel}</label>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            position: "absolute",
            right: "1vw",
            height: "min-content",
            bottom: "0vh",
          }}
        >
          {tempArr.map((item, index) => {
            return (
              <Alert
                index={index}
                key={index}
                time={5000}
                status={item.status}
                text={item.text}
              ></Alert>
            );
          })}
        </div>
        <div className="parent-new-user">
          <div className="formbold-main-wrapper">
            <div className="formbold-form-wrapper">
              <form>
                <div className="formbold-form-title">
                  <h2 style={{ textAlign: "left" }}>
                    {langFile.pages.NewCustomer.header3}
                  </h2>
                  <p>{langFile.pages.NewCustomer.header4}</p>
                </div>
                <div className="formbold-input-flex">
                  <div>
                    <label htmlFor="firstname" className="formbold-form-label">
                      {langFile.pages.NewCustomer.header5}
                    </label>
                    <input
                      type="text"
                      name="firstname"
                      id="firstname"
                      className="formbold-form-input"
                      onChange={(event) => setFirstName(event.target.value)}
                    />
                    {customerErrorList !== null &&
                    customerErrorList.errors.firstname
                      ? customerErrorList.errors.firstname.map((err, index) => (
                          <label key={index} className="errorLabel">
                            {err}
                          </label>
                        ))
                      : null}
                  </div>
                  <div>
                    <label htmlFor="lastname" className="formbold-form-label">
                      {langFile.pages.NewCustomer.header6}
                    </label>
                    <input
                      type="text"
                      name="lastname"
                      id="lastname"
                      className="formbold-form-input"
                      onChange={(event) => setLastName(event.target.value)}
                    />
                    {customerErrorList !== null &&
                    customerErrorList.errors.lastname
                      ? customerErrorList.errors.lastname.map((err, index) => (
                          <label key={index} className="errorLabel">
                            {err}
                          </label>
                        ))
                      : null}
                  </div>
                </div>
                <div className="formbold-input-flex">
                  <div className="formbold-mb-3">
                    <label htmlFor="password" className="formbold-form-label">
                      {langFile.pages.NewCustomer.header7}
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="formbold-form-input"
                      onChange={(event) => setPassword(event.target.value)}
                    />
                    {customerErrorList !== null &&
                    customerErrorList.errors.password
                      ? customerErrorList.errors.password.map((err, index) => (
                          <label key={index} className="errorLabel">
                            {err}
                          </label>
                        ))
                      : null}
                  </div>
                  <div>
                    <label htmlFor="username" className="formbold-form-label">
                      {langFile.pages.NewCustomer.header8}
                    </label>
                    <input
                      type="text"
                      name="username"
                      id="username"
                      className="formbold-form-input"
                      onChange={(event) => setUserName(event.target.value)}
                    />
                    {customerErrorList !== null &&
                    customerErrorList.errors.username
                      ? customerErrorList.errors.username.map((err, index) => (
                          <label key={index} className="errorLabel">
                            {err}
                          </label>
                        ))
                      : null}
                  </div>
                </div>

                <div className="formbold-input-flex">
                  <div>
                    <label
                      htmlFor="phoneNumber"
                      className="formbold-form-label"
                    >
                      {langFile.pages.NewCustomer.header13}
                    </label>
                    <input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      className="formbold-form-input"
                      placeholder="xxx xxx xx xx"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{2}-[0-9]{2}"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      // onChange={(event) => setPhoneNumber(event.target.value)}
                    />
                    {customerErrorList !== null &&
                    customerErrorList.errors.phoneNumber
                      ? customerErrorList.errors.phoneNumber.map(
                          (err, index) => (
                            <label key={index} className="errorLabel">
                              {err}
                            </label>
                          )
                        )
                      : null}
                  </div>

                  <div>
                    <label htmlFor="email" className="formbold-form-label">
                      {langFile.pages.NewCustomer.header9}
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="formbold-form-input"
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    {customerErrorList !== null &&
                    customerErrorList.errors.email
                      ? customerErrorList.errors.email.map((err, index) => (
                          <label key={index} className="errorLabel">
                            {err}
                          </label>
                        ))
                      : null}
                  </div>
                </div>
                <div className="select-user-type-container">
                  <label
                    style={{ marginRight: "37%" }}
                    className="formbold-form-label"
                  >
                    Kullanıcı Tipi
                  </label>
                  <select
                    name="selectUserType"
                    id="selectUserType"
                    onChange={(event) =>
                      setSelectedUserType(event.target.value)
                    }
                    className="user-type-select"
                    value={selectedUserType}
                  >
                    <option value={"Admin"}>Admin</option>
                    <option value={"User"}>User</option>
                  </select>
                </div>

                <button
                  type="button"
                  className="formbold-btn"
                  onClick={() => {
                    AddUser();
                  }}
                >
                  {langFile.pages.NewCustomer.button1}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
