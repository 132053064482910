import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export const LoginContext = createContext();

const LoginContextProvider = (props) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [importLoader, setImportLoader] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [tooManyRequest, setTooManyRequest] = useState(false);
  const navigate = useNavigate();

  async function SignInConfirm(loginEmail, loginPassword) {
    if (!executeRecaptcha) {
      return;
    }
    const recaptchaToken = await executeRecaptcha("login");

    setImportLoader(true);
    fetch(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_LOGIN, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        usernameOrEmail: loginEmail,
        password: loginPassword,
        recaptchaToken: recaptchaToken,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })

      .then((data) => {
        if (data.user !== null) {
          setImportLoader(false);
          data.user["accessToken"] = data.accessToken;

          localStorage.setItem("user", JSON.stringify(data.user));

          //return data;
        }
        if (data.result.message.includes("verification code sent to")) {
          navigate("/validation", {
            state: { email: loginEmail, password: loginPassword },
          });
        }
        if (data.user.privilege === "Admin") {
          navigate("/dashboard");
        } else if (data.user.privilege === "User") {
          navigate("/search");
        }
      })

      .catch((error) => {
        if (error.status === 429) {
          setTooManyRequest(true);
        } else {
          setLoginError(true);
        }

        setImportLoader(false);
      });
  }

  return (
    <LoginContext.Provider
      value={{
        SignInConfirm,
        loginEmail,
        setLoginEmail,
        loginPassword,
        setLoginPassword,
        importLoader,
        setImportLoader,
        loginError,
        setLoginError,
        tooManyRequest,
        setTooManyRequest,
      }}
    >
      {props.children}
    </LoginContext.Provider>
  );
};

export default LoginContextProvider;
