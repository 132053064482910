import React, { useEffect, useState } from "react";
import "./IdentityCard.css";

export default function IdentityCard(props) {
  const [pinCode, setPinCode] = useState("");
  const [pinCodeId, setPinCodeId] = useState(0);
  const [description, setDescription] = useState("");
  const [descriptionId, setDescriptionId] = useState(0);
  const [sortedTitles, setSortedTitles] = useState([]);
  const [descriptionFound, setDescriptionFound] = useState(false);
  const [pinCodeFound, setPindCodeFound] = useState(false);

  useEffect(() => {
    for (let i = 0; i < props.titles.length; i++) {
      if (props.titles[i].name.toLocaleLowerCase().includes("açıklama")) {
        setDescription(props.item[props.titles[i].name]);
        setDescriptionFound(true);
      } 
      // else if (
      //   props.titles[i].name.toLocaleLowerCase().includes("pın kodları")
      // ) {
      //   setPinCode(props.item[props.titles[i].name]);
      //   setPindCodeFound(true);
      // }
    }
  }, [props.titles]);

  // Bu useEffect içinde "titles" dizisini sıralıyoruz.
  useEffect(() => {
    if (props.titles.length !== 0) {
      props.titles.sort((a, b) => {
        const aName = a.name;
        const bName = b.name;

        const aLower = aName.toLowerCase();
        const bLower = bName.toLowerCase();

        if (
          aLower.includes("poliçe no") ||
          aLower.includes("pol no") ||
          bLower.includes("poliçe no") ||
          bLower.includes("pol no")
        ) {
          // "poliçe no" veya "pol no" içeren başlıkları ilk sıraya yerleştiriyoruz.
          return (
            (aLower.includes("poliçe no") || aLower.includes("pol no")
              ? 0
              : 1) -
            (bLower.includes("poliçe no") || bLower.includes("pol no") ? 0 : 1)
          );
        } else if (
          aLower.includes("tckn") ||
          aLower.includes("kimlik") ||
          bLower.includes("tckn") ||
          bLower.includes("kimlik")
        ) {
          // "tckn" veya "kimlik" içeren başlıkları ikinci sıraya yerleştiriyoruz.
          return (
            (aLower.includes("tckn") || aLower.includes("kimlik") ? 0 : 1) -
            (bLower.includes("tckn") || bLower.includes("kimlik") ? 0 : 1)
          );
        } else if (
          aLower.includes("sigortalı") ||
          aLower.includes("sig. ettiren") ||
          bLower.includes("sigortalı") ||
          bLower.includes("sig. ettiren")
        ) {
          return (
            (aLower.includes("sigortalı") || aLower.includes("sig. ettiren")
              ? 0
              : 1) -
            (bLower.includes("sigortalı") || bLower.includes("sig. ettiren")
              ? 0
              : 1)
          );
        } else if (aLower.includes("tar") || bLower.includes("tar")) {
          // "tar" içeren başlıkları üçüncü sıraya yerleştiriyoruz.
          return (
            (aLower.includes("tar") ? 0 : 1) - (bLower.includes("tar") ? 0 : 1)
          );
        } else {
          // Diğer başlıkları en sona yerleştiriyoruz.
          return aName.localeCompare(bName);
        }
      });
      setSortedTitles(props.titles);
    }
  }, [props.titles]);

  useEffect(() => {
    for (let i = 0; i < props.data.productProperties.length; i++) {
      if (
        props.data.productProperties[i].name
          .toLocaleLowerCase()
          .includes("açıklama")
      ) {
        setDescriptionId(props.data.productProperties[i].propertyId);
      }
      // else if (
      //   props.data.productProperties[i].name
      //     .toLocaleLowerCase()
      //     .includes("pın kodları")
      // ) {
      //   setPinCodeId(props.data.productProperties[i].propertyId);
      // }
    }
  }, [props.data.productProperties]);


  function Update() {
    // fetch(
    //   process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UDATE_PRODUCT,
    //   {
    //     method: "PUT",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Accept: "application/json",
    //       Authorization:
    //         "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
    //     },
    //     body: JSON.stringify({
    //       productCommand: {
    //         id: props.data.id,
    //         templateId: props.data.template.id,
    //         userId: props.data.user.id,
    //         companyId: props.data.company.id,
    //         name: "string",
    //         productCode: props.data.productCode,
    //         isActive: true,
    //       },
    //       propertiesToAddProductCommand: [
    //         {
    //           propertyId: descriptionId,
    //           value: {
    //             value: description,
    //           },
    //         },
    //       ],
    //     }),
    //   }
    // )
    //   .then((res) => {
    //     if (res.ok) {
    //       return res.json();
    //     }
    //     throw res;
    //   })
    //   .then((data) => {
    //     props.setSearchTrigger(props.searchTrigger);
    //     let temp = [...props.alertList];
    //     temp.push({ status: "success", text: "Product Update Successful" });
    //     props.setAlertList([...temp]);
    //     return data;
    //   })
    //   .catch((error) => {
    //     let temp = [...props.alertList];
    //     temp.push({ status: "error", text: "Product Update Fail" });
    //     props.setAlertList([...temp]);
    //     console.log(error);
    //   });
  }
  function checkId(inputString) {
    let temp = "";

    const numericPart = inputString.replace(/\D/g, ""); // Tckn:12345678912 -> 12345678912
    const stringPart = inputString.replace(/\d/g, "");
    if (
      numericPart.length !== 11 ||
      numericPart % 2 !== 0 ||
      numericPart[0] === 0
    ) {
      // mod2 must be zero and tckn is 11 length
      return inputString;
    }

    if (!/^\d+$/.test(numericPart)) {
      //123456erd123 -> false
      // tckn must be contain only numbers
      return inputString;
    }
    let a = 0;
    let b = 0;

    for (let i = 0; i < 9; i++) {
      if (i % 2 === 0) {
        a += parseInt(numericPart[i]);
      } else {
        b += parseInt(numericPart[i]);
      }

      if (i === 8) {
        let result = (a * 7) + (b * 9);
        if (result.toString().slice(-1) !== numericPart.toString().slice(-2, -1)) {

          return inputString;
        }
      }
    }

    for (let i = 0; i < numericPart.length; i++) {
      if (i >= 3 && i <= 8) {
        temp += "*";
      } else {
        temp += numericPart[i];
      }
    }

    return stringPart + temp;
  }

  return (
    <>
      <div
        style={{
          backgroundColor:
            props.item["isActive"] === "true" ? "#cce3d0" : "#f5aeae",
        }}
        className={props.selectedProductToUpdate === props.data ? "active-identity-card identity-card-container" : "identity-card-container"}
        onClick={() => { props.setSelectedProductToUpdate(props.data) }}
      >
        {sortedTitles.map((title, colIndex) =>
          title.filter === true ? (
            <div key={colIndex} className="row-container">
              <label>{title.name}</label>
              <label
                className={
                  title.name.toLowerCase().includes("sigortalı") ||
                    title.name.toLowerCase().includes("pol") ||
                    title.name.toLowerCase().includes("kimlik") ||
                    title.name.toLowerCase().includes("tckn") ||
                    title.name.toLowerCase().includes("sig. ettiren")
                    ? "importantLabel"
                    : ""
                }
              >
                {title.name.toLowerCase().includes("kimlik") ||
                  title.name.toLowerCase().includes("tckn") ? (
                  <>{checkId(props?.item[title.name])}</>
                ) : (
                  <>{props?.item[title.name]}</>
                )}
              </label>
            </div>
          ) : null
        )}

        <div className="description-container">
          {descriptionFound && (
            <React.Fragment>
              <textarea
                id="description"
                name="description"
                placeholder="description"
                value={description !== null ? description : ""}
                rows="1"
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              ></textarea>
              <button
                onClick={() => {
                  Update();
                }}
                className="update-button"
              >
                Update
              </button>
            </React.Fragment>

          )}

          {/* {pinCodeFound && (
            <textarea
              id="releated-pin-code"
              name="releated-pin-code"
              placeholder="releated pin code"
              value={pinCode !== null ? pinCode : ""}
              rows="1"
              onChange={(e) => {
                setPinCode(e.target.value);
              }}
            ></textarea>
          )} */}
        </div>
      </div>

    </>
  );
}
