import React, { useEffect, useState } from "react";
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import Card from "../components/Card/Card";
import "../styles/ListAllUser.css";
import EditUser from "../components/EditUser/EditUser";
import Alert from "../components/Alert/Alert";
import LogOutEndPoint from "../components/LogOutEndPoint";
import { useNavigate } from "react-router-dom";

export default function ListAllUser() {
  const navigate = useNavigate();
  const [toEditUser, setToEditUser] = useState(false);
  const [langFile, setLangFile] = useState(en);
  const [deleteTrigger, setDeleteTrigger] = useState(false);
  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);
  const [users, setUsers] = useState([]);
  const [alertList, setAlertList] = useState([]);
  const [tempArr, setTempArr] = useState([...alertList]);

  useEffect(() => {
    setTempArr(alertList);
  }, [alertList]);
  useEffect(() => {
    fetch(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_USER_GET_ALL, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        // console.log("AAAAAAAAAAAAAA",data)
        setUsers(data.users);
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }, [deleteTrigger]);

  return (
    <div className="list-all-user-parent">
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          position: "absolute",
          right: "1vw",
          height: "min-content",
          bottom: "0vh",
        }}
      >
        {tempArr.map((item, index) => {
          return (
            <Alert
              index={index}
              key={index}
              time={5000}
              status={item.status}
              text={item.text}
            ></Alert>
          );
        })}
      </div>
      <div className={`edit-user-container ${toEditUser ? "" : "hidden"}`}>
        <EditUser
          alertList={alertList}
          setAlertList={setAlertList}
          deleteTrigger={deleteTrigger}
          setDeleteTrigger={setDeleteTrigger}
          setToEditUser={setToEditUser}
          toEditUser={toEditUser}
        ></EditUser>
      </div>

      <div
        style={{ width: toEditUser ? "70%" : "100%" }}
        className="parent-small-profile-card"
      >
        {users.map((user, index) => (
          
            <Card
              deleteTrigger={deleteTrigger}
              setDeleteTrigger={setDeleteTrigger}
              key={index}
              customer={user}
              online={true}
              setToEditUser={setToEditUser}
            ></Card>
          
        ))}
      </div>
    </div>
  );
}
