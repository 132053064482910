import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import "../styles/NewProduct.css";
import Alert from "../components/Alert/Alert";
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import SelectProperties from "../components/NewProductComponents/SelectProperties";
import PropertyPairing from "../components/NewProductComponents/PropertyPairing";
import OfferSelectionContainer from "../components/NewProductComponents/OfferSelectionContainer";
import LogOutEndPoint from "../components/LogOutEndPoint";
import { useNavigate } from "react-router-dom";

export default function NewProduct() {
  const [alertList, setAlertList] = useState([]);
  const [langFile, setLangFile] = useState(en);
  const [selectedOffersList, setSelectedOffersList] = useState([])
    useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);
  useEffect(() => {
    setTempArr(alertList);
  }, [alertList]);

  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);
  const [productName, setProductName] = useState("");
  const [productNo, setProductNo] = useState(undefined);
  const [tempArr, setTempArr] = useState([...alertList]);
  const [checkedPropertyCounter, setCheckedPropertyCounter] = useState(1);
  const [polNo, setPolNo] = useState(-1)
  const [başTar, setBaşTar] = useState(-1)
  const [bitTar, setBitTar] = useState(-1)
  const [iptIst, setIptIst] = useState(-1)

  useEffect(() => {
    fetch(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_PROPERTY, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        let temp = [];
        for (let i = 0; i < data.properties.length; i++) {
          if (["Pol No", "Y.", "İpt / İst"].includes(data.properties[i].name)) {
            let obj = data.properties[i];
            obj["check"] = false;
            obj["isNew"] = false;
            temp.push(obj);
          } else {
            let obj = data.properties[i];
            obj["check"] = false;
            obj["isNew"] = false;
            temp.push(obj);
          }
        }
        setProperties(temp);
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let counter = 0;
    properties.map((item, index) => {
      if (item.check) {
        counter++;
      }
      else if (polNo == index) setPolNo(-1);
      else if (başTar == index) setBaşTar(-1);
      else if (bitTar == index) setBitTar(-1);
      else if (iptIst == index) setIptIst(-1);
    }
    );
    setCheckedPropertyCounter(counter);
  }, [properties]);

  async function CreateProperties(name, type) {
    return new Promise((resolve) => {
      const user = JSON.parse(localStorage.getItem("user"));
      fetch(
        process.env.REACT_APP_BASE_URL + process.env.REACT_APP_CREATE_PROPERTY,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer " + user.accessToken,
          },
          body: JSON.stringify({
            userId: user.id,
            property: { name: name, type: type },
          }),
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          else if (res.status === 401) {
            LogOutEndPoint(navigate);
          }
          throw res;
        })
        .then((data) => {
          let temp = [...alertList];
          temp.push({ status: "success", text: data.message });
          setAlertList([...temp]);
          return data;
        })
        .then((data) => {
          resolve(data);
          return data;
        })
        .catch((error) => {
          let temp = [...alertList];
          temp.push({ status: "error", text: "Add Property Failed" });
          error.json().then(function (error) {
            console.log(error);
            // setCustomerErrorList(error)
          });
          setAlertList([...temp]);
          console.log(error);
        });
    });
  }
  async function CreateTemplate(propertyList) {
    return new Promise((resolve) => {
      let propertiesListToAddTemplate = [];
      propertyList.map((prop) => {
        propertiesListToAddTemplate.push({
          propertyId: prop.property.id,
        });
      });
      console.log(
        "body",
        JSON.stringify({
          template: { name: productName + "-" + String(productNo) },
          propertiesList: propertiesListToAddTemplate,
        })
      );

      fetch(
        process.env.REACT_APP_BASE_URL + process.env.REACT_APP_CREATE_TEMPLATE,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
          },
          body: JSON.stringify({
            userId: JSON.parse(localStorage.getItem("user")).id,
            template: { name: productName + "-" + String(productNo) },
            propertiesList: propertiesListToAddTemplate,
          }),
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          else if (res.status === 401) {
            LogOutEndPoint(navigate);
          }
          throw res;
        })
        .then((data) => {
          let temp = [...alertList];
          temp.push({ status: "success", text: data.message });
          setAlertList([...temp]);
          return data;
        })
        .then((data) => {
          resolve(data);
          return data;
        })
        .catch((error) => {
          let temp = [...alertList];
          temp.push({ status: "error", text: "Add Product Failed" });
          error.json().then(function (error) {
            console.log(error);
            //  setCustomerErrorList(error)
          });
          setAlertList([...temp]);
          console.log(error);
        });
    });
  }

  async function MatchOfferAndTemplate(templateId){
    return new Promise((resolve) => {

      const user = JSON.parse(localStorage.getItem("user"));

      fetch(
        process.env.REACT_APP_BASE_URL + process.env.REACT_APP_MATCH_OFFER_AND_TEMPLATE,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer " + user.accessToken,
          },
          body: JSON.stringify({
            userId: user.id,
            templateId: templateId,
            offerDescs: selectedOffersList
          }),
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          else if (res.status === 401) {
            LogOutEndPoint(navigate);
          }
          throw res;
        })
        .then((data) => {
          let temp = [...alertList];
          temp.push({ status: "success", text: data.result.message });
          setAlertList([...temp]);
          return data;
        })
        .then((data) => {
          resolve(data);
          return data;
        })
        .catch((error) => {
          let temp = [...alertList];
          temp.push({ status: "error", text: "Add Product Failed" });
          setAlertList([...temp]);
          console.log(error);
        });
    });
  }

  async function handleCreateProduct() {
    try {
      let propList = []
      for (const item of properties) {
        if (item.check && item.isNew) {
          const response = await CreateProperties(item.name, "string");
          propList.push(response)
        } else if (item.check && !item.isNew) {
          propList.push({
            property: {
              id: item.id,
              name: item.name,
              type: "string",
            },
          });
        }
      }
      const responseCreateTemplate = await CreateTemplate(propList);
      const responseMatch = await MatchOfferAndTemplate(responseCreateTemplate.template.id)

    } catch (error) {
      console.error("Hata:", error);
    }
  }



  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          position: "absolute",
          right: "1vw",
          height: "min-content",
          bottom: "0vh",
        }}
      >
        {tempArr.map((item, index) => {
          return (
            <Alert
              index={index}
              key={index}
              time={5000}
              status={item.status}
              text={item.text}
            ></Alert>
          );
        })}
      </div>
      <div className="newProduct-container">
        <div className="metadata-container">
          <input
            onChange={(e) => setProductName(e.target.value)}
            // value={productName}
            type="text"
            id="product-name"
            placeholder={langFile.pages.NewProduct.placeholderProductName}
            required
          />
          <input
            onChange={(e) => setProductNo(e.target.value)}
            // value={productNo}
            type="number"
            id="product-no"
            placeholder={langFile.pages.NewProduct.placeholderProductNo}
            required
          />
        </div>
        <div className="newProduct-sub-container">
          <div className="select-properties-parent">
            <SelectProperties
              properties={properties}
              setProperties={setProperties}
            ></SelectProperties>
          </div>
          {/* <div className="property-pairing-parent">
            <PropertyPairing
              properties={properties}
              başTar={başTar}
              setBaşTar={setBaşTar}
              bitTar={bitTar}
              setBitTar={setBitTar}
              polNo={polNo}
              setPolNo={setPolNo}
              iptIst={iptIst}
              setIptIst={setIptIst}></PropertyPairing>
          </div> */}
          <div className="offer-selection-parent">
            <OfferSelectionContainer selectedOffersList={selectedOffersList} setSelectedOffersList={setSelectedOffersList}
             alertList={alertList} setAlertList={setAlertList} langFile={langFile}></OfferSelectionContainer>
          </div>
        </div>

        <button
          className="create-button"
          onClick={() => {
            handleCreateProduct();
          }}
        >
          {langFile.pages.NewProduct.createProductButton}
        </button>
      </div>
    </>
  );
}
