import React, { useEffect, useState } from "react";
import "./styles/Navigation.css";
import user from "../assets/user-3.png";
import notification from "../assets/notification-bell.png";
import upArrow from "../assets/up-arrow.png";
import downArrow from "../assets/down-arrow.png";
import ThemaMode from "../components/themaToggle/ThemaMode";
import ProfileCard from "../components/ProfileCard/ProfileCard";
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import LangDropdown from "../components/LangDropdown/LangDropdown";

export default function Navigation({ sideBar }) {
  const [profileComp, setProfileComp] = useState(false);
  const url = new URL(window.location.href);
  const path = url.pathname; // URL'nin yolu (path)
  const extension = path.split(".").pop(); // Uzantıyı alma
  const [local, setLocal] = useState(localStorage.getItem("language"));
  const [langFile, setLangFile] = useState(local === "English" ? en : tr);

  useEffect(() => {
    setLangFile(local === "English" ? en : tr);
    localStorage.setItem("language", local);
  }, [local]);
  return (
    <div className="user-bar-parent">
      <div
        style={{ width: sideBar === true ? "84vw" : "96vw" }}
        className="page-style"
      >
        <label className="page-name-label">
          {langFile.pages.PageTitleFromUrl[extension]}
        </label>

        <div className="icons-userbar">
          <LangDropdown selected={local} setSelected={setLocal}></LangDropdown>
          <ThemaMode></ThemaMode>

          {/* <img className="logo-userbar" alt="user" src={notification} />
          <img className="logo-userbar" alt="user" src={user} /> */}
        </div>
        {/* <label style={{color:"black", display:"flex", fontSize:"0.8vw", fontWeight:"normal"}}>{(props.fn).concat(" ").concat(props.ln)}</label> */}
        <label className="user-name-label">
          {JSON.parse(localStorage.getItem("user")).firstname +
            " " +
            JSON.parse(localStorage.getItem("user")).lastname}
        </label>

        <button
          style={{
            border: "none",
            background: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "2vw",
            height: "100%",
          }}
          onClick={() => setProfileComp(!profileComp)}
        >
          <img
            className="arrow-ico-userbar"
            alt="arrow-up"
            src={profileComp === false ? downArrow : upArrow}
            // src={props.profileComp === false ? downArrow : upArrow}
          />
        </button>
      </div>
      <ProfileCard profileComp={profileComp}></ProfileCard>
    </div>
  );
}
