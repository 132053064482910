import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/Table.css";
import Alert from "../components/Alert/Alert";
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import loadingGif from "../assets/loading3.gif";
import { parseISO, parse, format } from "date-fns";
import LogOutEndPoint from "../components/LogOutEndPoint";

export default function Table() {
  const [loadingForLoadMore, setLoadingForLoadMore] = useState(false);
  const [counterForPagination, setCounterForPagination] = useState(1);
  const [checkDataResult, setCheckDataResult] = useState(null);
  const [langFile, setLangFile] = useState(en);
  const [useCalculate, setUseCalculate] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataFromExcel, setDataFromExcel] = useState(
    useLocation().state.dataFromExcel
  );
  const [selectedProduct, setSelectedProduct] = useState(
    JSON.parse(useLocation().state.selectedProduct)
  );
  const [responses, setResponses] = useState("");
  const selectedCompany = JSON.parse(useLocation().state.selectedCompany);
  const [titles, setTitles] = useState([]);
  const [alertList, setAlertList] = useState([]);
  const [tempArr, setTempArr] = useState([...alertList]);
  const [submitStatus, setSubmitStatus] = useState(false);
  const navigate = useNavigate();
  const [maxHeight, setMaxHeight] = useState("3vh");
  const [whiteSpace, setWhiteSpace] = useState("nowrap");
  const [progress, setProgress] = useState(0);
  const dateFormats = ["dd/MM/yyyy", "MM/dd/yyyy"];
  const handleClick = () => {
    setMaxHeight(maxHeight === "3vh" ? "max-content" : "3vh");
    setWhiteSpace(whiteSpace === "nowrap" ? "normal" : "nowrap");
  };

  useEffect(() => {
    setTempArr(alertList);
  }, [alertList]);

  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);

  useEffect(() => {
    //to detect titles
    if (dataFromExcel !== undefined && dataFromExcel.length > 0) {
      let temp = Object.entries(dataFromExcel[0]);
      let arrForTittle = [];
      temp.map((item) => {
        arrForTittle.push({ name: item[0], filter: true }); //eğer filter özelliğini false yaparsanız o kolon filterelenir
      });
      setTitles(arrForTittle);
    }
  }, [dataFromExcel]);

  useEffect(() => {
    if (responses !== "") {
      setLoadingPage(false);
    }
  }, [responses]);

  function isDate(value) {
    for (const format of dateFormats) {
      const d = parse(value, format, new Date());
      if (!isNaN(d)) {
        return true;
      }
    }
    return false;
  }

  function ChangeDayAndMont(date) {
    const parsedDate = date.split("/");
    if (parseInt(parsedDate[0]) > 12) {
      //if parsedDate[0] greater than 12 this is day so do not change
      return parsedDate[0] + "/" + parsedDate[1] + "/" + parsedDate[2];
    }
    return parsedDate[1] + "/" + parsedDate[0] + "/" + parsedDate[2];
  }

  function UpdateDateFormat() {
    const processedData = dataFromExcel.map((row) => {
      const processedRow = {};
      for (const key in row) {
        const cellValue = row[key];
        if (key.toLocaleLowerCase().includes("tar") && isDate(cellValue)) {
          const formattedDate = ChangeDayAndMont(cellValue);
          processedRow[key] = formattedDate;
        } else {
          processedRow[key] = cellValue;
        }
      }
      return processedRow;
    });
    setDataFromExcel(processedData);
  }

  useEffect(() => {
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_TEMPLATE_BY_ID,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
        body: JSON.stringify({
          id: selectedProduct.id,
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        let temp = [...alertList];
        temp.push(
          {
            status: "success",
            text: data.message,
          },
          {
            status: "warning",
            text: 'Tarih Formatınızın "gg/aa/yyyy" şeklinde olduğundan emin olunuz!!!',
          }
        );
        setAlertList([...temp]);
        setSelectedProduct(data);
        return data;
      })
      .catch((error) => {
        let temp = [...alertList];
        temp.push({
          status: "error",
          text: "Get Selected Product Structure Failed",
        });
        error.json().then(function (error) {
          console.log(error);
        });
        setAlertList([...temp]);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (selectedProduct.template) {
      setCheckDataResult(CheckData());
    }
  }, [selectedProduct]);

  function CheckData() {
    // dataFromExcel objesinin içindeki öğelerin key değerlerini al
    const excelKeys = Object.keys(dataFromExcel[0]);

    // selectedProduct objesinin içindeki property isimlerini al
    const propertyNames = selectedProduct.template.properties.map(
      (property) => property.name
    );

    // Key değerlerinin sayısını ve property isimlerinin sayısını döndür
    let result = {
      keyCount: excelKeys.length,
      propertyCount: propertyNames.length,
      status: excelKeys.length === propertyNames.length ? true : false,
      statusForTemplate: true,
    };

    let unmatchedCount2 = 0;
    let objForTemplate = {};
    for (const key of propertyNames) {
      //propertylerin excel datası içinde var mı diye  kontrol ediyor
      if (!excelKeys.includes(key)) {
        objForTemplate[key] = false; // property değeri excel isimleri arasında bulunmuyorsa false olarak işaretle
        unmatchedCount2++;
        result["statusForTemplate"] = false;
      } else {
        objForTemplate[key] = true;
      }
    }

    // Her bir key değerini selectedProduct property isimleriyle karşılaştır
    let unmatchedCount = 0;
    let obj = {};
    for (const key of excelKeys) {
      //excel datasındaki veriler property ler içinde var mı diye  kontrol ediyor
      if (!propertyNames.includes(key)) {
        obj[key] = false; // Key değeri property isimleri arasında bulunmuyorsa false olarak işaretle
        unmatchedCount++;
        result["status"] = false;
      } else {
        obj[key] = true;
      }
    }

    result["includes"] = obj;
    result["includesForTemplate"] = objForTemplate;
    result["propertyNames"] = propertyNames;
    result["dataTitles"] = excelKeys;
    result["unmatchedCount"] = unmatchedCount;

    return result;
  }
  function CreateJson() {
    const user = JSON.parse(localStorage.getItem("user"));
    let arr = [];
    dataFromExcel &&
      dataFromExcel.map((item, rowIndex) => {
        let policy = {
          userId: user.id,
          withProductRules: useCalculate,
          productCommand: {
            templateId: selectedProduct.template.id,
            userId: JSON.parse(localStorage.getItem("user")).id,
            companyId: selectedCompany.id,
            name: "string",
            productCode:
              item["Pol No"] !== undefined && item["Pol No"] !== ""
                ? item["Pol No"]
                : item["Poliçe No"] !== undefined && item["Poliçe No"] !== ""
                ? item["Poliçe No"]
                : "0",
            isActive: false,
          },
          propertiesToAddProductCommand: [],
        };

        selectedProduct.template.properties.map((property, propertyIndex) => {
          let id = property.id;
          let name = property.name;
          let obj = {
            propertyId: String(id),
            value: {
              value: item[name],
            },
          };
          policy.propertiesToAddProductCommand.push(obj);
        });
        if (policy.productCommand.productCode !== 0) {
          arr.push(policy);
        }
      });
    return arr;
  }
  function SendExcel(arr) {
    return new Promise((resolve) => {
      fetch(
        process.env.REACT_APP_BASE_URL + process.env.REACT_APP_CREATE_PRODUCTS,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
          },
          body: JSON.stringify({
            withProductRules: useCalculate,
            createProductCommands: arr,
          }),
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else if (res.status === 401) {
            LogOutEndPoint(navigate);
          }
          throw res;
        })
        .then((data) => {
          console.log("ok");
          let temp = [...alertList];
          // temp.push({ status: "success", text: data.messages });
          temp.push({ status: "success", text: "Data Uplaod Succesfull" });
          setAlertList([...temp]);
          setSubmitStatus(true);
          return data;
        })
        .then((data) => {
          resolve(true);
          return data;
        })
        .catch((error) => {
          console.log("fail");
          console.log(
            "body",
            JSON.stringify({
              withProductRules: useCalculate,
              createProductCommands: arr,
            })
          );
          let temp = [...alertList];
          temp.push({ status: "error", text: "Adding Excel Failed" });
          setAlertList([...temp]);
          // error.json().then(function (error) {
          //   console.log(error);
          // });
          console.log(error);
        });
    });
  }

  // useEffect(() => {
  //   const toggle = document.getElementById("myCheckbox");

  //   toggle.addEventListener("change", function () {
  //     if (this.checked) {
  //       setUseCalculate(true);
  //     } else {
  //       setUseCalculate(false);
  //     }
  //   });

  //   return () => {
  //     toggle.removeEventListener("change", () => {});
  //   };
  // }, []);

  async function handleSubmit() {
    const promisesToAwait = [];
    if (submitStatus === false) {
      setLoadingPage(true);
      let json = CreateJson();
      if (json.length > 250) {
        for (let i = 0; i < json.length; i = i + 250) {
          if (i + 250 < json.length) {
            promisesToAwait.push(await SendExcel(json.slice(i, i + 250)));
          } else {
            promisesToAwait.push(await SendExcel(json.slice(i, json.length)));
          }
          // Her bir işlem tamamlandığında progress'i artır
          const newProgress = ((i + 250) / json.length) * 100;
          setProgress(newProgress);
        }
      } else {
        setProgress(100);
        promisesToAwait.push(await SendExcel(CreateJson()));
      }

      setResponses(await Promise.all(promisesToAwait));
    } else if (submitStatus === true) {
      const container = document.querySelector(".table-container");
      container.style.left = "100vw";
      setTimeout(function () {
        navigate("/upload");
      }, 1000);
    }
  }
  return (
    <>
      {loadingPage && (
        <div className="load">
          <img src={loadingGif} alt="loading" />
          <label>{langFile.pages.AllData.loadingLabel}</label>
          <progress className="progressBar" value={progress} max={100} />
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          position: "absolute",
          right: "1vw",
          height: "min-content",
          bottom: "0vh",
        }}
      >
        {tempArr.map((item, index) => {
          return (
            <Alert
              index={index}
              key={index}
              time={600000}
              status={item.status}
              text={item.text}
            ></Alert>
          );
        })}
      </div>
      <div className="table-container">
        <div className="table-container-header">
          <div className="result-text">
            {checkDataResult !== null ? (
              checkDataResult.status === true ? (
                <label style={{ color: "green" }}>
                  {langFile.pages.Table.resultSuccesText}
                </label>
              ) : (
                <>
                  <label style={{ color: "red" }}>
                    {langFile.pages.Table.resultFailedText}
                  </label>
                  <label style={{ color: "red" }}>
                    {langFile.pages.Table.resultFileTitleNumber}
                    {checkDataResult.keyCount}{" "}
                    {langFile.pages.Table.resultProductTitleNumber}
                    {checkDataResult.propertyCount}
                  </label>
                  {checkDataResult.unmatchedCount !== 0 ? (
                    <label style={{ color: "red" }}>
                      {langFile.pages.Table.resultUnmatchedTitleNumber}
                      {checkDataResult.unmatchedCount}{" "}
                      {langFile.pages.Table.resultUnmatchedTitleRed}
                    </label>
                  ) : null}
                  {checkDataResult.statusForTemplate === true ? (
                    <label style={{ color: "green" }}>
                      {langFile.pages.Table.okForExcelStructure}
                    </label>
                  ) : (
                    <label style={{ color: "red" }}>
                      {selectedProduct.template.properties.map((item, index) =>
                        checkDataResult.includesForTemplate[item.name] === false
                          ? item.name + ", "
                          : null
                      )}
                      {langFile.pages.Table.canNotFindInFile}
                    </label>
                  )}
                </>
              )
            ) : null}
          </div>
          <button onClick={UpdateDateFormat} className="changeDateFormatButton">
            Tarih Formatını Değiştir
            <br /> {"aa/gg/yyyy -> gg/aa/yyyy"}
          </button>

          {/* <div className="calculate-toggle-container">
            <label className="switch">
              <input id="myCheckbox" type="checkbox" defaultChecked />
              <span className="slider round"></span>
            </label>
            <label>
              {useCalculate ? langFile.pages.Table.useRules : langFile.pages.Table.dontUseRules}
            </label>
          </div> */}
        </div>

        <table>
          <thead>
            <tr>
              {/* <th>No</th> Satır numarası sütunu */}
              {titles &&
                titles.map((item, index) =>
                  item.filter === true ? (
                    <th
                      key={index}
                      style={{
                        backgroundColor:
                          checkDataResult?.includes[item.name] === false
                            ? "red"
                            : "",
                      }}
                    >
                      {item.name}
                    </th>
                  ) : null
                )}
            </tr>
          </thead>
          <tbody>
            {dataFromExcel &&
              dataFromExcel
                .slice(0, 200 * counterForPagination)
                .map((item, index) => (
                  <tr
                    key={index}
                    onClick={handleClick}
                    style={{ maxHeight, whiteSpace: whiteSpace }}
                  >
                    {/* <td>{index + 1}</td> Satır numarasını gösteren hücre */}
                    {titles.map((titles, index1) =>
                      titles.filter === true ? (
                        <td key={index1}>{item[titles.name]}</td>
                      ) : null
                    )}
                  </tr>
                ))}
            <tr
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "1vh 0",
                width: "100%!important",
              }}
            >
              <td style={{ borderRadius: "5px", minWidth: "100%" }}>
                <button
                  className="load-more-button"
                  onClick={() => {
                    setLoadingForLoadMore(true);
                    setCounterForPagination(counterForPagination + 1);
                  }}
                >
                  {loadingForLoadMore === true
                    ? // <div className="data-loader"></div>
                      langFile.pages.AllData.loadMoreButton
                    : langFile.pages.AllData.loadMoreButton}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <button className="excel-submit-button" onClick={handleSubmit}>
          {submitStatus
            ? langFile.pages.Table.finishButton
            : langFile.pages.Table.submitButton}
        </button>
      </div>
    </>
  );
}
