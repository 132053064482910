import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/TableOffer.css";
import Alert from "../components/Alert/Alert";
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import loadingGif from "../assets/loading3.gif";
import LogOutEndPoint from "../components/LogOutEndPoint";

export default function TableOffer() {
  const [loadingForLoadMore, setLoadingForLoadMore] = useState(false);
  const [counterForPagination, setCounterForPagination] = useState(1);
  const [checkDataResult, setCheckDataResult] = useState(null);
  const [langFile, setLangFile] = useState(en);

  const [loadingPage, setLoadingPage] = useState(false);

  const dataFromExcel = useLocation().state.dataFromExcel;
  const selectedOffer = useLocation().state.selectedOffer;
  const [selectedOfferDesc, setSelectedOfferDesc] = useState(
    selectedOffer === "$#$#$#$" ? "" : selectedOffer
  );
  const [responses, setResponses] = useState("");

  const selectedCompany = JSON.parse(useLocation().state.selectedCompany);
  const [titles, setTitles] = useState([]);
  const [alertList, setAlertList] = useState([]);
  const [tempArr, setTempArr] = useState([...alertList]);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [selectedOfferCode, setSelectedOfferCode] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setTempArr(alertList);
  }, [alertList]);

  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);

  useEffect(() => {
    //to detect titles
    if (dataFromExcel !== undefined && dataFromExcel.length > 0) {
      let temp = Object.entries(dataFromExcel[0]);
      let arrForTittle = [];
      temp.map((item) => {
        if (!item[0].toLowerCase().includes("empty")) {
          //if empty
          arrForTittle.push({ name: item[0], filter: true }); //eğer filter özelliğini false yaparsanız o kolon filterelenir
        }
      });
      setTitles(arrForTittle);
    }
  }, [dataFromExcel]);

  useEffect(() => {
    if (responses !== "") {
      setLoadingPage(false);
    }
  }, [responses]);
  function CreateJson() {
    const user = JSON.parse(localStorage.getItem("user"));
    let arr = [];
    dataFromExcel &&
      dataFromExcel.map((item, rowIndex) => {
        let offer = {
          userId: user.id,
          desc: selectedOfferDesc,
          pinCode: item[titles[selectedOfferCode].name],
          companyId: selectedCompany.id,
          isAvailable: true,
          status: "Active",
          offerGroups: [],
        };
        titles.map((title, index) => {
          offer.offerGroups.push({
            propertyName: title.name,
            value: item[title.name],
          });
        });
        arr.push(offer);
      });
    return arr;
  }
  function SendExcel(arr) {
    return new Promise((resolve) => {
      fetch(
        process.env.REACT_APP_BASE_URL + process.env.REACT_APP_CREATE_OFFERS,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
          },
          body: JSON.stringify({
            UserId: JSON.parse(localStorage.getItem("user")).id,
            offers: arr,
          }),
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else if (res.status === 401) {
            LogOutEndPoint(navigate);
          }
          throw res;
        })
        .then((data) => {
          if (data.result.success) {
            console.log("ok");
            let temp = [...alertList];
            // temp.push({ status: "success", text: data.messages });
            temp.push({ status: "success", text: "Data Uplaod Succesfull" });
            setAlertList([...temp]);
            setSubmitStatus(true);
          } else {
            console.log("fail");
            let temp = [...alertList];
            temp.push({ status: "error", text: data.result.message });
            setAlertList([...temp]);
            console.log(
              "body",
              JSON.stringify({
                offers: arr,
              })
            );
          }

          return data;
        })
        .then((data) => {
          resolve(true);
          return data;
        })
        .catch((error) => {
          console.log("fail");
          console.log(
            "body",
            JSON.stringify({
              offers: arr,
            })
          );
          let temp = [...alertList];
          temp.push({ status: "error", text: "Adding Excel Failed" });
          setAlertList([...temp]);
          // error.json().then(function (error) {
          //   console.log(error);
          // });
          console.log(error);
        });
    });
  }

  async function handleSubmit() {
    if (selectedOfferDesc && selectedOfferDesc !== "") {
      const promisesToAwait = [];
      if (submitStatus === false) {
        setLoadingPage(true);
        let json = CreateJson();
        if (json.length > 250) {
          for (let i = 0; i < json.length; i = i + 250) {
            if (i + 250 < json.length) {
              promisesToAwait.push(await SendExcel(json.slice(i, i + 250)));
            } else {
              promisesToAwait.push(await SendExcel(json.slice(i, json.length)));
            }
          }
        } else {
          promisesToAwait.push(await SendExcel(json));
        }
        setResponses(await Promise.all(promisesToAwait));
      } else if (submitStatus === true) {
        const container = document.querySelector(".table-offer-container");
        container.style.left = "100vw";
        setTimeout(function () {
          navigate("/uploadOffer");
        }, 1000);
      }
    }
  }

  return (
    <>
      {loadingPage && (
        <div className="load">
          <img src={loadingGif} alt="loading" />
          <label>{langFile.pages.AllData.loadingLabel}</label>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          position: "absolute",
          right: "1vw",
          height: "min-content",
          bottom: "0vh",
        }}
      >
        {tempArr.map((item, index) => {
          return (
            <Alert
              index={index}
              key={index}
              time={5000}
              status={item.status}
              text={item.text}
            ></Alert>
          );
        })}
      </div>
      <div className="table-offer-container">
        <div className="table-container-header">
          <label className="select-desc-label">
            {langFile.pages.TableOffer.selectCodeColumn}
          </label>
          <select
            name="selectOfferDesc"
            id="selectOfferDesc"
            onChange={(event) => setSelectedOfferCode(event.target.value)}
            value={selectedOfferCode}
          >
            {titles.map((title, index) => (
              <option key={index} value={index}>
                {title.name}
              </option>
            ))}
          </select>
          <label className="select-desc-label">
            {langFile.pages.TableOffer.inputOfferName}
          </label>
          <input
            id="selectOfferName"
            type="text"
            value={selectedOfferDesc}
            onChange={(e) => {
              setSelectedOfferDesc(e.target.value);
            }}
            disabled={selectedOffer !== "$#$#$#$"}
            placeholder="Teklif Adı Giriniz"
          ></input>
        </div>

        <table>
          <thead>
            <tr>
              {/* <th>No</th> Satır numarası sütunu */}
              {titles &&
                titles.map((item, index) =>
                  item.filter === true ? (
                    <th
                      key={index}
                      style={{
                        backgroundColor:
                          checkDataResult?.includes[item.name] === false
                            ? "red"
                            : "",
                      }}
                    >
                      {item.name}
                    </th>
                  ) : null
                )}
            </tr>
          </thead>
          <tbody>
            {dataFromExcel &&
              dataFromExcel
                .slice(0, 200 * counterForPagination)
                .map((item, index) => (
                  <tr key={index}>
                    {/* <td>{index + 1}</td> Satır numarasını gösteren hücre */}
                    {titles.map((titles, index1) =>
                      titles.filter === true ? (
                        <td key={index1}>{item[titles.name]}</td>
                      ) : null
                    )}
                  </tr>
                ))}
            <tr
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "1vh 0",
                width: "100%!important",
              }}
            >
              <td style={{ borderRadius: "5px", minWidth: "40vw" }}>
                <button
                  className="load-more-button"
                  onClick={() => {
                    setLoadingForLoadMore(true);
                    setCounterForPagination(counterForPagination + 1);
                  }}
                >
                  {loadingForLoadMore === true
                    ? // <div className="data-loader"></div>
                      langFile.pages.AllData.loadMoreButton
                    : langFile.pages.AllData.loadMoreButton}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <button className="excel-submit-button" onClick={handleSubmit}>
          {submitStatus
            ? langFile.pages.Table.finishButton
            : langFile.pages.Table.submitButton}
        </button>
      </div>
    </>
  );
}
