import React, { useEffect, useRef, useState } from 'react'
import "./SelectProperties.css"
import add_ico from "../../assets/add.png";

export default function SelectProperties(props) {

    const bottomRef = useRef(null);
    useEffect(() => {
        //scrool to top
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [props.properties]);

    // const [edit, setEdit] = useState(false);//checking if selection can be changed
    const [newProp, setNewProp] = useState("");
    const handleCheckboxChange = (index) => {
        // if (!["Pol No", "Y.", "İpt / İst"].includes(properties[index].name)) {
        //   const newCheckedItems = JSON.parse(JSON.stringify(properties));
        //   newCheckedItems[index].check = !newCheckedItems[index].check;
        //   setProperties(newCheckedItems);
        // }
        const newCheckedItems = JSON.parse(JSON.stringify(props.properties));
        newCheckedItems[index].check = !newCheckedItems[index].check;
        props.setProperties(newCheckedItems);
    };

    function ConfirmHandleToAddProp() {
        const temp = JSON.parse(JSON.stringify(props.properties));
        temp.push({ name: newProp, check: false, isNew: true });
        props.setProperties([...temp]);
        setNewProp("");
    }
    function handleKeyPress(event) {
        if (event.key === "Enter" && newProp !== "") {
            ConfirmHandleToAddProp();
        }
    }
    return (
        <div className='select-properties-container'>
            <div className='new-prop-parent'>
                <div className="new-prop-box-container">
                    {newProp === "" ? (
                        <img
                            alt="newProp"
                            style={{
                                width: "4%",
                                height: "auto",
                                filter: "invert(var(--filterRatio))",
                                WebkitFilter: "invert(var(--filterRatio))",
                            }}
                            src={add_ico}
                        ></img>
                    ) : null}

                    <label>
                        <input
                            onKeyDown={handleKeyPress}
                            type="newProp"
                            name="newProp"
                            value={newProp}
                            placeholder={"Enter New Propertie"}
                            onChange={(e) => {
                                setNewProp(e.target.value);
                            }}
                            disabled={false}
                            maxLength={40}
                            style={{
                                width: "100%",
                                border: "hidden",
                                background: "transparent",
                            }}
                        ></input>
                    </label>
                    {newProp !== "" ? (
                        <img
                            onClick={() => {
                                ConfirmHandleToAddProp();
                            }}
                            alt="confirm"
                            className="confirm-img"
                        ></img>
                    ) : null}
                </div>
            </div>
            <div className='prop-list-container'>

                {props.properties.map((item, index) => (
                    <div
                        className={`prop-box-container ${props.properties[index].check ? "checked-bg" : ""
                            }`}
                        key={index}

                    >
                        <input
                            type="checkbox"
                            id="prop-check"
                            checked={props.properties[index].check || false}
                            onChange={() => handleCheckboxChange(index)}
                        ></input>
                        <label onClick={() => handleCheckboxChange(index)}>{item.name}</label>
                        <select
                            name={"selectPopertyType" + index}
                            id={"selectPopertyType" + index}
                        >
                            <option>Date</option>
                            <option>Text</option>
                        </select>
                    </div>
                ))}
                {/* <div ref={bottomRef} /> */}

            </div>
        </div>
    )
}
