import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/ResetPassword.css";
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import pages_ico from "../assets/mentorSigortaLogo.png";
import Alert from "../components/Alert/Alert";
import loadingGif from "../assets/loading3.gif";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [loadingPage, setLoadingPage] = useState(false);
  const [langFile, setLangFile] = useState(en);
  const [alertList, setAlertList] = useState([]);
  const [tempArr, setTempArr] = useState([...alertList]);
  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);
  useEffect(() => {
    setTempArr(alertList);
  }, [alertList]);

  const [email, setEmail] = useState("");

  function SendResetEmail() {
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_CREATE_VERIFY_CODE,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailOrUserName: email,
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then((data) => {
        if (data.result.statusCode === 404) {
          let temp = [...alertList];
          temp.push({ status: "error", text: data.result.message });
          setAlertList([...temp]);
        } else if (email !== "" && isValidEmail(email)) {
          setLoadingPage(true);
          let temp = [...alertList];
          temp.push({ status: "success", text: data.result.message });
          setAlertList([...temp]);

          setTimeout(() => {
            setLoadingPage(false);
            navigate("/newPassword", {
              state: { email: email, username: data.username },
            });
          }, 2000);
        }

        return data;
      })
      .catch((error) => {
        let temp = [...alertList];
        temp.push({ status: "error", text: "Failed" });
        setAlertList([...temp]);
        console.log(error);
      });
  }

  function isValidEmail(email) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  }

  return (
    <>
      {loadingPage && (
        <div className="load">
          <img src={loadingGif} alt="loading" />
          <label>{langFile.pages.AllData.loadingLabel}</label>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          position: "absolute",
          right: "1vw",
          height: "min-content",
          bottom: "0vh",
        }}
      >
        {tempArr.map((item, index) => {
          return (
            <Alert
              index={index}
              key={index}
              time={5000}
              status={item.status}
              text={item.text}
            ></Alert>
          );
        })}
      </div>

      <div className="resetPassword-parent">
        <img alt="pages_ico" src={pages_ico} className="img" />
        <label className="title">{langFile.pages.ResetPassword.title}</label>
        <div className="container">
          <label>{langFile.pages.ResetPassword.label}</label>
          <input
            className="input"
            type="email"
            placeholder={langFile.pages.ResetPassword.input}
            onChange={(event) => setEmail(event.target.value)}
          />
          <button onClick={SendResetEmail}>
            {langFile.pages.ResetPassword.button}
          </button>
        </div>
      </div>
    </>
  );
}
