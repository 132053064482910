import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const RecaptchaProvider = ({ children }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LfD8IgpAAAAACfbk1ZhiNNF7mmKuTUnWov8Pjol">
      {children}
    </GoogleReCaptchaProvider>
  );
};
export default RecaptchaProvider;
