import React, { useEffect, useState } from "react";
import "../styles/NewPassword.css";
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import Alert from "../components/Alert/Alert";
import pages_ico from "../assets/mentorSigortaLogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import loadingGif from "../assets/loading3.gif";

export default function NewPassword() {

  const location = useLocation();
  const navigate = useNavigate();
  const [loadingPage, setLoadingPage] = useState(false);
  const [langFile, setLangFile] = useState(en);
  const [alertList, setAlertList] = useState([]);
  const [tempArr, setTempArr] = useState([...alertList]);
  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);
  useEffect(() => {
    setTempArr(alertList);
  }, [alertList]);

  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const email = location.state.email;
  const username = location.state.username;

  function SendNewPassword() {
    if (newPassword.length < 6) {
        setPasswordError(`${langFile.pages.NewPassword.error}`);
        return;
      }

    if (newPassword === newPasswordAgain) {
      fetch(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_RESET_PASSWORD, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          verificationCode: verificationCode,
          usernameOrEmail: email,
          newPassword: newPassword,
        }),
      })
        .then((res) => {
            if (res.ok) {
                return res.json();
              }
              throw res;
        })
        .then((data) => {
          if(data.result.statusCode === 400){
            setLoadingPage(true);
            let temp = [...alertList];
            temp.push({ status: "error", text: data.result.message });
            setAlertList([...temp]);

            setTimeout(() => {
                setLoadingPage(false);
                navigate("/resetPassword");
              }, 3000);
          } else {
            setLoadingPage(true);
            let temp = [...alertList];
            temp.push({ status: "success", text: data.result.message });
            setAlertList([...temp]);

          setTimeout(() => {
            setLoadingPage(false);
            navigate("/");
          }, 3000);
          }
        })
        .catch((error) => {
            let temp = [...alertList];
            temp.push({ status: "error", text: error.message });
            setAlertList([...temp]);
        });
    } else {
      let temp = [...alertList];
      temp.push({ status: "error", text: "Passwords do not match or verification code is wrong" });
      setAlertList([...temp]);
    }
  }

  return (
    <>
    {loadingPage && (
        <div className="load">
          <img src={loadingGif} alt="loading" />
          <label>{langFile.pages.AllData.loadingLabel}</label>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          position: "absolute",
          right: "1vw",
          height: "min-content",
          bottom: "0vh",
        }}
      >
        {tempArr.map((item, index) => {
          return (
            <Alert
              index={index}
              key={index}
              time={5000}
              status={item.status}
              text={item.text}
            ></Alert>
          );
        })}
      </div>

      <div className="newPassword-parent">
        <img alt="pages_ico" src={pages_ico} className="img" />
        <label className="title">{langFile.pages.ResetPassword.title}</label>
        <div className="container">
          <label>{langFile.pages.NewPassword.label}</label>
          <input
            className="infoInput"
            disabled
            value={username}
          />
          <label>{langFile.pages.NewPassword.label1}</label>
          <input
            className="input"
            type="text"
            onChange={(e) => setVerificationCode(e.target.value)}
            maxLength={6}
          />
          <label>{langFile.pages.NewPassword.label2}</label>
          <input
            className="input"
            type="password"
            onChange={(e) => {
                setNewPassword(e.target.value);
                setPasswordError("");
              }}
          />
          {passwordError && 
          <p className="passwordError">{passwordError}</p>} 
          <label>{langFile.pages.NewPassword.label3}</label>
          <input
            className="input"
            type="password"
            onChange={(e) => setNewPasswordAgain(e.target.value)}
          />
          <button onClick={SendNewPassword}>
            {langFile.pages.NewPassword.button}
          </button>
        </div>
      </div>
    </>
  );
}
