import React, { useEffect, useState } from 'react'
import "./UseOffer.css"
import LogOutEndPoint from '../LogOutEndPoint'
import { useNavigate } from 'react-router-dom'
import delete_ico from "../../assets/delete.png"
import emailLog_ico from "../../assets/emailLog.png"
import tr from "../../locales/tr.json"
import en from "../../locales/en.json"
import Alert from '../Alert/Alert'

export default function UseOffer(props) {
    const [emailList, setEmailList] = useState([])
    const [chooseEmailComponent, setChooseEmail] = useState(false)
    const [offerId, setOfferId] = useState()
    const [offers, setOffers] = useState([])
    const [updatedOffers, setUpdatedOffers] = useState([])
    const [getOffersByTemplateTrigger, setGetOffersByTemplateTrigger] = useState(false)
    const [usedOffers, setUsedOffers] = useState([])
    const [usedOffersGroup, setUsedOffersGroup] = useState([])
    const [emailLogs, setEmailLogs] = useState([]);
    const possibleNames = ["key", "pincodes", "pin codes", "pincode", "pin code"];
    const [langFile, setLangFile] = useState(en);
    const [toEmail, setToEmail] = useState(props.email)
    useEffect(()=>{
        setToEmail(props.email)
    },[props.email])
    
    useEffect(() => {
        localStorage.getItem("language") === "English"
            ? setLangFile(en)
            : setLangFile(tr);
    }, [localStorage.getItem("language")]);
    const [alertList, setAlertList] = useState([]);
    const [tempArr, setTempArr] = useState([...alertList]);
    useEffect(() => {
        setTempArr(alertList);
    }, [alertList]);
    const navigate = useNavigate();

    function groupByDesc(arr) {
        const groupedOffers = {};
        arr?.forEach((item) => {
            const desc = item.desc;
            if (!groupedOffers[desc]) {
                groupedOffers[desc] = {
                    desc: desc,
                    offerList: [],
                };
            }
            const offerData = {
                offerId: item.offerId,
                property: item.property,
            };
            groupedOffers[desc].offerList.push(offerData);
        });
        return Object.values(groupedOffers);
    }

    useEffect(() => {
        fetch(
            process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_EMAIL_CONFIG,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization:
                        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
                },
            }
        )
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
                else if (res.status === 401) {
                    LogOutEndPoint(navigate);
                }
                throw res;
            })
            .then((data) => {
                if (data?.emailConfigList) {
                    setEmailList(data.emailConfigList)
                }
                else {
                    setEmailList([])
                }
                return data;
            })
            .catch((error) => {
                setEmailList([])
                let temp = [...props.alertList];
                temp.push({ status: "error", text: "Get Email Configs Failed" });
                props.setAlertList([...temp]);
                console.log(error);
            });
    }, [])


    useEffect(() => {
        if (props.selectedTemplate !== "") {
            fetch(
                process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_ALL_OFFERS_BY_TEMPLATE + props.selectedTemplate + "&pageNumber=1&pageSize=999",
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        Authorization:
                            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
                    },
                }
            )
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }
                    else if (res.status === 401) {
                        LogOutEndPoint(navigate);
                    }
                    throw res;
                })
                .then((data) => {
                    if (data.offers) {
                        setOffers(data.offers)
                    }
                    else {
                        setOffers([])
                    }
                    return data;
                })
                .catch((error) => {
                    setOffers([])
                    let temp = [...props.alertList];
                    temp.push({ status: "error", text: "Get Offers Failed" });
                    props.setAlertList([...temp]);
                    console.log(error);
                });
        }
    }, [props.selectedTemplate, getOffersByTemplateTrigger])
    useEffect(() => {
        if (props.selectedProductToUpdate?.id) {
            fetch(
                process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_ALL_OFFERS_BY_PRODUCT + props.selectedProductToUpdate.id + "&PageNumber=1&PageSize=999",
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        Authorization:
                            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
                    },
                }
            )
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }
                    else if (res.status === 401) {
                        LogOutEndPoint(navigate);
                    }
                    throw res;
                })
                .then((data) => {
                    // console.log("data", data);
                    if (data.offers) {
                        const formattedEmailLogs = data.emails.map((emailLog) => {

                            const sendedAtDate = new Date(emailLog.sendedAt);
                            sendedAtDate.setHours(sendedAtDate.getHours() + 3);
                            const options = {
                                timeZone: 'Europe/Istanbul',
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                            };
                            const formattedDate = sendedAtDate.toLocaleString('tr-TR', options);

                            return { ...emailLog, sendedAt: formattedDate };
                        });

                        setUsedOffersGroup(groupByDesc(data.offers));
                        setUsedOffers(data.offers);
                        setEmailLogs(formattedEmailLogs);
                    } else {
                        setUsedOffers([]);
                        setUsedOffersGroup([]);
                        setEmailLogs([]);
                    }
                    return data;
                })
                .catch((error) => {
                    setUsedOffers([])
                    setUsedOffersGroup([])
                    let temp = [...props.alertList];
                    temp.push({ status: "error", text: "Get Offers Failed" });
                    props.setAlertList([...temp]);
                    console.log(error);
                });
        }
    }, [props.selectedProductToUpdate, getOffersByTemplateTrigger])


    function AddOfferToProduct(offerDesc) {

        const user = JSON.parse(localStorage.getItem("user"));

        fetch(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ADD_OFFER_TO_PRODUCT, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "accept": "application/json",
                "Authorization": "Bearer " + user.accessToken,
            },
            body: JSON.stringify({
                productId: props.selectedProductToUpdate.id,
                userId: user.id,
                offerDesc: offerDesc
            }),
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
                else if (res.status === 401) {
                    LogOutEndPoint(navigate);
                }
                throw res;
            })
            .then((data) => {
                let temp = [...props.alertList];
                temp.push({ status: "success", text: data.result.message });
                props.setAlertList([...temp]);
                setGetOffersByTemplateTrigger(!getOffersByTemplateTrigger)
                return data;
            })
            .catch((error) => {
                let temp = [...props.alertList];
                temp.push({ status: "error", text: "Using Offer Failed" });
                props.setAlertList([...temp]);
                setGetOffersByTemplateTrigger(!getOffersByTemplateTrigger)
                console.log(error);
            });
    }

    function DeleteOfferToProduct(offerId) {
        fetch(
            process.env.REACT_APP_BASE_URL + process.env.REACT_APP_DELETE_OFFER_PRODUCT,
            {
                method: "DELETE",
                headers: {
                    accept: "application/json",
                    Authorization:
                        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    offerId: offerId,
                    productId: props.selectedProductToUpdate.id,
                    userId: JSON.parse(localStorage.getItem("user")).id
                }),
            }
        )
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
                else if (res.status === 401) {
                    LogOutEndPoint(navigate);
                }
                throw res;
            })
            .then((data) => {
                let temp = [...alertList];
                temp.push({
                    status: "success",
                    text: data.result.success ? "Package Delete Success" : "Package Delete Failed",
                });
                setAlertList([...temp]);
                setGetOffersByTemplateTrigger(!getOffersByTemplateTrigger)
                return data;
            })
            .catch((error) => {
                let temp = [...alertList];
                temp.push({ status: "error", text: "Package Delete Failed" });
                setAlertList([...temp]);
                setGetOffersByTemplateTrigger(!getOffersByTemplateTrigger)
                console.log(error);
            });
    }

    useEffect(() => {
        let temp = [...offers]
        for (let i = 0; i < temp.length; i++) {
            const foundOffer = usedOffersGroup.find(offer => offer.desc === temp[i].offerDesc);
            const offerListLength = foundOffer ? foundOffer.offerList.length : 0;
            const remainingOfferCount = 1 - offerListLength;
            temp[i]["remainingOfferCount"] = remainingOfferCount
        }
        setUpdatedOffers(temp)
    }, [offers, usedOffersGroup])

    function SendEmail(offerId,fromAddress, toAddress) {
        const user = JSON.parse(localStorage.getItem("user"));
        fetch(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_SEND_EMAIL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "accept": "application/json",
                "Authorization": "Bearer " + user.accessToken,
            },
            body: JSON.stringify({
                fromAddress: {
                    address: fromAddress.address,
                    password: fromAddress.password,
                    port: fromAddress.port,
                    host: fromAddress.host,
                    ssl: fromAddress.ssl
                },
                toAddress: toAddress,
                offerId: offerId,
                userId: user.id,
                customerFullName: props.insured,
                productNo: props.productNo,
                isPackage: true,
            }),
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
                else if (res.status === 401) {
                    LogOutEndPoint(navigate);
                }
                throw res;
            })
            .then((data) => {
                let temp = [...props.alertList];
                temp.push({ status: "success", text: data.result.message });
                props.setAlertList([...temp]);
                setGetOffersByTemplateTrigger(!getOffersByTemplateTrigger)
                return data;
            })
            .catch((error) => {
                let temp = [...props.alertList];
                temp.push({ status: "error", text: "This product has no email address" });
                props.setAlertList([...temp]);
                setGetOffersByTemplateTrigger(!getOffersByTemplateTrigger)
                console.log(error);
            });
    }

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column-reverse",
                    position: "absolute",
                    right: "1vw",
                    height: "min-content",
                    bottom: "0vh",
                }}
            >
                {tempArr.map((item, index) => {
                    return (
                        <Alert
                            index={index}
                            key={index}
                            time={5000}
                            status={item.status}
                            text={item.text}
                        ></Alert>
                    );
                })}
            </div>            
            {chooseEmailComponent &&
                <div className='choose-email-parent'>
                    <div className='offer-list'>
                    <div className='choose-email-title'>
                            {props.langFile.pages.Search.to}
                        </div>
                        <input className='to-email' type='text' onChange={(e)=>{setToEmail(e.target.value)}} value={toEmail} placeholder={props.langFile.pages.Search.to}> 
                        </input>
                        <div className='choose-email-title'>
                            {props.langFile.pages.Search.chooseEmailConf}
                        </div>
                        {emailList?.map((email, index) =>
                            <div key={index} onClick={() => { if(toEmail){SendEmail(offerId, email, toEmail); setChooseEmail(false)} }} className='email-item'>
                                {email.address}
                            </div>
                        )}
                        <div onClick={() => setChooseEmail(false)} className='cancel-send-email'>
                            {props.langFile.pages.Search.cancel}
                        </div>
                    </div>
                </div>
            }
            <div className='use-offer-parent'>
                <div className='to-be-used-offer-parent'>
                    <div className='offer-items-container'>
                        <h3>{props.langFile.pages.Search.useableOffers}</h3>
                        <table>
                            <thead>
                                {updatedOffers.length > 0 ?
                                    <tr>
                                        <th>
                                        </th>
                                        <th>
                                            {props.langFile.pages.Search.offerName}                                </th>
                                        <th>
                                            {props.langFile.pages.Search.remainingOffer}                                </th>
                                        <th>
                                            {props.langFile.pages.Search.remainingOfferForThisPolicy}                                </th>
                                    </tr>
                                    : null}
                            </thead>
                            <tbody>
                                {updatedOffers.map((item, index) =>
                                    <tr style={{
                                        pointerEvents: item.counts[0].countStatusAndAvaiblityIsTrue > 0
                                            &&
                                            item?.remainingOfferCount > 0 ? "all" : "none"
                                    }} key={index}>
                                        <td>
                                            <button
                                                style={{
                                                    backgroundColor: item.counts[0].countStatusAndAvaiblityIsTrue > 0
                                                        &&
                                                        item?.remainingOfferCount > 0 ? "" : "#f5aeae"
                                                }}
                                                disabled={
                                                    !(item.counts[0].countStatusAndAvaiblityIsTrue > 0
                                                        &&
                                                        item?.remainingOfferCount > 0)
                                                }
                                                onClick={() => AddOfferToProduct(item.offerDesc)} className='use-button'>
                                                {item.counts[0].countStatusAndAvaiblityIsTrue > 0
                                                    &&
                                                    item?.remainingOfferCount > 0 ? "Kullan" : "Kullanılamaz"}
                                            </button>
                                        </td>
                                        <td style={{
                                            backgroundColor: item.counts[0].countStatusAndAvaiblityIsTrue <= 0
                                                ||
                                                item?.remainingOfferCount <= 0 ? "#f5aeae" : ""
                                        }} >
                                            {item.offerDesc}
                                        </td>
                                        <td style={{
                                            backgroundColor: item.counts[0].countStatusAndAvaiblityIsTrue <= 0
                                                ||
                                                item?.remainingOfferCount <= 0 ? "#f5aeae" : ""
                                        }}>
                                            {item.counts[0].countStatusAndAvaiblityIsTrue}
                                        </td>
                                        <td style={{
                                            backgroundColor: item.counts[0].countStatusAndAvaiblityIsTrue <= 0
                                                ||
                                                item?.remainingOfferCount <= 0 ? "#f5aeae" : ""
                                        }}>
                                            {item?.remainingOfferCount}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='used-offer-parent'>
                    <h3>{props.langFile.pages.Search.usedOffers}</h3>
                    <div className='tables-container'>
                        <div className='offer-items-container'>

                            {usedOffers.map((usedOfferGroup, indexTable) => (
                                <table key={"table-" + indexTable}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>
                                                {props.langFile.pages.Search.offerName}
                                            </th>
                                            {usedOfferGroup.property.map((prop, index) => {
                                                if (possibleNames.includes(prop.propertyName.toLowerCase())) {
                                                    return (
                                                        <th key={"title" + index}>
                                                            {prop.propertyName}
                                                        </th>
                                                    );
                                                }
                                                return null;
                                            })}
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <button
                                                    onClick={() => { DeleteOfferToProduct(usedOfferGroup.offerId) }}
                                                    className="delete-button"
                                                >
                                                    <img
                                                        alt="delete"
                                                        src={delete_ico}
                                                        style={{ width: "1.5vw", height: "1.5vw" }}
                                                    ></img>
                                                </button>
                                            </td>
                                            <td>
                                                {usedOfferGroup.desc}
                                            </td>
                                            {usedOfferGroup.property.map((prop, index) => {
                                                if (possibleNames.includes(prop.propertyName.toLowerCase())) {
                                                    return (
                                                        <td key={"prop" + index}>
                                                            {prop.value}
                                                        </td>
                                                    );
                                                }
                                                return null;
                                            })}
                                            <td>
                                                <button className='use-button' onClick={() => {
                                                    setChooseEmail(!chooseEmailComponent)
                                                    setOfferId(usedOfferGroup.offerId)
                                                }}>{langFile.pages.UseOffer.button}</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
            <div className='email-logs'>
                <div className='title'>
                    <h3>{langFile.pages.UseOffer.h3}</h3>
                </div>
                <div className='rows-container'>
                    {emailLogs &&
                        emailLogs.map((item, index) => (
                            <div className="row-parent" key={index}>
                                <div className="ico-container">
                                    <img
                                        alt="email-ico"
                                        src={emailLog_ico}
                                        className="email-ico"
                                    ></img>
                                </div>
                                <div className="email-text">{item.desc} {langFile.pages.UseOffer.text1} {item.pincode} {langFile.pages.UseOffer.text2} </div>
                                <div className="date">{item.sendedAt}</div>
                            </div>
                        ))}
                </div>
            </div>
        </>
    )
}
