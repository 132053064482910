import React, { useEffect, useRef, useState } from "react";
import "../styles/MailConfig.css";
import Alert from "../components/Alert/Alert";
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import LogOutEndPoint from "../components/LogOutEndPoint";
import { useNavigate } from "react-router-dom";
import { set } from "date-fns";
import delete_ico from "../assets/delete.png"

export default function MailConfig() {
  const [langFile, setLangFile] = useState(en);
  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);

  const bottomRef = useRef(null);
  const [alertList, setAlertList] = useState([]);
  const [tempArr, setTempArr] = useState([...alertList]);
  useEffect(() => {
    setTempArr(alertList);
  }, [alertList]);

  const navigate = useNavigate();
  const [emailConfigs, setEmailConfigs] = useState([])
  const [addEmailFlag, setAddEmailFlag] = useState(false)

  function ChangeHandle(key, index, value) {
    const updatedEmailConfigs = [...emailConfigs];
    updatedEmailConfigs[index] = { ...updatedEmailConfigs[index], [key]: value };
    setEmailConfigs(updatedEmailConfigs);
  }

  useEffect(() => {
    if (addEmailFlag) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [emailConfigs])

  function AddEmailConf() {
    setAddEmailFlag(true)
    const newConf = {
      address: "",
      host: "",
      newAddress: "",
      password: "",
      port: 0,
      ssl: false,
      new: true
    };
    setEmailConfigs([...emailConfigs, newConf]);
  }

  function GetEmailConfigs() {
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_EMAIL_CONFIG,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        if (data?.emailConfigList) {
          data?.emailConfigList.forEach(element => {
            // element["password"]=""
            element["newAddress"] = element["address"]
          });
          setEmailConfigs(data.emailConfigList)
        }
        else {
          setEmailConfigs([])
        }
        return data;
      })
      .catch((error) => {
        setEmailConfigs([])
        let temp = [...alertList];
        temp.push({ status: "error", text: "Get Email Configs Failed" });
        setAlertList([...temp]);
        console.log(error);
      });
  }

  useEffect(() => {
    GetEmailConfigs()
  }, [])

  // function UpdateEmail() {
  //   fetch(process.env.REACT_APP_BASE_URL+process.env.REACT_APP_SET_EMAIL,  {
  //     method: "POST",
  //     headers: {
  //               'Content-Type': 'application/json' ,
  //               'Accept': 'application/json',
  //               "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
  //             },
  //     body:JSON.stringify({       
  //       userId: JSON.parse(localStorage.getItem("user")).id,
  //       address: email,
  //       password: password,
  //       host: smtp,
  //       port: port,
  //       ssl: ssl
  //       })
  //     })
  //     .then((res) => {
  //       if (res.ok) {
  //         return res.json();
  //       }
  //       else if (res.status === 401) {
  //         LogOutEndPoint(navigate);
  //       }
  //       throw res;
  //     })
  //     .then((data) => {
  //       let temp = [...alertList];
  //       temp.push({status:"success", text:data.result.message})
  //       return data;
  //     })
  //     .catch((error) => {
  //       let temp = [...alertList];
  //       temp.push({status:"error", text:"Failed"})
  //       setAlertList([...temp])
  //       console.log(error);
  //     });
  // }

  function UpdateEmail(body) {
    fetch(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_SET_EMAIL_CONFIG, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
      body: JSON.stringify(
        body
      )
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        GetEmailConfigs()
        let temp = [...alertList];
        temp.push({ status: "success", text: data.result.message })
        setAlertList([...temp])
        return data;
      })
      .catch((error) => {
        GetEmailConfigs()
        let temp = [...alertList];
        temp.push({ status: "error", text: "Failed" })
        setAlertList([...temp])
        console.log(error);
      });
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            position: "absolute",
            right: "1vw",
            height: "min-content",
            bottom: "0vh",
          }}
        >
          {tempArr.map((item, index) => {
            return (
              <Alert
                index={index}
                key={index}
                time={5000}
                status={item.status}
                text={item.text}
              ></Alert>
            );
          })}
        </div>
        <div className="mailConfig">
          <div className="formbold-main-wrapper">
            {emailConfigs.map((emailConfig, index) => (
              <div key={index} className="formbold-form-wrapper">
                <form>
                  <div className="formbold-form-title">
                    <h2 style={{ textAlign: "left" }}>
                      {langFile.pages.MailConfig.header + "-" + (index + 1)}
                    </h2>
                  </div>
                  <div className="formbold-input-flex">

                    <div className="formbold-mb-3">
                      <label htmlFor="smtp" className="formbold-form-label">
                        {langFile.pages.MailConfig.label1}
                      </label>
                      <input
                        type="text"
                        name="smtp"
                        id={"smtp" + index}
                        className="formbold-form-input"
                        onChange={(event) => ChangeHandle("host", index, event.target.value)}
                        value={emailConfig.host}
                      />
                    </div>

                    <div className="formbold-mb-3">
                      <label htmlFor="port" className="formbold-form-label">
                        {langFile.pages.MailConfig.label2}
                      </label>
                      <input
                        type="number"
                        name="port"
                        id={"port" + index}
                        className="formbold-form-input"
                        onChange={(event) => ChangeHandle("port", index, event.target.value)}
                        value={emailConfig.port}
                      />
                    </div>

                    <div className="formbold-mb-3">
                      <label htmlFor="ssl" className="formbold-form-label">
                        {langFile.pages.MailConfig.label3}
                      </label>
                      <select className="formbold-form-input" defaultValue={emailConfig.ssl} name="ssl" id={"ssl" + index}
                        onChange={(event) => ChangeHandle("ssl", index, event.target.value)} >
                        <option value={true}>{langFile.pages.MailConfig.option1}</option>
                        <option value={false}>{langFile.pages.MailConfig.option2}</option>
                      </select>
                    </div>

                  </div>
                  <div className="formbold-input-flex">

                    <div className="formbold-mb-3">
                      <label htmlFor="email" className="formbold-form-label">
                        {langFile.pages.MailConfig.label6}
                      </label>
                      <input
                        type="email"
                        name="email"
                        id={"email" + index}
                        className="formbold-form-input"
                        onChange={(event) => ChangeHandle("newAddress", index, event.target.value)}
                        value={emailConfig.newAddress}
                      />
                    </div>

                    <div>
                      <label htmlFor="password" className="formbold-form-label">
                        {langFile.pages.MailConfig.label5}
                      </label>
                      <input
                        type="password"
                        name="password"
                        id={"password" + index}
                        className="formbold-form-input"
                        onChange={(event) => ChangeHandle("password", index, event.target.value)}
                        value={emailConfig.password}
                      />
                    </div>

                  </div>
                  <div style={{ marginLeft: "auto", display: "flex", gap: ".5vw", alignItems: "center" }}>
                    <input style={{ transform: "scale(1.7)" }} id={"viewPassword" + index} type="checkbox" onClick={() => {
                      var x = document.getElementById("password" + index);
                      if (x.type === "password") {
                        x.type = "text";
                      } else {
                        x.type = "password";
                      }
                    }} />
                    <label htmlFor={"viewPassword" + index} style={{ display: "flex", fontSize: ".8vw" }}>Show Password</label>

                  </div>



                  <button
                    type="button"
                    className="formbold-btn"
                    onClick={() => {
                      emailConfig?.new ?
                        UpdateEmail(
                          {
                            toBeAddedEmailConfigList: [
                              {
                                address: emailConfig.address,
                                password: emailConfig.password !== "" ? emailConfig.password : null,
                                port: emailConfig.port,
                                host: emailConfig.host,
                                ssl: emailConfig.ssl === "true" ? true : false
                              }
                            ],
                            userId: JSON.parse(localStorage.getItem("user")).id,
                          }
                        )
                        :
                        UpdateEmail(
                          {
                            toBeUpdatedEmailConfigList: [
                              {
                                address: emailConfig.address,
                                newAddress: emailConfig.newAddress,
                                password: emailConfig.password !== "" ? emailConfig.password : null,
                                port: emailConfig.port,
                                host: emailConfig.host,
                                ssl: emailConfig.ssl === "true" ? true : false
                              }
                            ],
                            userId: JSON.parse(localStorage.getItem("user")).id,
                          }
                        )
                    }}
                  >
                    {langFile.pages.MailConfig.button}
                  </button>
                  {!emailConfig.new &&
                  <button type="button" style={{ position: "absolute", top: "2vh", right: "1vw",border:"none", background:"transparent"}}>
                    <img style={{ width: "1.5vw" }} src={delete_ico}
                      onClick={() => {
                        UpdateEmail(
                          {
                            toBeRemovedEmailConfigList: [
                              {
                                address: emailConfig.address,
                                password: emailConfig.password !== "" ? emailConfig.password : null,
                                port: emailConfig.port,
                                host: emailConfig.host,
                                ssl: emailConfig.ssl === "true" ? true : false
                              }
                            ],
                            userId: JSON.parse(localStorage.getItem("user")).id,
                          }
                        )
                      }}
                      ></img>
                      </button>
                  }
                </form>
              </div>
            ))}
            <div ref={bottomRef}></div>
          </div>
          <button style={{ width: "50vw" }} className="formbold-btn" onClick={AddEmailConf}>
          {langFile.pages.MailConfig.addButton}
          </button>
        </div>
      </div>
    </>
  );
}
