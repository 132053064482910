import React, { useEffect, useState } from "react";
import "../styles/AllCompany.css";
import delete_ico from "../assets/delete.png";
import Alert from "../components/Alert/Alert";
import en from "../locales/en.json";
import tr from "../locales/tr.json";
import LogOutEndPoint from "../components/LogOutEndPoint";
import { useNavigate } from "react-router-dom";

export default function AllCompany() {
  const [companies, setCompanies] = useState([]);
  const [alertList, setAlertList] = useState([]);
  const [tempArr, setTempArr] = useState([...alertList]);
  const [deleteTrigger, setDeleteTrigger] = useState(false);
  const [langFile, setLangFile] = useState(en);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.getItem("language") === "English"
      ? setLangFile(en)
      : setLangFile(tr);
  }, [localStorage.getItem("language")]);

  useEffect(() => {
    setTempArr(alertList);
  }, [alertList]);

  useEffect(() => {
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_ALL_COMPANY,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        setCompanies(data.companies);
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }, [deleteTrigger]);

  function DeleteCompany(id) {
    fetch(
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_DELETE_COMPANY,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: JSON.parse(localStorage.getItem("user")).id,
          company: { id: id },
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          LogOutEndPoint(navigate);
        }
        throw res;
      })
      .then((data) => {
        setDeleteTrigger(!deleteTrigger);
        let temp = [...alertList];
        temp.push({ status: "success", text: data.result.message });
        setAlertList([...temp]);
        return data;
      })
      .catch((error) => {
        let temp = [...alertList];
        temp.push({ status: "error", text: "Data Delete Failed" });
        setAlertList([...temp]);
        console.log(error);
      });
  }

  return (
    <div className="allCompany-container">
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          position: "absolute",
          right: "1vw",
          height: "min-content",
          bottom: "0vh",
        }}
      >
        {tempArr.map((item, index) => {
          return (
            <Alert
              index={index}
              key={index}
              time={5000}
              status={item.status}
              text={item.text}
            ></Alert>
          );
        })}
      </div>
      <table>
        <thead>
          <tr>
            <th>{langFile.pages.AllCompany.no}</th>
            <th>{langFile.pages.AllCompany.companyName}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {companies.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.name}</td>
              <td>
                <button
                  onClick={() => {
                    DeleteCompany(item.id);
                  }}
                  className="delete-button"
                >
                  <img
                    alt="delete"
                    src={delete_ico}
                    style={{
                      width: "1vw",
                      height: "1vw",
                      filter: "invert(var(--filterRatio))",
                      WebkitFilter: "invert(var(--filterRatio))",
                    }}
                  ></img>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
