import React, { useState } from "react";
import Navigation from "./Navigation";
import "./styles/Layout.css";
import Sidebar from "./Sidebar";

export default function Layout({ children }) {
  const [sideBar, setSideBar] = useState(true);
  return (
    <div className="layout">
      <Navigation sideBar={sideBar}></Navigation>
      <Sidebar sideBar={sideBar} setSideBar={setSideBar}>{children}</Sidebar>
    </div>
  );
}
