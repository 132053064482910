import './App.css';
import Routing from './Routing';

function App() {
  return (
    <Routing></Routing>
  );
}

export default App;
