import React from "react";
import "./CounterBoxes.css";

export default function CounterBoxes({ text, count, backgroundColor, src }) {
  return (
    <div
      style={{ backgroundColor: backgroundColor }}
      className="counter-box-container"
    >
      <div className="ico-text-container">
        <img alt="ico" src={src} id="counter-ico"></img>

        <label className="count-text">{text}</label>
      </div>
      <label className="count-number">{count}</label>
    </div>
  );
}
